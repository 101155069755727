<div class="bg-image">
    <div class="row center m-0">
        <div class="col-xl-12 description">
            <div class="description-heading">
                {{'kalaignar-library'|translate}}
            </div>
        </div>
        <!-- <div data-aos="fade-right" data-aos-duration="1000" class="container" class="libdesc"> -->
        <div class="container-fluid libdesc">
            <!-- <h3>{{'description'|translate}}</h3> -->
            <p>{{'library-description-1'|translate}}</p><br>
            <p>{{'library-description-2'|translate}}</p><br>
            <p>{{'library-description-3'|translate}}</p>
        </div>

        <div class="libdrp col" *ngIf="hashvalue.length">
            <!-- <app-select [options]="librarydrop" [control]="f.librarydrop" (valuechange)='displaydrop($event)'></app-select> -->
            <!-- <button class="btn btn-primary" routerLink="/libraryhash">Commentry</button> -->
            <span class="taghead">Tags -</span>
            <ul class="d-flex flex-wrap tag">
                <div *ngFor="let item of hashvalue;let i=index" (click)="onTagClick(i)">
                    <li class="badge rounded-pill mr-2" [ngClass]="{'selectedTag':selectedTag==i}"
                        (click)="hashCol(item.hashtagsval)"><span
                            *ngIf="item.hashtagsval!='ALLCAT'else allCat">{{item.hashtagsval}}</span>
                        <ng-template #allCat>{{"alltag"|translate}}</ng-template>
                    </li>

                </div>
            </ul>
        </div>

        <!-- material table -->
        <!-- <div data-aos="fade-left" data-aos-duration="1000" class="container mx-0 px-0"> -->

        <div class="container-fluid">
            <div class="row">
                <div class="tab col-md-12">


                    <div id='search' class="col-md-4 form-control">
                        <input matInput placeholder="Search" autocomplete="off" (keyup)="filterData($event)">
                    </div>


                    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">


                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> {{'bookId'|translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.Id}} </td>
                        </ng-container>


                        <ng-container matColumnDef="title">
                            <th mat-header-cell *matHeaderCellDef> {{'title'|translate}} </th>
                            <td class="mat-title" mat-cell *matCellDef="let row" (click)="bookid(row.PostId)">
                                {{row.TitleLib}} </td>
                        </ng-container>


                        <ng-container matColumnDef="author">
                            <th mat-header-cell *matHeaderCellDef> {{'author'|translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.AuthorNameLib}} </td>
                        </ng-container>

                        <ng-container matColumnDef="bookId">
                            <th mat-header-cell *matHeaderCellDef> {{'publisher'|translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.BookId}} </td>
                        </ng-container>


                        <tr class="header-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr class="data-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row d-flex justify-content-center align-items-center" id="no-data" *matNoDataRow>
                            <div>
                                No Records Found
                            </div>
                        </tr>

                        <!-- <div *ngIf="dataSource.data.length === 0">
                                No Records Found!
                            </div> -->

                        <!-- <mat-footer-row *matFooterRowDef="['noData']">No data</mat-footer-row> -->
                        <!-- <tr mat-footer-row *matFooterRowDef="['total']"></tr> -->
                    </table>

                    <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                    </mat-paginator>
                </div>

            </div>
        </div>
    </div>
</div>