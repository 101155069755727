<div *ngFor="let data of history; let last=last;let i=index" class="row" style="margin-bottom:1rem">
    <div class="col-2 date-wrapper" style="display: flex;flex-direction: column;padding: 0;">
        <div class="date-container" style="text-align: center;color:white;
        background-color: rgba(0, 0, 0, 0.6);width: 6rem;padding:.3rem 0rem;align-self: flex-end;">
            <div class="date" style="font-weight:bolder; font-size: 2.5rem;line-height: 2.5rem">
                {{data.date|date:"dd"}}
            </div>
            <div class="month-year">
                {{data.date|date:"MMM yyyy"}}
            </div>
        </div>
    </div>
    <div class="col-md-10" [ngClass]="{'line':!last}" style="background-color: rgba(144, 144, 144, 0.137);
        padding: 1.5rem;">
        <div class="row">
            <div class="col-md-4">
                <img class="img-fluid" style="height: 12rem;object-fit: cover;width: 20rem;" [src]="data.image">
            </div>
            <div class="col-md-8">
                <!-- render html response from api -->
                <!-- https://blog.briebug.com/blog/how-do-i-display-html-inside-an-angular-binding -->
                <div class="postName" style="color: red;">{{data.title}}</div>

                <div *ngIf="data.isCollapsed;else fullView">
                    <div [innerHtml]="data.contentShort"></div>
                </div>

                <ng-template #fullView>
                    <div [innerHtml]="data.content"></div>
                </ng-template>

                <a (click)="data.isCollapsed=false" *ngIf="data.isCollapsed">{{'read-more'|translate}}</a>
                <a (click)="data.isCollapsed=true" *ngIf="!data.isCollapsed">{{'read-less'|translate}}</a>
            </div>
        </div>
    </div>
</div>