export let host = 'https://api.youthwingdmk.in/';
// export let host = 'http://192.168.5.49:8003/';
// export let host = 'http://13.235.141.98:8000/';
// export let host = 'http://192.168.5.136:8004/';
// export let host  = "http://205.147.97.85:8005/";
// export let host = 'http://13.234.230.73:8000/';
// export let host = 'http://127.0.0.1:8000/';
// export let host = 'http://192.168.5.81:8000/'; //Mohan local


// export let host = 'http://cbe1-2409-4072-80c-cf34-43bd-b327-3b2a-8d97.ngrok.io/';
// export let mediaUrl = host + 'media/';
// export let mediaUrl = 'http://13.234.230.73:8000/' + 'media/';

export let mediaUrl = 'https://youthwingassets.s3.ap-south-1.amazonaws.com/';

export let alphabetRegex = /^[a-zA-Z ]*$/;
export let mobileNumberRegex = '^[0-9]{10}$';
export let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export interface ApiResponse {
    "Data": any,
    "Status": string;
};

export interface Report {
    "Id": string,
    "PostTitle": string,
    "PostName": string,
    "PostContent": string,
    "categories": string,
    "PostImage": string,
    "VideURL": string,
    "SincDateTime": string,
    "PostDateTime": string,
};



export interface DateFilter {
    date: Date;
}
