import { Route } from '@angular/compiler/src/core';
import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { ApiResponse, Report } from 'src/app/services/interface';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  @ViewChild('card') cardElement: ElementRef;

  slideConfig = {
    "slidesToShow": 4,
    "dots": true,
    "slidesToScroll": 1,
    "infinite": false,
    'draggable': true,
    "arrows": false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };
  photos: Report[];
  reports: Report[];
  secretaryInService: Report[];
  pressRelease: Report[];
  constructor(
    private service: HttpService,
    private router: Router
  ) { }



  ngOnInit(): void {
    this.getPhotos('Photo');
    this.getReport('report');
    this.getsSecretaryInService('secretaryInPublicService');
    this.getPressRelease('pressRelease');

  };

  ngAfterViewInit() {
    // console.log('element', document.getElementById('Software Developer'));
    var itemid1 =  localStorage.getItem("itemCard2");
   console.log(itemid1,"element1001");
   const elementID = document.getElementById(itemid1);
  //  console.log(elementID,"element11001");
   setTimeout(() => {
   elementID.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
   localStorage.removeItem("itemCard2");


   },500);
  }

  getPhotos(cat) {
    this.service.getPublications(cat, 'abc').subscribe((res: ApiResponse) => {
      // console.log('photos res', res);
      // this.reports = res.Data.slice(0, 4);
      this.photos = res.Data.slice(0, 9);
      // console.log(this.photos);
    });
  }

  getReport(cat) {
    this.service.getPublications(cat, 'abc').subscribe((res: ApiResponse) => {
      // console.log('reports res', res);
      // this.reports = res.Data.slice(0, 4);
      this.reports = res.Data.slice(0, 9);
      // console.log(this.reports);
    });
  }
  getsSecretaryInService(cat) {
    this.service.getPublications(cat, 'abc').subscribe((res: ApiResponse) => {
      // console.log('getsSecretaryInService res', res);
      this.secretaryInService = res.Data.slice(0, 9);
      // console.log(this.secretaryInService);
    });
  }
  getPressRelease(cat) {
    this.service.getPublications(cat, 'abc').subscribe((res: ApiResponse) => {
      // console.log('getPressRelease res', res);
      this.pressRelease = res.Data.slice(0, 9);
      // console.log(this.pressRelease);
    });
  }




  readMore(event) {
    // console.log(event);
    localStorage.setItem("itemCard2", event);
   console.log(event, "dddddd");
    let navigationExtras: NavigationExtras = {
      queryParams: { 'Id': event }
    };
    this.router.navigate(['/publication-details'], navigationExtras);
  }
}
