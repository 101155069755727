import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { host, mediaUrl } from 'src/app/services/interface';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})

export class PhotosComponent implements OnInit {
  // host = host;
  mediaUrl=mediaUrl
  photos = [];

  // full screen view
  images = [];
  selectedImageIndex: any;
  showFlag: boolean;


  constructor(private service: HttpService,) { }

  ngOnInit(): void {
    this.service.getImages().subscribe((res: any) => {
      this.photos = res.Data;
      this.fullScreenView();
    });

  }
  // full screen methods
  fullScreenView() {
    this.photos.forEach(ele => {
      // console.log('this is each', ele);

      this.images.push({ image: mediaUrl + ele.images });
    });
  }

  showPreview(imgIndex: number) {
    // console.log('img deee', this.images);

    this.selectedImageIndex = imgIndex;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.selectedImageIndex = -1;
  }

}
