import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpService } from 'src/app/services/http.service';
import { host } from 'src/app/services/interface';

@Component({
  selector: 'app-youtube-videos',
  templateUrl: './youtube-videos.component.html',
  styleUrls: ['./youtube-videos.component.scss']
})
export class YoutubeVideosComponent implements OnInit {

  // host = host;
  items = [];

  constructor(
    private service: HttpService,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.service.getVideos().subscribe((res: any) => {
      this.items = res.Data.map(obj => {
        // sanitize the youTube url , so we don't need to change the actual youTube url 
        // https://stackoverflow.com/questions/42439060/how-to-embed-a-youtube-video-in-angular
        let youTube = this._sanitizer.bypassSecurityTrustResourceUrl(obj.video);
        return { ...obj, youTube };

      });
      // console.log(this.items);

    });
  }

}
