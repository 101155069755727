import { getLocaleMonthNames } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/services/http.service';
import { host, mediaUrl } from 'src/app/services/interface';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  @Input() line = false;
  history = [];
  readMore: boolean = false;
  constructor(
    private service: HttpService,
    private translator: TranslateService
  ) { }

  ngOnInit(): void {

    this.langChange(this.translator.defaultLang);

    // on language change
    this.translator.onDefaultLangChange.subscribe((res) => {
      this.langChange(res.lang);
    });

    // console.log(this.history);

  }
  // change language by user select
  langChange(lang) {
    if (lang === "en") {
      // alert('english');
      this.getHistory('English');

    }
    else if (lang === "ta") {
      // alert('tamil');
      this.getHistory('Tamil');
    }
  }

  // history with language
  getHistory(lang) {
    this.service.getHistory(lang).subscribe((res) => {

      this.history = res['Data'].map((data) => {

        return {
          'image':mediaUrl + data.PostImage,
          'name': data.PostName,
          'title': data.PostTitle,
          'content': data.PostContent,
          'contentShort': data.PostContent.slice(0, 292) + '...</p>',
          'date': data.SincDateTime,
          'isCollapsed': true
        };
      });
    }, (err) => { });
  }

}
