import { NgModule } from '@angular/core';
import {  Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApplicationFormComponent } from './components/application-form/application-form.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HomeComponent } from './components/home/home.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from './components/input/input.component';
import { HistoryFeedComponent } from './components/home/history-feed/history-feed.component';
import { HistoryComponent } from './components/home/history-feed/history/history.component';
import { FeedComponent } from './components/home/history-feed/feed/feed.component';
import { GalleryComponent } from './components/home/gallery/gallery.component';
import { YouthTeamComponent } from './components/home/youth-team/youth-team.component';
import { ReportsComponent } from './components/home/reports/reports.component';
import { FooterComponent } from './components/footer/footer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectComponent } from './components/select/select.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ErrrorInterceptor } from './interceptors/error/errror.interceptor';
import { SocialMediaComponent } from './components/nav-bar/social-media/social-media.component';
import { SlickCarouselComponent } from './components/home/gallery/slick-carousel/slick-carousel.component';
import { BannerComponent } from './components/home/banner/banner.component';
import { LoginModalComponent } from './components/home/youth-team/login-modal/login-modal.component';
import { PubCardComponent } from './components/publications/pub/pub-card/pub-card.component';
import { PubPageComponent } from './components/publications/pub/pub-page/pub-page.component';
import { PubDetailsComponent } from './components/publications/pub/pub-details/pub-details.component';
import { PubListComponent } from './components/publications/pub/pub-list/pub-list.component';
import { PaymentComponent } from './components/home/payment/payment.component';
import { LibraryComponent } from './components/library/library.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PhotosComponent } from './components/photos/photos.component';
import { YoutubeVideosComponent } from './components/youtube-videos/youtube-videos.component';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { LoginComponent } from './login/login.component';
import { BeneficiariesComponent } from './components/home/beneficiaries/beneficiaries.component';
import { OrgChartComponent } from './components/home/youth-team/org-chart/org-chart.component';
import { OrgTreeComponent } from './components/org-tree/org-tree.component';
import { LibrarybookdetailComponent } from './components/library/librarybookdetail/librarybookdetail.component';
import { LibraryhashComponent } from './components/library/libraryhash/libraryhash.component';
import { LibraryCommentDetailsComponent } from './components/library/library-comment-details/library-comment-details.component';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { SecretaryInServiceComponent } from './components/secretary-in-service/secretary-in-service.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { APP_INITIALIZER } from '@angular/core';
import { PubNewComponent } from './components/publications/pub/pub-new/pub-new.component';
import { AuthorPageComponent } from './components/publications/pub/author-page/author-page.component';
import { MetaService } from './services/metatag';
import { MetaModule } from '@ngx-meta/core';
import { ServerModule } from '@angular/platform-server';
import { InstagramShareButtonComponent } from './components/instagram-share-button/instagram-share-button.component';
import { HashtagsArticleSecretaryInServiceComponent } from './components/hashtags-article-secretary-in-service/hashtags-article-secretary-in-service.component';

// import { HashLocationStrategy, LocationStrategy  } from '@angular/common';


// import {provideClientHydration} from '@angular/platform-browser';



export function metaTagsFactory(metaService: MetaService) {
  return () => {
    const title = 'DMK Youth Wing';
    const description = 'தந்தை பெரியார் –-- பேரறிஞர் அண்ணா –-- முத்தமிழறிஞர் கலைஞர் –-- கழக தலைவர் அவர்கள் வழியில் தமிழ்நாட்டு மக்களின் மேன்மைக்காக உழைக்கவும், சமூக நீதி – சமதர்மம் கொண்ட சமுதாயத்தை அமைக்கவும் தி.மு.க இளைஞர் அணியில் இணைவீர்.';
    const image = 'https://www.youthwingdmk.in/assets/images/dmk_youthwing_logo.png';
    metaService.updateTags(title, description, image);
  };
}

@NgModule({

  declarations: [
    AppComponent,
    ApplicationFormComponent,
    ContactFormComponent,
    PageNotFoundComponent,
    HomeComponent,
    NavBarComponent,
    InputComponent,
    HistoryFeedComponent,
    HistoryComponent,
    FeedComponent,
    GalleryComponent,
    YouthTeamComponent,
    ReportsComponent,
    FooterComponent,
    SelectComponent,
    SocialMediaComponent,
    SlickCarouselComponent,
    BannerComponent,
    LoginModalComponent,
    PubCardComponent,
    PubPageComponent,
    PubDetailsComponent,
    PubListComponent,
    PaymentComponent,
    LibraryComponent,
    PhotosComponent,
    YoutubeVideosComponent,
    LoginComponent,
    BeneficiariesComponent,
    OrgChartComponent,
    OrgTreeComponent,
    LibrarybookdetailComponent,
    LibraryhashComponent,
    LibraryCommentDetailsComponent,
    PrivacyPolicyComponent,
    LoginPageComponent,
    SecretaryInServiceComponent,
    PubNewComponent,
    AuthorPageComponent,
    InstagramShareButtonComponent,
    HashtagsArticleSecretaryInServiceComponent,

  ],
  imports: [
    // BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    LayoutModule,
    NgbModule,
    HttpClientModule,
    NgSelectModule,
    NgxSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ToastrModule.forRoot({
      timeOut: 1500,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SlickCarouselModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    NgImageFullscreenViewModule,
    ShareButtonsModule.withConfig({
      debug: true,

    }),
    ShareIconsModule,
    MetaModule.forRoot(),



  ],
  providers: [
    // provideClientHydration(),
    Meta,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrrorInterceptor,
      multi: true
    },
    MetaService,
    {
      provide:APP_INITIALIZER,
      useFactory: metaTagsFactory,
      deps: [MetaService],
      multi: true

    },
    // {provide : LocationStrategy , useClass: HashLocationStrategy}

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
