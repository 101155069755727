import { Component, Input, OnInit, Output, EventEmitter,ElementRef,ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { mediaUrl, Report } from 'src/app/services/interface';

@Component({
  selector: 'app-pub-card',
  templateUrl: './pub-card.component.html',
  styleUrls: ['./pub-card.component.scss']
})
export class PubCardComponent implements OnInit {
  mediaUrl = mediaUrl;
  @Input() report: Report;

  @Output() readMore: EventEmitter<string>= new EventEmitter<string>();
  @ViewChild('card', {static: false, read: ElementRef}) cardElement: ElementRef;


  constructor(
    private router: Router,
    private elementRef: ElementRef<HTMLElement>,

  ) { }

  ngOnInit(): void {
  }

  readMoreFunc(id){
    this.readMore.emit(id);

  }
  // readMore(Id) {
  //   // this.router.navigate(['/report-details'],{queryParams:{Id:this.Id}})
  //   let navigationExtras: NavigationExtras = {
  //     queryParams: { 'Id': Id }
  //   };
  //   this.router.navigate(['/publication-details'], navigationExtras).then(() => {
  //     window.location.reload();
  //   });
  //   // this.router.navigateByUrl('/report-details', { skipLocationChange: true }).then(() => {
  //   //   this.router.navigate(['/report-details'], navigationExtras);
  //   // });
  // }
}
