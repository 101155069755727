import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { host, mediaUrl } from 'src/app/services/interface';

@Component({
  selector: 'app-librarybookdetail',
  templateUrl: './librarybookdetail.component.html',
  styleUrls: ['./librarybookdetail.component.scss']
})
export class LibrarybookdetailComponent implements OnInit {
  bookid: any;
  bookdetails: any;
  comments: any;
  use: any;
  // host = host;
  mediaUrl=mediaUrl
  fullDesc: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: HttpService,
  ) { }

  ngOnInit(): void {

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    this.route.queryParams.subscribe(res => {
      this.bookid = res.bookid;
      // console.log(this.bookid);
      // check the user came from youth team page
    });

    this.service.librarybookdetails(this.bookid).subscribe((res: any) => {
      // console.log(res.data);
      this.bookdetails = res.data.BookData[0];
      this.comments = res.data.Comments;
      // console.log(this.bookdetails, this.comments);

    });

  }

  commentDetails(id) {
    this.router.navigate(['library/librarycommentdetails'], { queryParams: { commentid: id, from: 'librarybookdetails' } });
    // console.log(id);
  }
  onReadMore() {
    this.fullDesc = true;
  }

}
