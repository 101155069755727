<div class="heading">
    <h1>{{'history-heading'|translate}}</h1>
</div>
<div class="row">
    <div class="col-xl-9 col-lg-9 col-md-12 history scrollable">
        <app-history></app-history>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-12 social-media">
        <app-feed></app-feed>
    </div>
</div>