import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private meta:Meta) { }

  ngOnInit(): void {
    this.meta.updateTag({ name: 'description', content: 'தனியுரிமைக் கொள்கை' });
    this.meta.updateTag({ name: 'twitter:description', content: 'தனியுரிமைக் கொள்கை' });

  }

}
