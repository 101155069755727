<!-- activate bootstrap grid when user click on dist member -->
<div class="org-chart" [ngClass]="{'row':activeDiv}">
    <div [ngClass]="{'col-md-8':activeDiv}">

        <button class="btn btn-danger rounded-pill btn-sm org-back-btn"
            (click)="onBack()">{{'go-back'|translate}}</button>

        <div class="body genealogy-body genealogy-scroll">
            <div class="genealogy-tree">
                <ul>
                    <li>
                        <a href="javascript:void(0);">
                            <div class="member-view-box">
                                <div class="member-details">
                                    <h3 class="heading">{{chartData.DistName}}</h3>
                                </div>
                            </div>
                        </a>
                        <ul class="active">
                            <li *ngIf="loggedIn">
                                <a href="javascript:void(0);" (click)="onSubdivision()">
                                    <div class="member-view-box">
                                        <div class="member-details">
                                            <h3 class="sub-heading">உட்பிரிவு</h3>
                                        </div>
                                    </div>
                                </a>
                            </li>

                            <li>
                                <a href="javascript:void(0);" (click)="onPublications()">
                                    <div class="member-view-box">
                                        <div class="member-details">
                                            <h3 class="sub-heading">மாவட்ட வெளியீடுகள்</h3>
                                        </div>
                                    </div>
                                </a>
                            </li>

                            <li>
                                <a href="javascript:void(0);">
                                    <div class="member-view-box">
                                        <div class="member-details">
                                            <h3 class="sub-heading">மாவட்ட அமைப்பாளர்</h3>
                                        </div>
                                    </div>
                                </a>

                                <ul>
                                    <li>
                                        <div *ngFor="let head of chartData.DistHead;let i=index">
                                            <div *ngIf="head.type=='mavattam'">
                                                <a href="javascript:void(0);" (click)="onMember(head.Id,i)">
                                                    <div class="member-view-box">
                                                        <div class="member-details">
                                                            <!-- highlight selected member -->
                                                            <!-- https://stackoverflow.com/questions/52361513/how-highlight-a-div-on-click-in-angular -->
                                                            <h3 [ngClass]="{'highlight':selectedId==i}" class="member">
                                                                {{head.Name}}</h3>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>

                            <li>
                                <a href="javascript:void(0);">
                                    <div class="member-view-box">
                                        <div class="member-details">
                                            <h3 class="sub-heading">மாவட்ட துணை அமைப்பாளர்</h3>
                                        </div>
                                    </div>
                                </a>

                                <ul>
                                    <li>
                                        <div *ngFor="let head of chartData.DistHead; let i=index">
                                            <div *ngIf="head.type=='mavattamthunai'">
                                                <a href="javascript:void(0);" (click)="onMember(head.Id,i)">
                                                    <div class="member-view-box">
                                                        <div class="member-details">
                                                            <h3 [ngClass]="{'highlight':selectedId==i}" class="member"
                                                                class="member">
                                                                {{head.Name}}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div [ngClass]="{'col-md-4':activeDiv}" *ngIf="distMemberData.name">
        <div class="profile vl py-4">
            <div class="profile-wrapper">
                <img class="member-img mb-2" [src]="distMemberData['photo']" alt="{{distMemberData['photo']}}">
                <p class="member-name"><b>பெயர் : </b>{{distMemberData.name}}</p>
                <p class="member-address"> {{distMemberData.post}} - {{distMemberData.kalagaDistrict}}</p>
                <p class="member-address"><b>முகவரி : </b>{{distMemberData.address}}</p>
            </div>
        </div>

    </div>
</div>