import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  private apiDataSubject = new BehaviorSubject<{ title: string; description: string; image: string }>({ title: '', description: '', image: '' });
  apiData$ = this.apiDataSubject.asObservable();

  constructor(private meta: Meta) { }

  updateTags(title: string, description: string, image: string) {
    this.meta.updateTag({ name: 'title', content: title });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({ name: 'twitter:title', content: title });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'twitter:image', content: image });
  }

  setApiData(data: { title: string; description: string; image: string }) {
    this.apiDataSubject.next(data);
  }
}




//import { Injectable } from '@angular/core';
// import { Meta } from '@angular/platform-browser';

// @Injectable({
//     providedIn: 'root'
// })
// export class MetaService {

//     constructor(private meta: Meta) { }

//     updateTags(title: string, description: string, image: string) {
//         this.meta.updateTag({ name: 'title', content: title });
//         this.meta.updateTag({ name: 'description', content: description });
//         this.meta.updateTag({ property: 'og:title', content: title });
//         this.meta.updateTag({ property: 'og:description', content: description });
//         this.meta.updateTag({ property: 'og:image', content: image });
//         this.meta.updateTag({ name: 'twitter:title', content: title });
//         this.meta.updateTag({ name: 'twitter:description', content: description });
//         this.meta.updateTag({ name: 'twitter:image', content: image });
//     }

// }
