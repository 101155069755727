<div class="slick-wrapper">
  <!-- You can now use your library component in app.component.html -->
  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <!-- show only 10 images and videos -->
    <div ngxSlickItem *ngFor="let item of items.slice(0,9);let i = index" class="slide" (click)="showPreview(i)">
      <img [src]="mediaUrl+item.images" *ngIf="!video" width="100%">
      <div class="card" *ngIf="video">
        <!-- <video [src]="item.video" width="100%" controls></video> -->
        <iframe width="100%" frameborder="0" allowfullscreen [src]="item.youTube"></iframe>

        <p class="title">{{item.Title | titlecase}}</p>
        <!-- <p class="description">{{item.Desc}}</p> -->

      </div>
    </div>
  </ngx-slick-carousel>
</div>

<!-- <ng-image-fullscreen-view [images]="images" [imageIndex]="selectedImageIndex" [show]="showFlag"
  (close)="closeEventHandler()" *ngIf="images"></ng-image-fullscreen-view> -->