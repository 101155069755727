<div class="bg-image">
    <div class="row center m-0">
        <div class="col-xl-4 col-lg-6 pr-1 description">
            <div class="description-heading">
                {{'lets-join'|translate}}
            </div>
            <div class="description-text">
                {{'join-full'|translate}}
            </div>
        </div>
        <!-- <div class="col-xl-4 col-lg-6 site-form mt-4" data-aos="fade-right" data-aos-duration="1000"> -->
        <div class="col-xl-4 col-lg-6 site-form mt-4">
            <p style="color: rgb(197, 6, 6);font-size: 1.1rem;">{{'personal-information'|translate}}
            </p>
            <div class="row">
                <div class="col-lg-6">
                    <app-input [control]="f.FirstName" placeHolder="{{'full-name'|translate}}"></app-input>
                </div>
                <div class="col-lg-6">
                    <app-input [control]="f.FatherName" placeHolder="{{'father-name'|translate}}"></app-input>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 pt-2">
                    <!-- <app-input [control]="f.Email" placeHolder="மின்னஞ்சல்"></app-input> -->
                    <input class="form-control" type="date" [formControl]="f.DOB" type="text"
                        onfocus="(this.type = 'date')" placeholder="{{'date-of-birth'|translate}}">
                    <ng-container *ngIf="f.DOB.errors && f.DOB.touched">
                        <div class="error" *ngIf="f.DOB.errors.required ">
                            {{'date-of-birth'|translate}} {{'required'|translate}}
                        </div>
                    </ng-container>
                </div>
                <div class="col-lg-6">
                    <app-input [control]="f.MobileNo" placeHolder="{{'mobile-number'|translate}}"></app-input>
                </div>
            </div>
            <div style="color: rgb(197, 6, 6);font-size: 1.1rem;">
                {{'address'|translate}}
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <app-select *ngIf="lang;else english" [options]="district1" bindValue="district.id"
                        [control]="f.District">
                    </app-select>

                    <ng-template #english>

                        <app-select [options]="district" bindValue="district.id" [control]="f.District">
                        </app-select>
                    </ng-template>


                    <!-- <app-select *ngIf="currentLang == 'en'" [options]="district.name" bindValue="district.name"
                        [control]="f.District">
                    </app-select> -->
                    <!-- <app-select *ngIf="currentLang == 'ta'" [options]="district1" [control]="f.District"></app-select> -->

                </div>
                <div class="col-lg-6">
                    <!-- <app-select [options]="MlaAssembly" [control]="f.MlaAssembly"></app-select> -->

                    <app-select *ngIf="lang;else eng" [options]="MlaAssembly1" bindValue="MlaAssembly.id"
                        [control]="f.MlaAssembly">
                    </app-select>

                    <ng-template #eng>

                        <app-select [options]="MlaAssembly" bindValue="MlaAssembly.id" [control]="f.MlaAssembly">
                        </app-select>
                    </ng-template>

                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <app-select *ngIf="lang;else mpeng" [options]="MpAssembly1" bindValue="MpAssembly.id"
                        [control]="f.MpAssembly"></app-select>
                    <ng-template #mpeng>
                        <app-select [options]="MpAssembly" bindValue="MpAssembly.id" [control]="f.MpAssembly">
                        </app-select>
                    </ng-template>
                </div>
                <div class="col-lg-6">
                    <app-input [control]="f.Ward" placeHolder="{{'ward-number'|translate}}"></app-input>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <app-input [control]="f.Address" placeHolder="{{'town/area'|translate}}"></app-input>
                </div>
            </div>

            <div style="color: rgb(197, 6, 6);font-size: 1.1rem;">
                {{'social-media'|translate}}
            </div>
            <input class="form-control mt-3" [formControl]="f.FacebookLink" type="text" placeholder="Facebook Link">
            <input class="form-control mt-3" [formControl]="f.TwitterLink" type="text" placeholder="Twitter Link">
            <input class="form-control my-3" [formControl]="f.InstaLink" type="text" placeholder="Instagram Link">

            <div style="color: rgb(197, 6, 6);font-size: 1.1rem;">
                {{'upload-identity-card'|translate}}
            </div>
            <div style="text-align: center;">
                <input type="file" (change)="handleFileInput($event)" accept="image/x-png,image/jpeg" #idInput
                    style="display: none;">
                <div class="m-4">
                    <button class="btn btn-primary" (click)="idInput.click()">
                        {{'upload-image'|translate}}
                    </button>
                    <div *ngIf="file" style="font-size: 1.2rem;">
                        <div class="mt-4" style="font-weight: bold;">
                            {{'uploaded-image'|translate}}
                        </div>
                        <div>{{file.name}}</div>
                        <div>{{file.type}}</div>
                    </div>
                    <input multiple #User_media (change)="handleFileInput($event)" type='file'
                        accept="image/x-png,image/jpeg" style="display:none" />
                </div>
            </div>
            <div class="col-12 submit mt-2">
                <button class="btn submit-btn" style="background-color:rgb(197, 6, 6)" (click)="submit()">
                    {{'submit'|translate}}
                </button>
            </div>
        </div>
    </div>
</div>