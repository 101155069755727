import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-history-feed',
  templateUrl: './history-feed.component.html',
  styleUrls: ['./history-feed.component.scss']
})
export class HistoryFeedComponent implements OnInit {

  constructor(
    private service: HttpService
  ) { }

  ngOnInit(): void {
  }

}
