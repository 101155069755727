import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    constructor(
        private translator: TranslateService
    ) { }
    private data = new BehaviorSubject('default data');
    data$ = this.data.asObservable();
    district: string;
    memberLogin: string;
    loginFormValue: any;
    dropdownVal: string;

    changeData(data: string) {
        this.data.next(data);
        this.district = data;
    }
    setLanguage(lang: string) {
        this.translator.setDefaultLang(lang);
    }
    setDropdownVal(value) {
        this.dropdownVal = value;
    }
}
