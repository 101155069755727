<div class="bg-image">
    <div class="row center m-0">
        <div class="col-xl-12 description">
            <div class="description-heading">
                {{'book-commentary'|translate}}
            </div>
        </div>

        <div class="libdrp col">
            <!-- <app-select [options]="librarydrop" [control]="f.librarydrop" (valuechange)='displaydrop($event)'></app-select> -->
            <!-- <button class="btn btn-primary" routerLink="/libraryhash">Commentry</button> -->
            <span class="taghead">Tags -</span>
            <!-- <ul>
                <li class="badge rounded-pill" (click)="hashCol('ALLCAT')">{{"alltag"|translate}}</li>
            </ul> -->
            <ul class="d-flex flex-wrap tag">
                <div *ngFor="let item of hashvalue;let i=index" (click)="onTagClick(i)">
                    <li class="badge rounded-pill mr-2" [ngClass]="{'selectedTag':selectedTag==i}"
                        (click)="hashCol(item.hashtagsval)"><span
                            *ngIf="item.hashtagsval!='ALLCAT'else allCat">{{item.hashtagsval}}</span>
                        <ng-template #allCat>{{"alltag"|translate}}</ng-template>
                    </li>

                </div>
            </ul>
        </div>

        <div class="container-fluid">
            <div class="row" *ngIf="comments.length;else noContent">
                <div class="comcarcol col-md-12">
                    <div class="comcardrow row">

                        <div class="card col-md-3 " *ngFor="let item of comments">
                            <img (click)="commentDetails(item.PostId)" class="image"
                                [src]="mediaUrl+item?.BookLibImg" alt="Avatar">
                            <div class="container">
                                <p class="date-time">
                                    {{item?.PostDate|date:'dd MMM yyyy'}}
                                    <!-- 12 Oct 2021 -->
                                </p>
                                <h5 class="heading" (click)="readMoreFunc(report?.Id)"> {{item.TitleLib}}</h5>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <ng-template #noContent>
                <div class="card p-4 mt-4 mx-auto" style="text-align: center;width: 98%;">
                    <div class="no-reports">
                        {{'reports-not-found'|translate}}
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>