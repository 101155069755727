<div class="bg-image">
    <div class="row center m-0">
        <div class="description">
            <div class="description-heading">
                {{'privacy-policy'|translate}}
            </div>
            <!-- <div data-aos="fade-up" data-aos-duration="1000" class="description-text content"> -->
            <div class="description-text privacyData">
                <!-- <p>{{'donation-content'|translate}}</p> -->
                <p>About the App</p>

                <p> The DMK Youth Wing mobile app has been developed to easily facilitate two-way communication between
                    the Youth Wing and its members, cadres, volunteers, citizens, and followers of Udhayanidhi Stalin.
                    By doing so, we will be able to easily inform everyone on the app about the day-to-day activities of
                    the Youth wing and enable them to take part in social media activities as well. In addition to that,
                    users who aren’t yet members of the Youth wing will also be able enroll themselves as members using
                    the app. Being the first political party in India to form a Youth wing back in 1967, this is our
                    next step in shaping the future leaders of the nation.
                </p>


                <p>DATA PRIVACY POLICY</p>

                <p>This privacy policy applies to the current and former users who have registered on the 'DMK Youth
                    wing' mobile application.</p>


                <p>General:</p>
                <p>We are committed to being transparent about how we collect and use that data and to meet our data
                    protection obligations in accordance with the General Data Protection Regulations (GDPR).
                </p>


                <p>What information do we collect?</p>
                <p>We collect and process user information through the mobile app’s registration and transfer of files
                    if necessary for communication. This includes;</p>

                <ul>
                    <li>Personal information - Name, Email, Mobile number, and Address.</li>
                    <li>Non-personal information - Files and Documents.</li>
                </ul>

                <p>Where do we store the data we collect?</p>
                <p>Data is stored in a range of different places, including on our secure, encrypted database, our
                    secure cloud-based storage folders, and other IT systems including our email system.</p>

                <p>Why do we process personal data?</p>
                <ul>
                    <li>
                        Basic personal information such as name and email/mobile number are collected to create a unique
                        account for every user.
                    </li>
                    <li>
                        Files and Documents can be shared by the users if they deem it necessary to communicate the
                        information they want during conversations.
                    </li>
                </ul>


                <p>Who has access to data?</p>
                <p>User information will be shared appropriately and confidentially only with the IT staff to
                    fulfilling the responsibilities of their role to create a platform for members to enroll in the
                    Youth wing and communicate with each other easily.
                </p>


                <p>How do we protect data?</p>
                <p>We take the security of your data seriously. We have internal policies and controls in place to
                    ensure as far as we can that your data is not lost, accidentally destroyed, misused, or disclosed,
                    and is not accessed by anyone else except by the employees while performing their duties. Where we
                    engage third parties to process personal data on our behalf, they do so on the basis of written
                    instructions and under a duty of confidentiality and are obliged to implement appropriate technical
                    and organizational measures to ensure the security of data.
                </p>

                <p>For how long do we keep data?</p>
                <p>We will only retain your personal data for as long as necessary to fulfill the purpose for which we
                    collected it. To determine the appropriate retention period for personal data we consider the
                    amount, nature, and sensitivity of the data as well as the purpose for which it was collected.
                </p>

                <p>Your rights</p>
                <p>As a data subject, you have a number of rights. You can:</p>

                <ul>
                    <li> decide whether or not to provide data that is marked as non-mandatory.</li>
                    <li>access and obtain a copy of your data on request.</li>
                    <li>change incorrect or incomplete data.</li>
                    <li>remove data collected under the non-mandatory category.</li>
                    <li>stop processing data if it is inaccurate.</li>
                </ul>

                <p>
                    If you would like to exercise any of these rights, please contact
                    Address

                    Anbagam,
                    DMK Youth Wing Head Office,
                    Anna Salai,
                    614, Teynampet,
                    Chennai- 600018.

                    044-24349970

                    Email: youthwing@dmk.in
                </p>
            </div>
            <!-- <div data-aos="fade-up" data-aos-duration="1000" class="description-text"> -->
            <!-- <div class="description-text">
                <p class="top" style="font-size: 1.3rem;">DMK Youth Wing Charitable Trust</p>
                <p class="middle">
                    <span class="label">{{'bank-name'|translate}}</span> - {{'indian-bank'|translate}}<br>
                    <span class="label">{{'branch'|translate}}</span> - {{'teynampet'|translate}},
                    {{'chennai'|translate}}-18.<br>
                    <span class="label">{{'savings-account'|translate}}</span> - <span
                        class="numberStyle">783271504</span><br>
                    <span class="label"> {{'ifsc-code'|translate}}</span> - <span class="numberStyle">IDIB000T011</span>
                </p>
            </div> -->
        </div>
    </div>
</div>