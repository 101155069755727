import { Component, EventEmitter, Input, OnInit, Output, NgZone , ElementRef, ViewChild } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { Report, mediaUrl, ApiResponse, DateFilter } from 'src/app/services/interface';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/metatag';
import { CardStateService } from 'src/app/services/cardstateservice';

@Component({
  selector: 'app-secretary-in-service',
  templateUrl: './secretary-in-service.component.html',
  styleUrls: ['./secretary-in-service.component.scss']
})
export class SecretaryInServiceComponent implements OnInit {
  [x: string]: any;
  mediaUrl = mediaUrl;
  @ViewChild('card') cardElement: ElementRef;

  // @Input() report: Report;
  toggleFilter: boolean = false;
  minDate = new Date(2023, 0, 1);
  maxDate = new Date(2023, 12, 1);
  startDate: string;
  endDate: string;
  distId: string = 'abc';
  reports: Report[];
  categories: Report[];
  icon: any;
  selectedTagID: any;
  hashvalue: any = [];
  selectedTag: any = null;
  selectedTagC: any = null;
  selectedTagB: any = null;
  catValue: any = [];
  currentArticleValue: any = [];
  previousArticleValue: any = [];
  currentLang: string;
  lang: boolean;
  AllCat: boolean;
  isPrevious: boolean;
  isCurrent: boolean;
  data: any;
  loadlang: any;
  // cat: string = "secreterayinservice";

  roomsFilter: DateFilter;
  // @Output() readMore: EventEmitter<string>= new EventEmitter<string>();
  category: string = "secretaryInPublicService";
  id: string = " CHNH";
  cat: any;
  hashtagsval: string;
  current: string;
  previous: string;
  previousPressed: boolean;
  isSpecialCategoryT:boolean;
  isSpecialCategoryE:boolean;
  isSpecial:boolean;
  constructor(
    private metaService: MetaService,
    private service: HttpService,
     private router: Router,
     private translate: TranslateService,

     private ngZone: NgZone,
    private meta: Meta) {



    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 0, 0, 15);
    this.maxDate = new Date(currentYear + 0, 11, 31);

    this.translate.onDefaultLangChange.subscribe((res) => {
      // console.log('translation Response', res);
      this.currentLang = res.lang;
      localStorage.setItem("ta", this.currentLang);
      if (this.currentLang == 'ta') {
        this.lang = true;
      } else {
        this.lang = false;
      }
    });
    if (localStorage.getItem("ta") == 'ta') {
      this.lang = true;
    } else {
      this.lang = false;
    }
  }


  // current_series, previous_series


  ngAfterViewInit() {
    // console.log('element', document.getElementById('Software Developer'));
    var itemid =  localStorage.getItem("itemCard");
   console.log(itemid,"element102");
   const elementID = document.getElementById(itemid);
  //  console.log(elementID,"element1102");
   setTimeout(() => {
   elementID.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
   localStorage.removeItem("itemCard");
   },2000);
  }

  todayIsAfterJune3: boolean = false;

  checkDate() {
    const today = new Date();
    const june3 = new Date(today.getFullYear(), 5, 3); // June is 5 because getMonth() is 0-based
    this.todayIsAfterJune3 = today >= june3;
  }

filterCategories(): void {

    const currentDate = new Date();
    const juneThird = new Date(currentDate.getFullYear(), 5, 3); // June is 5 since months are 0-based
console.log(currentDate >= juneThird,"condition");

    if (currentDate >= juneThird) {
      this.filteredCategories = this.currentArticleValue;
    } else {
      this.filteredCategories = this.currentArticleValue.filter(category =>
        category.subcat_english !== 'special articles' && category.subcat_tamil !== 'சிறப்பு கட்டுரைகள்'
      );
    }
  }
  ngOnInit(): void {

this.checkDate();
     this.previousPressed = false;
    let today = new Date();
    let date = today.getFullYear() + '-' + (today.getMonth() + 0o1) + '-' + today.getDate();
    console.log(date, "current datesssssssssss"); // output will be in yyyy-mm-dd format
    this.AllCat = true;
    // console.log(this.translate.getBrowserLang(), "46 default lang after changing");
    // this.hashtagsval = "ALLCAT"; // to show all tags as  default and active
    // this.catValue.id = "CHNH";
    this.translate.onDefaultLangChange.subscribe((res) => {
      this.currentLang = res.lang;
      localStorage.setItem("ta", this.currentLang);
      if (this.currentLang == 'ta') {
          console.log('translation Response1', res.lang == 'ta');
        this.lang = true;
      } else {
        this.lang = false;
      }
    });

    this.service.getCatDetails().subscribe((res: any) => {
      console.log(res, "gtcatttttttttttttt");
      this.reports=[];

      let arr = res.data.map(element => {

        const title = element.PostTitle; // Adjust this according to your API response structure
      const description = element.PostTitle; // Adjust this according to your API response structure
      const image = mediaUrl + element.PostImage; // Adjust this according to your API response structure

        return {
          Id: element.Id, PostTitle: element.PostTitle, PostName: element.PostName,
          PostContent: element.PostContent,
          categories: element.categories,
          PostImage: element.PostImage,
          VideURL: element.VideURL,
          SincDateTime: element.SinceDateTime,
          PostDateTime: element.PostDateTime,
          hashtags: element.hashtags,

        };
      });

      this.reports = [...arr];  //show all categories detail on page load
      // this.reports = [];  //show all categories detail on page load - testing empty
      console.log(this.reports[0]['hashtags'], "array38");

      this.ngZone.run(() => { });
    });
    // this.service.getPublications(this.category, this.id).subscribe((res: any) => {
    //   // console.log(res, "17se");
    //   // this.report = res.Data;
    //   // console.log(this.report, "24");
    //   // if (res.length) res.push({ Id: null, hashtagsval: 'ALLCAT' });

    //   let arr = res.Data.map(element => {
    //     return {
    //       Id: element.Id, PostTitle: element.PostTitle, PostName: element.PostName,
    //       PostContent: element.PostContent,
    //       categories: element.categories,
    //       PostImage: element.PostImage,
    //       VideURL: element.VideURL,
    //       SincDateTime: element.SinceDateTime,
    //       PostDateTime: element.PostDateTime

    //     };
    //   });
    //   // console.log(arr, "array38");

    //   this.reports = [...arr];  //show all categories detail on page load
    //   // this.reports = [];  //show all categories detail on page load - testing empty

    //   this.ngZone.run(() => { });
    // });


    this.service.getCategory().subscribe((res: any) => {
      console.log("123","testingg");
      console.log(res.data.current_series, "category response 62");
      this.catValue = res.category;

      // this.currentArticleValue  =   this.appendArticles(res.data.current_series);
      this.currentArticleValue =res.data.current_series;
      this.filterCategories();
      this.previousArticleValue = res.data.previous_series;


      console.log(this.currentArticleValue, "sec valuessssssssssssssssssssssss");
      // if (this.catValue.length) this.catValue.push({ Id: null, hashtagsval: 'ALLCAT', cat_icon: 'CategoryIcon/fb515405-10da-47bf-b498-2b904b1afa5d.png' });
      // this.selectedTag = this.catValue.length - 1;
      // this.selectedTag = this.catValue.length;
    });


    // var b = this.translate.getBrowserLang();
    // var d = this.translate.getDefaultLang();
    // console.log(b, "browselang");
    // console.log(d, "default language");
  }

  onFilterClick() {
    // console.log(this.startDate, "selected  date");
    this.toggleFilter = !this.toggleFilter;
  }
  onDate(event): any {

    this.roomsFilter = event;
    this.getData(this.roomsFilter);

  }
  dateSubmit1() {
    this.AllCat = true;
    // if (!this.startDate && !this.endDate) { alert('தேதி குறிப்பிடப்படவில்லை.'); }
    // else if (!this.startDate) { alert('தொடக்க தேதி குறிப்பிடப்படவில்லை.'); }
    console.log(this.selectedTag, "catergory selected");
    console.log(this.endDate, "selected dateeeee");


    if (!this.endDate) { alert('முடியும் தேதி குறிப்பிடப்படவில்லை.'); }
    else {
      this.onFilterClick();
      this.reports = [];
      if (this.selectedTag == null) {
        this.selectedTag = null;
        this.service.getSpecificCategory(this.selectedTagID, this.endDate).subscribe((res: any) => {
          console.log(res, "get category details by dateeenullll");

          this.reports = res.data;
        });
      } else {
        this.reports = [];
        this.service.getSpecificCategory(this.selectedTagID, this.endDate).subscribe((res: any) => {
          console.log(res, "get category details by dateee");

          this.reports = res.data;
        });
      }
      if (this.selectedTagID == undefined) {
        this.selectedTagID = null;
        this.reports = [];
        this.service.getSpecificCategory(this.selectedTagID, this.endDate).subscribe((res: any) => {
          console.log(res, "get nulllllllllll");

          this.reports = res.data;
        });
      }
      //api for filter by date
      // this.service.getReportsByDate(this.startDate, this.endDate, this.category, this.distId).subscribe((res: ApiResponse) => {
      //   this.reports = res.Data;
      // });
    }
  }

  getAllData() {
    this.AllCat = true;
    this.reports = [];
    this.service.getCatDetails().subscribe((res: any) => {
      console.log(res, "gtcatttttttttttttt");

      let arr = res.data.map(element => {
        return {
          Id: element.Id, PostTitle: element.PostTitle, PostName: element.PostName,
          PostContent: element.PostContent,
          categories: element.categories,
          PostImage: element.PostImage,
          VideURL: element.VideURL,
          SincDateTime: element.SinceDateTime,
          PostDateTime: element.PostDateTime

        };
      });
      console.log("arr", "array38");

      this.reports = [...arr];  //show all categories detail on page load
      // this.reports = [];  //show all categories detail on page load - testing empty

      this.ngZone.run(() => { });
    });
  }

  hashCol(id: any, type: string, cat: string, icon: any) {
    console.log(id,type, cat, icon, "hashCol===");
    // this.AllCat = true;

    this.selectedTagID = id;
   if(type =="சிறப்பு கட்டுரைகள்" || type =="Special Articles" ){
    this.isSpecial = true;
   }
   else{
    this.isSpecial = false;
   }

  //  if(){
  //   this.isSpecial = true;
  //  }
   console.log(this.isSpecial ,"english onclick");

    console.log(mediaUrl + icon, "hashCol 88");
    this.icon = icon;
    console.log(this.endDate, "llllllllllllllllllll");
    if (this.endDate == undefined) {
      var date = null;
    }
    console.log(this.cat,"cat===257");
    if (cat == undefined) {
      let today = new Date();

      // var date = null;
      let date = today.toISOString().split('T')[0];
      // var date = "2024-3-4" ;


    }
     // const date = this.endDate;
    if (id !== undefined && cat !== "ALLCAT") {
      console.log("hieee");




      let today = new Date();
      // var dateFilter = today.getFullYear() + '-' + (today.getMonth() + 0o1) + '-' + today.getDate();

      // var date = null;
      let date = today.toISOString().split('T')[0];
      // var date = "2024-3-4" ;


      this.service.getSpecificCategory(id, date).subscribe((resp: any) => {
        console.log(id, date, "iddd");
        console.log(resp, "107 specific cat");
        // this.categories = resp

        this.categories = [];
        let arr1 = resp.data.map(element => {
          return {
            Id: element.Id, PostTitle: element.PostTitle, PostName: element.PostName,
            PostContent: element.PostContent,
            categories: element.categories,
            PostImage: element.PostImage,
            VideURL: element.VideURL,
            SincDateTime: element.SinceDateTime,
            PostDateTime: element.PostDateTime

          };
        });
        this.categories = [...arr1];
        console.log(this.categories, "this.categories");


      });
    }
    else {
      this.activeRoute.queryParams.subscribe(params => {
        this.id = params.Id;
        // console.log(params.Id, "123");
      }
      );
      // date = null;
      let today = new Date();
       date = today.toISOString().split('T')[0];
      this.selectedTagID = this.id;
      // this.onTagClick(id);
      this.hashCol(this.id,"undefined", "undefined", "undefined");


    }
    // else {

    //   console.log("heloo");
    //   this.service.getPublications("secretaryInPublicService", "CHNH").subscribe((res: any) => {
    //     console.log(res, "allcategory -allteg");
    //     this.data = res.Data;
    //     // this.reports = res.category;
    //     let arr1 = res.Data.map(element => {
    //       return {
    //         Id: element.Id, PostTitle: element.PostTitle, PostName: element.PostName,
    //         PostContent: element.PostContent,
    //         categories: element.categories,
    //         PostImage: element.PostImage,
    //         VideURL: element.VideURL,
    //         SincDateTime: element.SinceDateTime,
    //         PostDateTime: element.PostDateTime

    //       };
    //     });
    //     this.categories = [...arr1];

    //   });
    // }
  }
  onTagClick(index) {
    console.log(index, "index cat 92");
    this.selectedTag = index;
    this.AllCat = false;
    this.isPrevious = false;
    // this.selectedTagC = false;
    // console.log(this.AllCat, "allcat");
  }
  onTagClick1(index){
    console.log(index,"index onclick previoustag");
    this.selectedTag = index;
    this.AllCat = false;
    this.isPrevious = true;
    this.selectedTagC = null;


  }

  getClassBindings(index: number) {
    return {
      'selectedTagC': this.selectedTagC == index,
      'fas fa-star top-right-star latest-label bounce-blink': this.someCondition
    };
  }

  onTagClickCurrentTag(index) {
    console.log(index, "index cat current 336");
    this.selectedTagC = index;
    this.AllCat = false;
    this.isPrevious = false;
    // console.log(this.AllCat, "allcat");
    this.selectedTagB = null;
    this.selectedTag = "";


  }

  onTagPressed(type: string){
    console.log(type,"type tag pressed");

 this.selectedTag = null;
    if(type==="previous"){

      this.AllCat = false;



      this.selectedTagB = type;
      this.isPrevious = true;
      this.isCurrent = false;
      // this.categories= [];
    }
    // if(type==="current"){

    //   this.AllCat = false;

    //   this.selectedTagB = type;
    //   this.isCurrent = true;
    //   this.isPrevious = false;
    //   this.categories =[];
    // }

  }



  back() {
    this.router.navigate(['/secretary-in-public-service']);
  }

  readMore(event) {

    localStorage.setItem("itemCard", event);
   console.log(event, "dddddd");


   ///////////////////////////
    let navigationExtras: NavigationExtras = {
      queryParams: { 'Id': event }
    };
    // this.router.navigate(['/publication-details'], navigationExtras);
    this.router.navigate(['pubnew'], { queryParams: { 'Id': event } });

    // this.router.navigate(['/pubnew'], navigationExtras);


  }



  open(event: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: { 'Id': event }
    };
    this.router.navigate(['/pubnew'], navigationExtras);
    // this.router.navigate(['./pubnew']);
    // this.router.navigate(['./application']);

  }

  clear() {
    this.getAllData();
    this.endDate = null;

    // this.onFilterClick();
  }
  clear1() {
    this.getAllData();
    this.selectedTag = null;
    // this.hashCol(this.id, this.endDate, '');
    this.endDate = null;


  }
  // clear() {
  //   this.getAllData();
  //   this.endDate = null;

  //   this.onFilterClick();
  // }

}



