<div class="bg"></div>

<h2 class="heading my-4">{{'photo-gallery'|translate}}</h2>

<div class="row">
    <div *ngFor="let photo of photos;let i = index" (click)="showPreview(i)"
        class="col-sm-2 col-md-4 col-lg-3 col-xl-2">
        <div class="images">
            <img class="image" width="100" height="100" [src]="mediaUrl+photo.images" alt="">
        </div>
    </div>
</div>

<ng-image-fullscreen-view [images]="images" [imageIndex]="selectedImageIndex" [show]="showFlag"
    (close)="closeEventHandler()" *ngIf="images"></ng-image-fullscreen-view>