import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { NavigationExtras } from '@angular/router';
import { Report, mediaUrl, ApiResponse, DateFilter } from 'src/app/services/interface';
import { Router } from '@angular/router';


@Component({
  selector: 'app-author-page',
  templateUrl: './author-page.component.html',
  styleUrls: ['./author-page.component.scss']
})
export class AuthorPageComponent implements OnInit {

  slideConfig = {
    "slidesToShow": 3,
    "dots": true,
    "slidesToScroll": 1,
    "infinite": false,
    'draggable': true,
    "arrows": false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };
  mediaurl= mediaUrl;
  name: string ;
  bio: string ;
  title: string = "Article name wrtiten by Author";
  img: string;
  twturl: string;
  fburl: string;
  authorNotes: any = [];
  socialShareUrl: any;

    // user: any = {
  //   firstName: '',
  //   lastName: '',
  //   email: ''
  // };
  authorId: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private service: HttpService,
    private router: Router,
    // private userService: UserService
  ) { }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(params => {
      this.authorId = params.Id;
      // console.log(params.Id, "123");
    }
    );

    this.service.getAuthor(this.authorId).subscribe((res: any)=>{
      console.log("res====");
      // console.log(res);
      this.authorNotes = res.author_datas;

      console.log(res.author_datas);

      this.name = res.data[0]['author_name'];
      this.img = res.data[0]['photo'];
      this.bio = res.data[0]['author_info'];
      this.fburl = res.data[0]['facebook_url'];
      this.twturl = res.data[0]['twitter_url'];
      


    })
    // this.userService.getUserProfile().subscribe((user: User) => {
    //   this.user = user;
    // });
  }

  readMore(event) {
    console.log(event, "dddddd");
    let navigationExtras: NavigationExtras = {
      queryParams: { 'Id': event }
    };
    // this.router.navigate(['/publication-details'], navigationExtras);
    this.router.navigate(['pubnew'], { queryParams: { 'Id': event } });

    // this.router.navigate(['/pubnew'], navigationExtras);

  }
  onSubmit() {
    // this.userService.updateUserProfile(this.user).subscribe(() => {
    //   console.log('User profile updated successfully');
    // }, (error) => {
    //   console.log('Error updating user profile: ', error);
    // });
  }
}


;


