import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-instagram-share-button',
  templateUrl: './instagram-share-button.component.html',
  styleUrls: ['./instagram-share-button.component.scss']
})
export class InstagramShareButtonComponent implements OnInit {
  @Input() urlToShare: string = 'https://example.com'; // You can pass the URL dynamically

  // shareOnInstagram() {
  //   const instagramAppUrl = 'instagram://app';
  //   window.location.href = instagramAppUrl;
  //   window.open(instagramAppUrl, '_blank'); // Open the URL in a new tab
  // }



  ngOnInit(): void {






  }


  shareOnInstagram() {
    this.copyToClipboard(this.urlToShare);
    // alert('Link copied to clipboard. You will be redirected to Instagram.');

    // Open Instagram website
    const instagramUrl = 'https://www.instagram.com/';
    window.open(instagramUrl, '_blank');
  }

  copyToClipboard(text: string) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

}
