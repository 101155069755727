<div class="background-img"></div>



<div class="container-fluid">
  <div class="row row1 text-center mt-4">
    <div class="col">
      <h1>எழுத்தாளர் விவரங்கள்</h1>
    </div>

  </div>

  <div class="row row1 evenspace">
    <div class="col-md-4 text-center">
      <!--image of author-->

      <img [src]="mediaurl + img" alt="author's profile picture" class="mx-auto img-fluid">




    </div>

    <div class="col-md-8">
      <!--authors details-->

      <h2 class="hmob">{{name}}</h2>
      <!-- <h2 class="hmob">{{title}}</h2> -->
      <p class="ptag">{{bio}}</p>
      <div class="text-center" style="width: 100%;" class="fb">


        <!-- <h2 class="atag1">Social Pages</h2>
        <a href="https://www.flaticon.com/free-icons/twitter-logo" title="twitter logo icons" class="text-center">click
          to follow on
          Facebook</a>

        <a href="https://www.flaticon.com/free-icons/twitter-logo" title="twitter logo icons">click to follow on
          Twitter</a> -->

      </div>





    </div>

    <!-- <div class="row row1" style="margin-top: 20px;display: flex; justify-content: flex-start;">
      <h2 style="text-align: left;">Social Pages</h2>
    </div> -->
    <!-- <div class="row row1">
      <div class="col-md-6" style="text-align: left;">
        <a href="{{fburl}}" title="twitter logo icons" class="amob" target="_blank">click
          to follow on
          Facebook</a>
      </div>
      <div class="col-md-6" style="text-align: left;">
        <a class="amob" href="{{twturl}}" title="twitter logo icons" target="_blank">click
          to follow on
          Twitter</a>

      </div>



    </div> -->

    <!-- </div> -->
  </div>
  <!--  row for author notes-->





      <!-- </div> -->



   </div>
   <!-- <div class="row"> -->
    <!-- <div class="col-lg-9"> -->
      <!-- <div>-->
        <!-- <h2 style="text-align: left; padding: 300px;">Other Articles </h2> -->



    <div class="row1 " style="display: flex; justify-content: flex-start; margin-top: -25px;">
          <h2 style="text-align: left;">கட்டுரைகள்</h2>
        </div>


        <div class="row" style="height: 457px; ">
          <div class="col-lg-1"></div>

          <div class="col-lg-9">
            <div>

              <div class="reports">
                  <div class="row justify-content-space-between align-items-center card-space" *ngIf="authorNotes.length; else nodata">
<!--*ngFor="let item of authorNotes"-->

                    <ng-container>
                          <div class="col-sm-12 col-12" style="margin-bottom: 10px;">
                            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                              <div ngxSlickItem  class="slide" *ngFor="let item of authorNotes">
                                <app-pub-card [report]="item" (readMore)="readMore($event)"></app-pub-card>
                              </div>
                          </ngx-slick-carousel>
                              <!-- <app-pub-card [report]="item" (readMore)="readMore($event)"></app-pub-card> -->

                            <!-- <div class="card"> -->
                              <!-- <img class="image" (click)="readMoreFunc(item?.Id)" [src]="mediaUrl+report?.PostImage" alt="Avatar"> -->
                              <!-- <div class="container"> -->
                                  <!-- <p class="date-time">
                                      பதிவு
                                      <span>
                                          {{report?.PostDateTime|date:'dd MMM yyyy, hh:mm:ss'}}
                                      </span>
                                      &nbsp;மணி
                                  </p> -->
                                  <!-- <p class="date-time">
                                      {{item?.PostDateTime|date:'dd MMM yyyy'}}
                                  </p> -->
                                  <!-- <h5 class="heading" (click)="readMoreFunc(report?.Id)">{{item?.PostName.slice(0,60)+'...'}}</h5> -->
                                  <!-- <p class="content" style="height: 6.5rem;overflow: hidden;">{{report?.PostTitle}}</p> -->
                                  <!-- <p class="read-more" (click)="readMoreFunc(report?.Id)"><a>{{'read-more'|translate}}</a></p> -->
                              <!-- </div> -->
                          </div>
                            <!-- <div class="card">

                                  <img class="image" [src]="mediaUrl+item.PostImage" alt="Avatar1" (click)="readMore(item?.Id)">

                                  <div class="container">

                                      <p class="date-time">
                                         {{item.PostDateTime|date:'dd MMM yyyy'}}
                                      </p>
                                      <h5 class="heading" (click)="readMore(item?.Id)">{{item?.PostName.slice(0,60)+'...'}}</h5>
                                      </div>
                              </div> -->

                          <!-- </div> -->
                      </ng-container>
                  </div>
                  <ng-template #nodata>
                      <div class="card p-4 mt-4 mx-auto" style="text-align: center;width: 98%;">
                          <div class="no-reports">
                              {{'reports-not-found'|translate}}
                          </div>
                      </div>
                  </ng-template>
              </div>
          </div>
          </div>
          </div>


          <!-- author social accounts-->

          <div class="row1 col-md-10" style="display: flex; justify-content: flex-start;">
            <h2 style="text-align: left;">{{'follow-on-social-media'|translate}}</h2>
          </div>


          <div class="row  row-dupe" style="margin-top: -35px">
            <div class="col-lg-1"></div>

            <div class="col-lg-9 col-md-6" >
              <div>

                <div class="reports1" >
                    <!-- <p class="share-social-title"></p> -->
                    <!-- <share-buttons [include]="['facebook','twitter']" [url]="customUrl">
                    </share-buttons> -->

                    <ul>
                      <li class="nav-item">
                          <!-- <a href="https://twitter.com/dmk_youthwing" class="nav-link" target="_blank"><i class="fab fa-twitter"></i></a> -->
                          <a [href]="twturl" class="nav-link" target="_blank"><img
                                  src="../../../../../assets/images/twitterIcon.png" alt="twitterlink"></a>
                      </li>

                      <!-- <li class="nav-item">
                          <a [href]="fburl" class="nav-link" target="_blank"><img
                                  src="../../../../../assets/images/facebookIcon.png" alt="facebook"></a>
                      </li> -->


                  </ul>
                  </div>
                  </div>







<!-- author article old list-->


        <!-- <div class="row">
          <div class="row article-row"  *ngIf="authorNotes.length; else nodata">
              <ng-container>
                  <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 mt-4" *ngFor="let item of authorNotes">



                       <div class="card">

                           <img class="image" [src]="mediaurl+item.PostImage" alt="Avatar1" (click)="readMore(item?.Id)">

                          <div class="container">
                               <p class="date-time">

              <span>
                  {{report?.PostDateTime|date:'dd MMM yyyy, hh:mm:ss'}}
              </span>
              &nbsp;&nbsp; &nbsp;மணி
           </p>
                              <p class="date-time">
                                  {{item.PostDateTime|date:'dd MMM yyyy'}}
                              </p>
                              <h5 class="heading" (click)="readMore(item?.Id)">{{item?.CatDistrict.slice(0,60)+'...'}}</h5>
                                    </div>
                      </div>

                  </div>
              </ng-container>
          </div>
          <ng-template #nodata>
              <div class="card p-4 mt-4 mx-auto" style="text-align: center;width: 98%;">
                  <div class="no-reports">
                      {{'reports-not-found'|translate}}
                  </div>
              </div>
          </ng-template>
          </div> -->




    <!--  row for author notes-->











<!-- <div class="container-fluid">
  <div class="row text-center mt-4">
    <div class="col">
      <h1>Author Details</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3"></div>

    <div class="col-md-6 text-center">
      <img [src]=" img" alt="author's profile picture" class="mx-auto img-fluid" style="max-width: 100%; height: auto;">
      <h2>{{name}}</h2>
      <h2>{{title}}</h2>
      <p>வடகிழக்குப் பருவமழை பாதிப்பை நேரில் ஆய்வு செய்வதிலும், மக்களை சந்தித்துக் குறைகளைக் கேட்பதிலும்,அதிகாரிகளுக்கு
        உடனுக்குடன் ஆலோசனை கூறுவதிலும், பாதிக்கப்பட்டவர்களுக்குத் தேவையான நிவாரண உதவிகளை வழங்குவதிலும் மாண்புமிகு
        முதலமைச்சர் அவர்கள், முத்தமிழறிஞர் கலைஞர் அவர்களின் வழியில் செயல்பட்டு வருவதைக் கண்டு நாடே பாராட்டுகிறது.
        மாண்புமிகு முதலமைச்சர் அவர்களின் வழியில் அமைச்சர் பெருமக்களும் மாவட்ட கழகச் செயலாளர்கள் உள்ளிட்ட கழக
        நிர்வாகிகளும், இளைஞர் அணி தம்பிமார்களும் களப்பணியாற்றி வருவதை அறிவேன். 2015-ம் ஆண்டைவிட அதிக மழை பெய்தும் பெரிய
        அளவில் பாதிப்புகள் இல்லாததற்கு உங்களின் இந்தக் களப்பணியும் ஒரு முக்கியமான காரணம். அந்த வகையில் நான் எனது
        சேப்பாக்கம்-திருவல்லிக்கேணி தொகுதியிலும் சென்னையைச் சுற்றியுள்ள பகுதிகளிலும் கனமழையால் பாதிக்கப்பட்ட மக்களை
        நேரில்
        சந்தித்து உதவி வருகிறேன்.</p>
      <ul>
        <li class="nav-item">
          <a href="https://www.flaticon.com/free-icons/twitter-logo" title="twitter logo icons">click to follow on
            Twitter</a>
        </li>
      </ul>
    </div>

    <div class="col-md-3"></div>
  </div>
</div> -->

<!-- </div> -->

<!-- <a href="https://twitter.com/dmk_youthwing" class="nav-link" target="_blank"><i class="fab fa-twitter"></i></a> -->
<!-- <a href="https://twitter.com/dmk_youthwing" class="nav-link" target="_blank"><img
              src="../../../../assets/images/icons8-twitter-50.png" alt="twitterlink"></a> -->
<!-- <div class="row">



  <div class="col-md-10">
    <h5>Author Details</h5>

    <div class="card">
      <img [src]="img" alt="author's profile picture">
      <div class="card-content">
        <h2>{{name}}</h2>
        <p>{{bio}}</p>
      </div>
    </div>
  </div>
</div> -->




<!-- <div class="col-md-6">
    <div class="card mt-4">
      <div class="card-header">
        <h3>User Profile</h3>
      </div>
      <div class="card-body">
        <form (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="firstName">First Name</label>
            <input type="text" class="form-control" id="firstName" name="firstName" required>
          </div>
          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input type="text" class="form-control" id="lastName" name="lastName" required>
          </div>
          <div class="form-group">
            <label for="email">Email Address</label>
            <input type="email" class="form-control" id="email" name="email" required>
          </div>
          <button type="submit" class="btn btn-primary">Save Changes</button>
        </form>
      </div>
    </div>
  </div> -->
