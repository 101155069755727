<div class="background-img"></div>
<div class="report-details">


    <!-- <div class=insidediv><div class=col-md-4></div><div class=col-md-6></div><div class=col-md-4></div></div> -->



    <div class="row contents">
        <div class="col-md-2">

        </div>

        <div class="col-md-8">
            <div class="report-wrapper">

                <div class="back-button d-flex">
                    <a class="view-btn btn btn-sm btn-danger rounded-pill my-4" (click)="onBack()">
                        {{'go-back'|translate}}
                    </a>
                </div>

                <div class="date-time">
                    <!-- <p>பதிவு {{reportData['PostDateTime']|date:'dd MMM
                        yyyy'}},{{reportData['PostDateTime']|date:'hh:mm:ss'}} மணி
                    </p> -->
                </div>

                <div class="heading">
                    <h2>{{reportData['PostTitle']}}</h2>
                    <!-- <h2>{{reportData['PostName']}}</h2> -->
                </div>
                <!-- <div class="sub-heading">
                    <h5>{{reportData['PostTitle']}}</h5>
                </div> -->

                <div class="videos row" *ngIf="videos.length>0">
                    <!-- <div class="video-title">காணொளி</div> -->
                    <div *ngFor="let video of videos">
                        <div class="video col-sm-12 col-md-6 col-lg-6 mt-4">
                            <iframe class="youtube-video" frameborder="0" allowfullscreen [src]="video"></iframe>
                        </div>
                    </div>

                </div>
                <div class="images slick-wrapper" *ngIf="images.length>0">

                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                        <!-- show only 10 images and videos -->
                        <div ngxSlickItem *ngFor="let image of images;let i=index" class="slide"
                            (click)="showPreview(i)">
                            <img class="image" [src]="mediaUrl+image.filefield" [alt]="image.filefield">
                        </div>
                    </ngx-slick-carousel>

                </div>


                <div class="content mt-4">
                    <div [innerHTML]="postContent"></div>
                </div>
                <div class="share-social mt-4">
                    <p class="share-social-title">{{'share-on-social-media'|translate}}</p>
                    <share-buttons [include]="['facebook','twitter','whatsapp','email','copy']">
                    </share-buttons>
                </div>

                <div class="comment-section">
                    <p class="comment-post-title">{{'post-comments'|translate}}</p>

                    <div class="comment-post">
                        <form>
                            <app-input [control]="f.Name" placeHolder="{{'full-name'|translate}}">
                            </app-input>
                            <app-input [control]="f.MobileNo" placeHolder="{{'mobile-number'|translate}}">
                            </app-input>

                            <textarea class="form-control" [formControl]="f.Description"
                                placeholder="{{'here-your-information'|translate}}..." rows="8" required></textarea>
                            <div style="padding-left: .3rem;color: red;padding-top: .3rem;"
                                *ngIf="f.Description.errors && f.Description.errors.required && f.Description.touched">
                                {{'information'|translate}} {{'required'|translate}}
                            </div>

                            <button class="btn btn-danger mt-4" style="background-color:rgb(197, 6, 6)"
                                (click)="onPostComment()">
                                {{'submit'|translate}}
                            </button>

                        </form>
                    </div>

                    <!-- <p class="comment-view-title">{{'comments'|translate}}</p> -->
                    <!-- <div #scrollMe [scrollTop]="scrollMe.scrollHeight" class="comment-view mt-4"> -->
                    <div class="comment-view mt-4">
                        <div *ngFor="let comment of comments;let i=index">
                            <div class="comment">
                                <span class="comment-date">{{comment.PostDate|date:'dd MMM yyyy'}}
                                    {{comment.PostDate|date:'shortTime'}}</span>
                                <p class="comment-name">{{comment.Name}}</p>

                                <!-- check the desc is more than 6 lines -->
                                <div *ngIf="comment.Description.length>600;else fullDesc">
                                    <!-- by default it shows collapsed content -->
                                    <div *ngIf="comment.isCollapsed">
                                        <p class="comment-desc">{{comment.Description.slice(0, 600)}}...</p>
                                        <!-- button for expand the desc -->
                                        <a *ngIf="comment.isCollapsed"
                                            (click)="comment.isCollapsed=false">{{'read-more'|translate}}</a>
                                    </div>
                                    <!-- expanded desc on read more button click -->
                                    <div *ngIf="!comment.isCollapsed">
                                        <p class="comment-desc">{{comment.Description}}</p>
                                        <!-- button for collapse the desc -->
                                        <a *ngIf="!comment.isCollapsed"
                                            (click)="comment.isCollapsed=true">{{'read-less'|translate}}</a>
                                    </div>

                                </div>
                                <ng-template #fullDesc>
                                    <p class="comment-desc">{{comment.Description}}</p>
                                </ng-template>

                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="comment-section">
                    <ul ngbNav #nav="ngbNav" class="nav-tabs">
                        <li ngbNavItem>
                            <a ngbNavLink>{{'comments'|translate}}</a>
                            <ng-template ngbNavContent>
                                <div #scrollMe [scrollTop]="scrollMe.scrollHeight" class="comment-view">
                                    <div *ngFor="let comment of comments">
                                        <div class="comment">
                                            <span class="comment-date">{{comment.PostDate|date:'dd MMM yyyy'}}</span>
                                            <p class="comment-name">{{comment.Name}}</p>
                                            <p class="comment-desc">{{comment.Description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink>{{'post-comments'|translate}}</a>
                            <ng-template ngbNavContent>
                                <div class="comment-post">
                                    <form>
                                        <app-input [control]="f.Name" placeHolder="{{'full-name'|translate}}">
                                        </app-input>
                                        <app-input [control]="f.MobileNo" placeHolder="{{'mobile-number'|translate}}">
                                        </app-input>

                                        <textarea class="form-control" [formControl]="f.Description"
                                            placeholder="{{'here-your-information'|translate}}..." rows="8"
                                            required></textarea>
                                        <div style="padding-left: .3rem;color: red;padding-top: .3rem;"
                                            *ngIf="f.Description.errors && f.Description.errors.required && f.Description.touched">
                                            {{'information'|translate}} {{'required'|translate}}
                                        </div>

                                        <button class="btn btn-danger mt-4" style="background-color:rgb(197, 6, 6)"
                                            (click)="onPostComment()">
                                            {{'submit'|translate}}
                                        </button>

                                    </form>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav"></div>

                </div> -->

            </div>
        </div>

        <div class="col-md-2"></div>
        <!-- <div class="col-md-3">
            <div class="heading other-reports">
                <h5>பிற அறிக்கைகள்</h5>
            </div>
            <app-pub-card [report]="nextReportData" (readMore)="readMore($event)"></app-pub-card>
        </div> -->
    </div>
</div>

<ng-image-fullscreen-view [images]="imagesFullScreen" [imageIndex]="selectedImageIndex" [show]="showFlag"
    (close)="closeEventHandler()" *ngIf="imagesFullScreen"></ng-image-fullscreen-view>
