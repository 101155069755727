import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
export interface SelectOptions {
  placeholder: string,
  data: any,
  searchable?: boolean,
  multiple?: boolean,
  clearable?: boolean,
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() control: FormControl;
  @Input() options: SelectOptions;

  @Output() valuechange = new EventEmitter;

  filtval:string;

  constructor() { }

  ngOnInit(): void {
    
  }
  
  onChange(event){
    this.filtval = this.control.value;
    this.valuechange.emit(this.filtval);
  }

}
