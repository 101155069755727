import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes, Scroll} from '@angular/router';
import { ApplicationFormComponent } from './components/application-form/application-form.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { BeneficiariesComponent } from './components/home/beneficiaries/beneficiaries.component';
import { HomeComponent } from './components/home/home.component';
import { PaymentComponent } from './components/home/payment/payment.component';
import { OrgChartComponent } from './components/home/youth-team/org-chart/org-chart.component';
import { YouthTeamComponent } from './components/home/youth-team/youth-team.component';
import { LibraryCommentDetailsComponent } from './components/library/library-comment-details/library-comment-details.component';
import { LibraryComponent } from './components/library/library.component';
import { LibrarybookdetailComponent } from './components/library/librarybookdetail/librarybookdetail.component';
import { LibraryhashComponent } from './components/library/libraryhash/libraryhash.component';
import { OrgTreeComponent } from './components/org-tree/org-tree.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PhotosComponent } from './components/photos/photos.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { PubDetailsComponent } from './components/publications/pub/pub-details/pub-details.component';
import { PubListComponent } from './components/publications/pub/pub-list/pub-list.component';
import { PubPageComponent } from './components/publications/pub/pub-page/pub-page.component';
import { YoutubeVideosComponent } from './components/youtube-videos/youtube-videos.component';
import { LoginComponent } from './login/login.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { SecretaryInServiceComponent } from './components/secretary-in-service/secretary-in-service.component';
import { HashtagsArticleSecretaryInServiceComponent } from './components/hashtags-article-secretary-in-service/hashtags-article-secretary-in-service.component';

import { PubNewComponent } from './components/publications/pub/pub-new/pub-new.component';
import { AuthorPageComponent } from './components/publications/pub/author-page/author-page.component';
import { MetaGuard } from '@ngx-meta/core';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [MetaGuard],
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'application',
        component: ApplicationFormComponent
      },
      {
        path: 'contact',
        component: ContactFormComponent
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
      },
      {
        path: 'publications',
        component: PubPageComponent,
        children: [
          {
            path: '',
            redirectTo: 'report',
            pathMatch: 'full',
          },
          {
            path: 'report',
            component: PubListComponent,
            data: {
              category: 'report',
            }
          },

          // {
          //   path: 'secretary-in-service',
          //   component: PubListComponent,
          //   data: {
          //     category: 'secretaryInPublicService'
          //   }
          // },
          {
            path: 'press-release',
            component: PubListComponent,
            data: {
              category: 'pressRelease'
            }
          },

          {
            path: 'photo',
            component: PubListComponent,
            data: {
              category: 'Photo'
            }
          },
          {
            path: 'video',
            component: PubListComponent,
            data: {
              category: 'video'
            }
          },
          {
            path: 'program',
            component: PubListComponent,
            data: {
              category: 'program'
            }
          },
          {
            path: 'announcement',
            component: PubListComponent,
            data: {
              category: 'announcement'
            }
          },
          {
            path: 'articles',
            component: PubListComponent,
            data: {
              category: 'Articles'
            }
          },
          {
            path: 'achievements',
            component: PubListComponent,
            data: {
              category: 'AchievmentsOfTheSociety'
            }
          },
          {
            path: 'district',
            children: [
              {
                path: '',
                component: PubListComponent,
                data: {
                  category: 'DistrictPost'
                },
              },
              {
                path: 'org-tree',
                component: OrgTreeComponent,
                pathMatch: 'full'
              }
            ]
          },
        ]
      },


      // {
      //   path: 'secretary-in-service',
      //   component: PubListComponent,
      //   data: {
      //     category: 'secretaryInPublicService'
      //   }
      // },
      {
        path: 'publication-details',
        component: PubDetailsComponent
      },
      {
        path: 'functionaries',
        component: YouthTeamComponent,
      },
      {
        path: 'org-tree',
        component: OrgTreeComponent,
      },
      {
        path: 'foundation',
        children: [
          {
            path: 'donate',
            component: PaymentComponent
          },
          {
            path: 'beneficiaries',
            component: BeneficiariesComponent
          },
        ]
      },
      {
        path: 'library',
        children: [
          {
            path: 'books',
            component: LibraryComponent
          },
          {
            path: 'bookcommentary',
            component: LibraryhashComponent
          },
          {
            path: 'librarybook',
            component: LibrarybookdetailComponent
          },
          {
            path: 'librarycommentdetails',
            component: LibraryCommentDetailsComponent
          }
        ]
      },


      {
        path: 'photos',
        component: PhotosComponent
      },
      {
        path: 'videos',
        component: YoutubeVideosComponent
      },
    ],
  },
  {
    path: 'secretary-in-public-service',
    component: SecretaryInServiceComponent
  },

  {
      path: 'author-page',
      component: AuthorPageComponent
  },
  {
    path: 'pubnew',
    component: PubNewComponent,
    data: {
      title: 'Murasoli Pasarai-DMK',
      description:'DMk youthwing - Murasoli Pasarai',
      ogUrl: 'https://youthwingdmk.in/secretary-in-public-service'
    }
  },
  // app-hashtags-article-secretary-in-service

  {
    path: 'hashtag-article',
    component: HashtagsArticleSecretaryInServiceComponent,

  },
  {
    path: 'applaunched',
    component: LoginPageComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    // useHash:true
},)],
  exports: [RouterModule],
  declarations: [
  ],

  // providers: [
  //   { provide: RouteReuseStrategy, useClass: CustomRouteReuseStategy },
  // ]
})
export class AppRoutingModule { }
