<div class="bg">
</div>
<div *ngIf="!orgChart" class="youth-team">
    <div class="row">
        <div class="col-lg-8">
            <h1 *ngIf="control.value=='volunteers';else others" class="heading">{{'volunteers'|translate}}</h1>
            <ng-template #others>
                <h1 class="heading">{{'youth-wing-functionaries'|translate}}</h1>
            </ng-template>
        </div>
        <div class="col-lg-4 col-sm-6" *ngIf="!isLoggedInStateMemberClicked">
            <div class="row">
                <div class="col-sm-8">
                    <app-select [options]="selection" [control]="control"></app-select>
                </div>
                <!-- <div class="col-sm-4">
                    <div *ngIf="control.value=='district'">
                        <button *ngIf="!loggedIn" class="btn btn-primary mt-3"
                            (click)="login(content)">{{'enter'|translate}}</button>
                        <button *ngIf="loggedIn" class="btn btn-danger mt-3"
                            (click)="logout(content)">{{'exit'|translate}}</button>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <!-- state members -->
    <div class="row team-members state" *ngIf="control.value=='state'&& !isLoggedInStateMemberClicked">
        <div *ngFor="let member of stateMemberData" class="col-lg-3 col-md-4 col-sm-6 col-xs-12 team-member">
            <div class="member-img">
                <img [src]="mediaUrl+member.images" alt="member-img">
            </div>
            <p *ngIf="!loggedIn" class="member-name">{{member.Name}}</p>

            <p class="member-name" *ngIf="loggedIn" (click)="onLoggedInStateMember(member.Id)"
                style="cursor: pointer;color: blue;">{{member.Name}}</p>

            <p class="member-designation">{{member.Designation}}</p>
            <p class="member-address">{{member.Address}}</p>
        </div>
    </div>

    <!-- logged-in districts in districts  -->
    <div class="row mx-0 team-members" *ngIf="control.value=='district'&&loggedIn">
        <ol class="row mx-0">
            <!-- https://stackoverflow.com/questions/55943639/ngfor-how-to-override-value-in-a-template-loop-if-found -->
            <div *ngFor="let member of distWithLoggedInMemberData" class="mt-4 col-sm-12 col-md-6 col-lg-4">


                <li *ngIf="member.Logged else notLoggedInDist" target="_blank" class="text-left"
                    (click)="onLoggedDistrictClick(member.DistId)">
                    <a class="district-click">{{member.KalagaDistrict}}</a>
                </li>

                <ng-template #notLoggedInDist>
                    <li>{{member.KalagaDistrict}}</li>
                </ng-template>


            </div>

        </ol>
    </div>

    <!-- districts -->
    <div class="row mx-0 team-members" *ngIf="control.value=='district'&&!loggedIn">
        <ol class="row mx-0">
            <div *ngFor="let member of districtMemberData" class="mt-4 col-sm-12 col-md-6 col-lg-4">
                <!-- <div class="member-img">
                    <img [src]="member.Photo" alt="member-img">
                </div>
                <p class="member-name">{{member.Name}}</p>
                <p class="member-designation">{{member.Post}}</p>
                <p class="member-address">{{member.KalagaDistrict}}</p> -->
                <li class="text-left" (click)="onDistrictClick(member.DistId)">
                    <span class="dist-name">{{member.KalagaDistrict}}</span>
                </li>
            </div>
        </ol>
    </div>

    <!-- volunteers -->
    <div class="row mx-0 team-members" *ngIf="control.value=='volunteers'&&loggedIn">
        <div class="card p-4 mt-4 mx-auto" style="text-align: center;width: 98%;">
            <div class="no-reports">
                {{'reports-not-found'|translate}}
            </div>
        </div>
    </div>

    <!-- state members profile -->
    <div class="state-member-profile" *ngIf="isLoggedInStateMemberClicked">
        <button class="btn btn-danger rounded-pill btn-sm back-btn"
            (click)="onBackToStateMember()">{{'go-back'|translate}}</button>
        <div class="profile-wrapper">
            <div class="profile">
                <img class="profile-picture" [src]="loggedInSateMemberData.images" alt="member-img">

                <table>
                    <tr>
                        <th colspan="2" style="font-size: 1.5rem;">Personal Details</th>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>{{loggedInSateMemberData.Name}}</td>
                    </tr>
                    <tr>
                        <td>Designation</td>
                        <td>{{loggedInSateMemberData.Designation}}</td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td>{{loggedInSateMemberData.Address}}</td>
                    </tr>
                    <tr>
                        <td>District</td>
                        <td>{{loggedInSateMemberData.District}}</td>
                    </tr>
                    <tr>
                        <td>State</td>
                        <td>{{loggedInSateMemberData.state}} </td>
                    </tr>
                    <tr>
                        <td>PIN</td>
                        <td>{{loggedInSateMemberData.pincode}} </td>
                    </tr>
                    <tr>
                        <td>Zone</td>
                        <td>{{loggedInSateMemberData.Zone}} </td>
                    </tr>
                    <tr>
                        <td>Mobile</td>
                        <td>{{loggedInSateMemberData.MobileNo}} </td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{{loggedInSateMemberData.Email}} </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

</div>
<!-- log-in model -->
<!-- <ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{'enter'|translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-input [control]="f.username" placeHolder="{{'username'|translate}}" (keyup.enter)="onEnter()"></app-input>
        <app-input [control]="f.password" placeHolder="{{'password'|translate}}" type='password'
            (keyup.enter)="onEnter()"></app-input>
        <div class="d-flex justify-content-center">
            <button type="button" class="btn btn-primary" (click)="loginSubmit()">{{'submit'|translate}}</button>
        </div>
    </div>
</ng-template> -->



<!-- org chart -->
<!-- <div *ngIf="orgChart">
    <app-org-chart [chartData]="chartData" (back)="backToDist($event)" [loggedIn]="loggedIn"></app-org-chart>
</div> -->