import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ActivatedRoute,
  Data,
  NavigationExtras,
  Router,
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/http.service';
import {
  alphabetRegex,
  host,
  mediaUrl,
  mobileNumberRegex,
  Report,
} from 'src/app/services/interface';
import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
// import {  Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-pub-details',
  templateUrl: './pub-details.component.html',
  styleUrls: ['./pub-details.component.scss'],
})
export class PubDetailsComponent implements OnInit {
  mediaUrl = mediaUrl;
  reportData = [];
  // reportData:  Report | null = null;
  postContent: any;
  reportId: any;
  nextReportId: any;
  nextReportData: any;
  videos = [];
  images = [];
  comments = [];

  // full screen
  // full screen view
  imagesFullScreen = [];
  selectedImageIndex: any;
  showFlag: boolean;

  slideConfig = {
    slidesToShow: 3,
    dots: true,
    slidesToScroll: 1,
    infinite: false,
    draggable: true,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  title: string;
  imgg: string;
  desc: string; // above 3 for metatag
  commentForm: FormGroup;
  document: any;

  constructor(
    private service: HttpService,
    private router: Router,
    private _location: Location,
    private activeRoute: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,

    private titleService: Title,
    private metaService: Meta
  ) {


    ///////////////////////////////////////////////////////////////////////////////////////////
    this.commentForm = this.formBuilder.group({
      Name: ['', [Validators.required, Validators.pattern(alphabetRegex)]],
      MobileNo: ['', [Validators.pattern(mobileNumberRegex)]],
      Description: ['', Validators.required],
      PostForeignId: [''],
      other: [''],
    });
  }

  ngOnInit(): void {
    console.log('innn sam');

    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {

    //     this.service.getSpecificRepo('170').subscribe((res: any) => {
    //       this.reportData = res.Data[0].Data;
    //       const title = this.reportData['postTitle'];
    //       const cont = this.reportData['PostContent'];
    //       console.log(this.reportData, "84");

    //       // this.document.querySelector('title').setAttribute("content", "dmk blog content previewww");
    //       // this.document.querySelector('meta[name="description"]').setAttribute("content", cont);

    //   }
    // });

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    this.activeRoute.queryParams.subscribe((params) => {
      this.reportId = params.Id;
      // console.log(params.Id, "123");
    });
    this.service.getSpecificReport(this.reportId).subscribe((res: any) => {
      console.log(res, 'hieeeeeeeeeeeeee');
      this.reportData = res.Data[0].Data;
      var desc = this.reportData['PostTitle'];
      var img = this.reportData['PostImage'];
      console.log(desc, 'desccccccccc');
      console.log(img, 'imggggggggggggggggggg');
    });
    this.getSpecificReport(this.reportId);
    this.getComments(this.reportId);

    // this.meta.updateTag({ property: 'og:image', content: 'URL of your blog post image' });
  }

  getSpecificReport(id) {
    console.log(id, '116');
    this.service.getSpecificReport(id).subscribe(
      (res: any) => {
        this.reportData = res.Data[0].Data;

        // image with and height from ckeditor not working, so i sanitize the data
        //https://stackoverflow.com/questions/54703250/angular-image-size-problem-in-ckeditor-content
        this.postContent = this._sanitizer.bypassSecurityTrustHtml(
          this.reportData['PostContent']
        );

        this.nextReportId = res.Data[0].NextId;

        this.images = res.Data[0].pdf;

        console.log(this.images, 'ogimage watsssss');
        let temp = res.Data[0].Data.VideURL;
        if (temp) {
          temp.forEach((ele: string) => {
            let url = 'https://www.youtube.com/embed/' + ele.slice(32);
            // console.log(url);
            let modified_url =
              this._sanitizer.bypassSecurityTrustResourceUrl(url);
            this.videos.push(modified_url);
          });
        }

        this.fullScreenView();
        this.getNextReport();
      },
      (err) => {},
      () => {
        // console.log(this.meta.getTags('content'));
      }
    );
  }

  getNextReport() {
    this.service.getAllReports().subscribe((res) => {
      this.nextReportData = res['Data'].filter(
        (ele) => ele.Id == this.nextReportId
      )[0];
    });
  }

  onBack() {
    this._location.back();
  }

  readMore(event) {
    this.getSpecificReport(event);
  }

  // full screen methods
  fullScreenView() {
    this.images.forEach((ele) => {
      this.imagesFullScreen.push({ image: mediaUrl + ele.filefield });
    });
    // console.log('this is each', this.imagesFullScreen);
  }

  showPreview(imgIndex: number) {
    this.selectedImageIndex = imgIndex;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.selectedImageIndex = -1;
  }
  get f() {
    return this.commentForm.controls;
  }

  onPostComment() {
    this.f.PostForeignId.setValue(this.reportId);
    this.f.other.setValue(window.location.href);

    if (this.commentForm.valid) {
      // console.log('form value ', this.commentForm.value);
      this.service
        .postComment(this.commentForm.value)
        .subscribe((res: Data) => {
          // console.log('res', res);
          this.toastr.success('உங்கள் விண்ணப்பம் சமர்ப்பிக்கப்பட்டது.');
          this.commentForm.reset();
        });
    } else {
      this.commentForm.markAllAsTouched();
    }
    // console.log('form values', this.commentForm.value);
  }
  getComments(id) {
    this.service.getComments(id).subscribe((res: Data) => {
      res.data.forEach((data: Data) => {
        // add isCollapsed property to collapse and expand the  desc content by default collapsed
        data['isCollapsed'] = true;
        this.comments.push(data);
      });
    });
    // console.log('comments', this.comments);
  }
  // ReadMore(index) {
  //   // console.log('index', index);

  // }
}
