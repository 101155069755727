<!-- set navbar bg -->
<!-- <div class="bg"></div> -->
<!-- activate bootstrap grid when user click on dist member -->
<div class="org-chart">
    <button class="btn btn-danger rounded-pill btn-sm org-back-btn" (click)="onBack()">{{'go-back'|translate}}</button>

    <div class="body genealogy-body genealogy-scroll">
        <div class="genealogy-tree">
            <ul>
                <li>
                    <a href="javascript:void(0);">
                        <div class="member-view-box">
                            <div class="member-details">
                                <h3 class="heading">{{chartData.DistName}}</h3>
                            </div>
                        </div>
                    </a>
                    <ul class="active">

                        <li>
                            <a href="javascript:void(0);" (click)="onPublications()">
                                <a href="javascript:void(0);">
                                    <div class="member-view-box">
                                        <div class="member-details">
                                            <h3 class="sub-heading">மாவட்ட வெளியீடுகள்</h3>
                                        </div>
                                    </div>
                                </a>
                            </a>
                        </li>

                        <li>
                            <a href="javascript:void(0);">
                                <div class="member-view-box">
                                    <div class="member-details">
                                        <h3 class="sub-heading">மாவட்ட அமைப்பாளர்</h3>
                                    </div>
                                </div>
                            </a>

                            <ul>
                                <li>
                                    <div *ngFor="let head of chartData.DistHead;let i=index">
                                        <div *ngIf="head.type=='mavattam'">
                                            <a href="javascript:void(0);" (click)="onMember(head.Id,i)">
                                                <div class="member-view-box">
                                                    <div class="member-details">
                                                        <!-- highlight selected member -->
                                                        <!-- https://stackoverflow.com/questions/52361513/how-highlight-a-div-on-click-in-angular -->
                                                        <!-- <h3 [ngClass]="{'highlight':selectedId==i}" class="member">
                                                            {{head.Name}}</h3> -->
                                                        <h3 class="member"> {{head.Name}}</h3>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        </li>

                        <li>
                            <a href="javascript:void(0);">
                                <div class="member-view-box">
                                    <div class="member-details">
                                        <h3 class="sub-heading">மாவட்ட துணை அமைப்பாளர்</h3>
                                    </div>
                                </div>
                            </a>

                            <ul>
                                <li>
                                    <div *ngFor="let head of chartData.DistHead; let i=index">
                                        <div *ngIf="head.type=='mavattamthunai'">
                                            <a href="javascript:void(0);" (click)="onMember(head.Id,i)">
                                                <div class="member-view-box">
                                                    <div class="member-details">
                                                        <h3 class="member"> {{head.Name}} </h3>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>


                        <li>
                            <a href="javascript:void(0);">
                                <div class="member-view-box">
                                    <div class="member-details">
                                        <h3 class="sub-heading">உட்பிரிவு</h3>
                                    </div>
                                </div>
                            </a>

                            <ul>
                                <div *ngFor="let Division of chartData.Division">
                                    <!-- <div *ngIf="Division.SubCat=='பகுதி'"></div> -->
                                    <li *ngIf="Division.Subcat=='பகுதி'">
                                        <a href="javascript:void(0);">
                                            <div class="member-view-box">
                                                <div class="member-details">
                                                    <h3 class="sub-heading">{{Division.Subcat}}</h3>
                                                </div>
                                            </div>
                                        </a>
                                        <ul>
                                            <li *ngFor="let subarea of Division.subarea">
                                                <a href="javascript:void(0);">
                                                    <div class="member-view-box">
                                                        <div class="member-details">
                                                            <h3 class="sub-heading">
                                                                {{subarea.SubUnderArea}}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </a>
                                                <ul>
                                                    <li>
                                                        <a href="javascript:void(0);">
                                                            <div class="member-view-box">
                                                                <div class="member-details">
                                                                    <h3 class="sub-heading">பகுதி துணை அமைப்பாளர்</h3>
                                                                </div>
                                                            </div>
                                                        </a>

                                                        <ul>
                                                            <li>
                                                                <div *ngFor="let heads of subarea.Heads; let i=index">
                                                                    <div *ngIf="heads.Post=='பகுதி துணை அமைப்பாளர்'">
                                                                        <a href="javascript:void(0);"
                                                                            (click)="onMember(heads.Id,i)">
                                                                            <div class="member-view-box">
                                                                                <div class="member-details">
                                                                                    <h3 class="member" class="member">
                                                                                        {{heads.Name}}
                                                                                    </h3>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li>
                                                        <a href="javascript:void(0);">
                                                            <div class="member-view-box">
                                                                <div class="member-details">
                                                                    <h3 class="sub-heading">பகுதி அமைப்பாளர்</h3>
                                                                </div>
                                                            </div>
                                                        </a>

                                                        <ul>
                                                            <li>
                                                                <div *ngFor="let heads of subarea.Heads; let i=index">
                                                                    <div *ngIf="heads.Post=='பகுதி அமைப்பாளர்'">
                                                                        <a href="javascript:void(0);"
                                                                            (click)="onMember(heads.Id,i)">
                                                                            <div class="member-view-box">
                                                                                <div class="member-details">
                                                                                    <h3 class="member" class="member">
                                                                                        {{heads.Name}}
                                                                                    </h3>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </div>

                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf="distMemberData.name&&isPopupOpen">
        <div class="profile vl py-4 model-popup card">
            <div class="close-btn" (click)="onClosePopup()"> <span aria-hidden="true">&times;</span></div>
            <div class="profile-wrapper">

                <div *ngIf="distMemberData['hasPhoto']">
                    <img class="member-img mb-2" *ngIf="isImgLoading" src="../../../assets/images/loading-img.gif"
                        alt="loading">
                    <img [hidden]="isImgLoading" class="member-img mb-2" (load)="onImgLoad($event)"
                        [src]="distMemberData['photo']" alt="profile-img">
                </div>

                <img *ngIf="!distMemberData['hasPhoto']" class="member-img mb-2"
                    src="../../../assets/images/no-photo.jpeg" alt="no-img">

                <p class="member-name"><b>பெயர் : </b>{{distMemberData.name}}</p>
                <p class="member-address"> {{distMemberData.post}} - {{distMemberData.kalagaDistrict}}</p>
                <p class="member-address"><b>முகவரி : </b>{{distMemberData.address}}</p>
                <!-- <p class="show-more" (click)="onShowMore(distMemberData.Id)">{{'show-more'|translate}}</p> -->
            </div>
        </div>
    </div>


</div>

<!-- <div class="row mx-0 team-members" *ngIf="isDistShown">
    <ol class="row m-4">
        <div *ngFor="let member of districtMemberData" class="mt-4 col-sm-12 col-md-6 col-lg-4">
            <li class="text-left" style="cursor: pointer;"
                (click)="onDistrictClick(member.DistId,member.KalagaDistrict)">
                {{member.KalagaDistrict}}</li>
        </div>
    </ol>
</div> -->
