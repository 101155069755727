import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { host, mediaUrl } from 'src/app/services/interface';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-library-comment-details',
  templateUrl: './library-comment-details.component.html',
  styleUrls: ['./library-comment-details.component.scss']
})
export class LibraryCommentDetailsComponent implements OnInit {
  commentid: any;
  commentDetails: any;
  // host = host;
  mediaUrl=mediaUrl
  bookTitle: string;
  bookImg: string;
  authorName: string;
  bookDesc: any;



  constructor(
    private route: ActivatedRoute,
    private service: HttpService,
    private _location: Location,
    private _sanitizer: DomSanitizer

  ) { }

  ngOnInit(): void {

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    this.route.queryParams.subscribe(res => {
      this.commentid = res.commentid;
      // console.log(this.commentid);
      // check the user came from youth team page
      // this.commentdetails(this.commentid);
    });

    this.service.libraryCommentDetails(this.commentid).subscribe((res: any) => {
      // console.log('lib comments', res.data);
      // this.commentDetails = res.data;
      this.bookTitle = res.data[0].TitleLib;
      this.bookImg = res.data[0].BookLibImg;
      this.authorName = res.data[0].AuthorNameLib;
      // this.bookDesc = res.data[0].DescLip;
      this.bookDesc = this._sanitizer.bypassSecurityTrustHtml(res.data[0].DescLip);


    });

  }

  onBack() {
    this._location.back();
  }

}
