<div class="form-group">
    <input type="text" class="form-control mt-2" [formControl]="control" [type]="type" [placeholder]="placeHolder">
    <ng-container *ngIf="control.errors && control.touched">
        <div class="error" *ngIf="control.errors.required ">
            {{placeHolder}} {{'required'|translate}}
        </div>
        <div class="error" *ngIf="control.errors.email ">
            {{placeHolder}} {{'not-valid'|translate}}
        </div>
        <div class="error" *ngIf="control.errors.pattern ">
            {{placeHolder}} {{'not-valid'|translate}}
        </div>
    </ng-container>

</div>