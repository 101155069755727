import { Component, ElementRef, OnInit, ViewChild, ViewChildren,QueryList,AfterViewInit} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { SelectOptions } from 'src/app/components/select/select.component';
import { HttpService } from 'src/app/services/http.service';
import { ApiResponse, Report } from 'src/app/services/interface';
import { SubSink } from 'subsink';
import { Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-pub-list',
  templateUrl: './pub-list.component.html',
  styleUrls: ['./pub-list.component.scss']
})
export class PubListComponent implements OnInit,AfterViewInit {

  category: string;
  readMoreLink: string;
  reports: Report[];
  startDate: string;
  endDate: string;
  distId: string = 'abc';
  toggleFilter: boolean = false;
  subs = new SubSink();
   element;

  @ViewChild('card') cardElement: ElementRef;
  // @ViewChildren(PubCardComponent) pubCards: QueryList<PubCardComponent>;



  district: SelectOptions = {
    placeholder: 'district',
    clearable: true,
    // multiple: true,
    data: []
  };

  isDistrict: boolean = true;
  districtMemberData: any;
  DistrictName: string;
  isBackBtnHidden: boolean = false;

  searchControl = new FormControl('CHNH');

  constructor(
    private route: ActivatedRoute,
    private service: HttpService,
    private elementRef: ElementRef<HTMLElement>,
    private spinner: NgxSpinnerService,
    private router: Router,
    private elem: ElementRef,
    private meta: Meta
  ) { }

  ngAfterViewInit() {
    // console.log('element', document.getElementById('Software Developer'));
    var itemid =  localStorage.getItem("itemCard1");

    var distid =  localStorage.getItem("distID");
    // console.log(distid,"element102");
   const elementID2 = document.getElementById(itemid);
  //  if(elementID2 != null){
  //   console.log(elementID2,"elementID2");

  //  }
   var elementID;
   var distElementID;
   setTimeout(() => {
    if(itemid !== null){
       elementID = document.getElementById(itemid);
       console.log(elementID,"element1102");


    }
    if(distid !== null){

      distElementID = document.getElementById(distid);
      console.log(distElementID,"element1103");


    }

    //


     if(elementID !== null){
      elementID.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      localStorage.removeItem("itemCard1");
     }
     if(distElementID !== null){
      distElementID.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      localStorage.removeItem("distID");


     }


   },5000);
  }
  ngOnInit(): void {
    this.spinner.show();
    this.subs.sink = this.route
      .data
      .subscribe(data => {
        this.category = data.category;
        this.spinner.hide();
        // this.getAllReports();
      });

    // show districts on DistrictPost category
    if (this.category === 'DistrictPost') {
      this.service.getDistrictMembers().subscribe((res: any) => {
        this.districtMemberData = res['Data'].map((item) => {
          return {
            DistId: item.DistrictID,
            KalagaDistrict: item.TamilName
          };
        });
        // console.log('dist member', this.districtMemberData);
      }, (err) => { });
    } else {
      this.isDistrict = false;
    }

    // query data from org-tree
    this.route.queryParamMap.subscribe((res: any) => {
      // set search bar value based on id passed by org tree
      // console.log('res', res.params.distId);
      if (res.params.distId) {
        // this.searchControl.setValue(res.params.distId);
        this.isDistrict = false;
        this.isBackBtnHidden = true;
        this.distId = res.params.distId;
        this.DistrictName = res.params.distName;
        // console.log('dist name', res);
        this.service.getPublications(this.category, this.distId).subscribe((res: any) => {
          console.log(this.category, this.distId, "chkkkkkkkkkkkkkkkkkkkkkkkkkkk");
          this.reports = res.Data;
          // console.log('reports', res);

        });
      }
      else {
        // get dist reports by initial selected value
        this.service.getPublications(this.category, this.searchControl.value).subscribe((res: any) => {
          // this.searchControl.setValue('CHNH');
          this.reports = res.Data;
        });
      }
    });





    // this.searchControl.valueChanges.subscribe(res => {
    //   // console.log('seect value', res);

    //   this.distId = res;
    //   this.service.getPublications(this.category, this.distId).subscribe((res: any) => {
    //     this.reports = res.Data;
    //   });
    // });

    this.service.getDistrictMembers().subscribe((res: any) => {
      let arr = res.Data.map(element => {
        element['id'] = element.DistrictID;
        element['name'] = element.TamilName;
        return element;
      });
      this.district.data = [...arr];
    });

  }
  // ngAfterViewInit() {
  //   // console.log('element', document.getElementById('Software Developer'));

  //   var itemidw =  localStorage.getItem("itemCard1");
  //  console.log(itemidw,"element1003");
  //  this.getAllReports();
  //   this.element = document.getElementById(itemidw);

  //  console.log(this.element,"element1003");

  //  setTimeout(() => {
  //  this.element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  //  },500);
  // }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onFilterClick() {
    this.toggleFilter = !this.toggleFilter;
  }

  dateSubmit() {
    if (!this.startDate && !this.endDate) { alert('தேதி குறிப்பிடப்படவில்லை.'); }
    else if (!this.startDate) { alert('தொடக்க தேதி குறிப்பிடப்படவில்லை.'); }
    else if (!this.endDate) { alert('முடியும் தேதி குறிப்பிடப்படவில்லை.'); }
    else if (this.startDate > this.endDate) { alert('சரியான தேதியைத் தேர்ந்தெடுக்கவும்'); }
    else {
      this.onFilterClick();
      this.service.getReportsByDate(this.startDate, this.endDate, this.category, this.distId).subscribe((res: ApiResponse) => {
        this.reports = res.Data;
      });
    }
  }

  // filter report based on month/year
  prvMonth() {
    this.service.filterPrvMonth(this.category, this.distId).subscribe((res) => {
      this.reports = res['data'];
      this.onFilterClick();
    });
  }

  prv6Month() {
    this.service.filterPrvSixMonth(this.category, this.distId).subscribe((res) => {
      this.reports = res['data'];
      this.onFilterClick();
    });
  }

  prvYear() {
    this.service.filterPrvYear(this.category, this.distId).subscribe((res) => {
      this.reports = res['data'];
      this.onFilterClick();
    });
  }

  clear() {
    this.getAllReports();
    this.startDate = this.endDate = null;
    this.setPlaceholder();
    this.onFilterClick();
  }

  setPlaceholder() {
    document.querySelector('.from-date').setAttribute('type', 'text');
    document.querySelector('.to-date').setAttribute('type', 'text');
  }

  getAllReports() {
    this.service.getPublications(this.category, 'abc').subscribe((res: ApiResponse) => {
      this.reports = res.Data;
      // console.log('all reports', this.reports);

    });
  }
  readMore(event) {
    console.log(event,"eventpress");
    localStorage.setItem("itemCard1", event);

    let navigationExtras: NavigationExtras = {
      queryParams: { 'Id': event }
    };
    this.router.navigate(['/publication-details'], navigationExtras);
  }

  // on dist click to show tree
  onDistrictClick(dist) {
    console.log(dist,"distpress");
    localStorage.setItem("distID", dist.DistId);
    // console.log(dist.KalagaDistrict);
    // this.DistrictName = dist.KalagaDistrict;
    // this.service.getPublications(this.category, dist.DistId).subscribe((res: any) => {
    //   this.reports = res.Data;
    //   this.isDistrict = false;
    // });
    // this.router.navigate(['org-tree'], { queryParams: { 'distId': dist.DistId } });
  }

  onBackToDistList() {
    this.isDistrict = true;
  }
  // }


}
