import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
// import { ActivatedRoute, Data } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { alphabetRegex, mediaUrl, mobileNumberRegex } from 'src/app/services/interface';
import { TranslateService } from '@ngx-translate/core';
import { ApiResponse } from 'src/app/services/interface';
import { MetaService } from 'src/app/services/metatag';

import { response } from 'express';
import { Router, ActivatedRoute,Data, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NgNavigatorShareService } from 'ng-navigator-share';


const InlineShareButtonsConfig = {
  alignment: 'center', // alignment of buttons (left, center, right)
  color: 'social', // set the color of buttons (social, white)
  enabled: true, // show/hide buttons (true, false)
  font_size: 16, // font size for the buttons
  labels: 'cta', // button labels (cta, counts, null)
  language: 'en', // which language to use (see LANGUAGES)
  networks: [
    // which networks to include (see SHARING NETWORKS)
    'whatsapp',
    'linkedin',
    'messenger',
    'facebook',
    'twitter',
  ],
  padding: 12, // padding within buttons (INTEGER)
  radius: 4, // the corner radius on each button (INTEGER)
  show_total: true,
  size: 40, // the size of each button (INTEGER)

  // OPTIONAL PARAMETERS

  url: 'https://www.sharethis.com', // (defaults to current url)
  image: 'https://bit.ly/2CMhCMC', // (defaults to og:image or twitter:image)
  description: 'custom text', // (defaults to og:description or twitter:description)
  title: 'custom title', // (defaults to og:title or twitter:title)
  message: 'custom email text', // (only for email sharing)
  subject: 'custom email subject', // (only for email sharing)
  username: 'custom twitter handle', // (only for twitter sharing)
};


@Component({
  selector: 'app-pub-new',
  templateUrl: './pub-new.component.html',
  styleUrls: ['./pub-new.component.scss']
})
export class PubNewComponent implements OnInit {
  private ngNavigatorShareService: NgNavigatorShareService;
customUrl: any;

inlineShareButtonsConfig = InlineShareButtonsConfig;


  name: any;
  authorId: any;
  currentArticleValue: any = [];  //for hashtag list


  // constructor(
  //   private router: Router,
  //   private  service: HttpService,
  //   private activatedRoute: ActivatedRoute,
  //   private titleService: Title

  // )

  author(id: string) {
    console.log(id,"author id--");
    if(id==="" || id==="null"){
      console.log("no id");
    }

    else{
      this.router.navigate(['/author-page'], { queryParams: { 'Id': id } });

    }

    // this.router.navigate(['org-tree'], { queryParams: { 'distId': dist.DistId } });

  }
  mediaUrl = mediaUrl;
  facebookIframeSrc: string;

  reportId: any;
  reportData: [];
  hashTags:[];
  commentForm: FormGroup;
  postContent: any;
  nextReportId: any;
  images: any;
  videos: any;
  imagesFullScreen = [];
  articleData: any;
  title:string ;
  desc:string ;

  url:string ;


  /**
   *
   */


  nextReportData: any;
  // comments: [];
  comments: Data[] = []; // define the type of the comments array
  catValue: any;
  title1: any;
  description: any;


  constructor(
     public service: HttpService,
    private activeRoute: ActivatedRoute,
    private _location: Location,
    private _sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    // private metaService: Meta,
    private translate: TranslateService,
    private router: Router,
    private titleService: Title,

//

    // private  meta: MetaService

  ) {



    // console.log('Browser1:', window.navigator.userAgent);
    // console.log('Platform1:', window.navigator.platform);

    this.activeRoute.queryParams.subscribe((params) => {
      this.reportId = params.Id;
      // console.log(params.Id, "123");
    });
    this.service.getSpecificReport(this.reportId).subscribe((res: any) => {
      console.log(res, 'hieeeeeeeeeeeeee');
      this.reportData = res.Data[0].Data;
      this.title = this.reportData['PostTitle'];
      this.desc = this.reportData['PostName'];
     var  img = this.reportData['PostImage'];
      var id = res.Data[0].Data['Id'];
    this.url ="https://youthwingdmk.in/pubnew?Id="+id ;

    // this.shareContent(title ,desc , url);

  //   // this.updateOgTags1(title,desc,img);
  //   // this.metaService1.updateTags(title,desc,img);
  //   // this.metaService1.updateTags("முரசொலி-பாசறை","முரசொலி-பாசறை-தி.மு.க-இளைஞர்-அணி","https://youthwingdmk.in/assets/images/Youth%20wing%20logo2.png");

  // // const isChrome = window.navigator.userAgent.includes("Chrome");
  // // console.log(isChrome,"isChrome");


  //     console.log(desc, 'description-meta');
  //     console.log(img, 'image-meta');
  //     // this.metaService.updateTag({
  //     //   name: 'description',
  //     //   content: desc,
  //     // });
  //     // this.metaService.updateTag({
  //     //   name: 'title',
  //     //   content: title,
  //     // });

  //     // this.metaService.updateTag({ property: 'og:title', content: title });
  //     // this.metaService.updateTag({ property: 'og:description', content: desc  });
  //     // this.metaService.updateTag({ property: 'og:type', content: "article"  });
  //     // this.metaService.updateTag({ property: 'canonical', content: url});

  //     // this.metaService.updateTag({ property: 'twitter:title', content: title  });
  //     // this.metaService.updateTag({ property: 'twitter:description', content: desc  });
  //     // this.metaService.updateTag({ property: 'twitter:image', content: mediaUrl+ img  });
  //     // this.metaService.updateTag({ property: 'og:image', content: mediaUrl + img  });
    });


    //////////////////////////////////////////////

    this.commentForm = this.formBuilder.group({
      'Name': ['', [Validators.required, Validators.pattern(alphabetRegex)]],
      'MobileNo': ['', [Validators.pattern(mobileNumberRegex)]],
      'Description': ['', Validators.required],
      'PostForeignId': [''],
      'other': ['']
    });

    // this.activeRoute.queryParams.subscribe(params => {
    //   console.log(params, "paramssssss");
    //   this.reportId = params.Id;
    //   console.log(params.Id, "123");
    // });

     this.service.getSpecificReport(this.reportId).subscribe((res: any) => {
      console.log(res, "hieeeeeeeeeeeeee");
      this.reportData = res.Data[0].Data;
      this.authorId = res.Data[0].Data['author_id'];
      console.log(this.authorId),"author id in pubnew";
      console.log(this.reportData['PostTitle'], "specific post data title==");
      console.log(mediaUrl + this.reportData['PostImage'], "mediaurl for post==");

      const title = this.reportData['PostTitle']; // Adjust this according to your API response structure
      const description = this.reportData['PostName']; // Adjust this according to your API response structure
      const image = mediaUrl + this.reportData['PostImage']; // Adjust this according to your API response structure

    //   // Update meta tags using MetaService

    //   // this.meta.removeTag('name=description');

    //   // this.meta.setTitle(` ${this.reportData['PostTitle']}`);
    //   // this.meta.setTag('og:image', mediaUrl + this.reportData['PostImage']);
    //   // this.meta.setTag('og:description', this.reportData['PostName']);


    //   // this.meta.updateTag({ name: 'description', content: this.reportData['PostTitle'] });
    //   // this.meta.updateTag({ property: 'og:title', content: this.reportData['PostTitle'] });
    //   // this.meta.updateTag({ property: 'og:description', content: this.reportData['PostName'] });
    //   // this.meta.updateTag({ property: 'og:image', content: mediaUrl + this.reportData['PostImage'] });

    //   //to get author name
      this.service.getAuthor(this.authorId).subscribe((res: any) => {
        // console.log(res);

        this.name = res.data[0]['author_name'];
        console.log(this.name, "an");
      }
      );

    });

  }



  ngOnInit(): void {

    this.updateFacebookIframeSrc();
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

//hashtag

this.service.getCategory().subscribe((res: any) => {
  console.log(res.data.current_series, "category response 62");
  this.catValue = res.category;
  this.currentArticleValue =res.data.current_series;
  // this.previousArticleValue = res.data.previous_series;
  console.log(res, "sec valuessssssssssssssssssssssss");
  // if (this.catValue.length) this.catValue.push({ Id: null, hashtagsval: 'ALLCAT', cat_icon: 'CategoryIcon/fb515405-10da-47bf-b498-2b904b1afa5d.png' });
  // this.selectedTag = this.catValue.length - 1;
  // this.selectedTag = this.catValue.length;
});
//////////////hashtag

    this.activeRoute.queryParams.subscribe(params => {
      console.log(params, "paramssssss");
      this.reportId = params.Id;
      console.log(params.Id, "123");
    });  //for meta checking

  //   this.activeRoute.data.subscribe(value => {
  //     // contains meta tag and title. update
  //     this.meta = value.meta;
  //     this.title = value.title;
  //     this.description = value.description;


  // //     this.meta.addTag({ name: 'description', content:
  // //     this.meta });
  // // this.meta.updateTag({ name: 'description', content:
  // //     this.meta });


  //   });
    // this.service.getSpecificReport(this.reportId).subscribe((res: any) => {
    //   console.log(res, "hieeeeeeeeeeeeee");
    //   this.reportData = res.Data[0].Data;
    //   this.authorId = res.Data[0].Data['author_id'];
    //   console.log(this.authorId),"author id in pubnew";
    //   console.log(this.reportData['PostTitle'], "specific post data title==");
    //   console.log(mediaUrl + this.reportData['PostImage'], "mediaurl for post==");

    //   const title = this.reportData['PostTitle']; // Adjust this according to your API response structure
    //   const description = this.reportData['PostName']; // Adjust this according to your API response structure
    //   const image = mediaUrl + this.reportData['PostImage']; // Adjust this according to your API response structure

    //   // Update meta tags using MetaService
    //   this.metaService.updateTags(title, description, image);

    //   // this.meta.removeTag('name=description');

    //   // this.meta.setTitle(` ${this.reportData['PostTitle']}`);
    //   // this.meta.setTag('og:image', mediaUrl + this.reportData['PostImage']);
    //   // this.meta.setTag('og:description', this.reportData['PostName']);


    //   // this.meta.updateTag({ name: 'description', content: this.reportData['PostTitle'] });
    //   // this.meta.updateTag({ property: 'og:title', content: this.reportData['PostTitle'] });
    //   // this.meta.updateTag({ property: 'og:description', content: this.reportData['PostName'] });
    //   // this.meta.updateTag({ property: 'og:image', content: mediaUrl + this.reportData['PostImage'] });

    //   //to get author name
    //   this.service.getAuthor(this.authorId).subscribe((res: any) => {
    //     // console.log(res);

    //     this.name = res.data[0]['author_name'];
    //     console.log(this.name, "an");
    //   }
    //   );

    // });


    this.service.getCategory().subscribe((res: any) => {
      // console.log(res.category, "category response 62");
      this.catValue = res.category;
      console.log(this.catValue, "sec valuessssssssssssssssssssssss");



    });

    // console.log(this.authorId, "aid");

    this.getSpecificReport(this.reportId);
    this.getComments(this.reportId);
   // this.meta.updateTag({ property: 'og:url', content: 'URL of your blog post' });
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe(() => {
    //   this.updateMetaTags();
    // });

  }


  onTagClickCurrentTag(index) {
    console.log(index, "index cat current 336");
    // this.selectedTagC = index;
    // this.AllCat = false;
    // this.isPrevious = false;
    // // console.log(this.AllCat, "allcat");
    // this.selectedTagB = null;
    // this.selectedTag = "";


  }

  onResize(event: Event): void {
    this.updateFacebookIframeSrc();
  }

  private updateFacebookIframeSrc(): void {
    const windowWidth = window.innerWidth;
    const desiredWidth = windowWidth < 768 ? 200 : 300;

    this.facebookIframeSrc = `https://www.facebook.com/plugins/likebox.php?href=http%3A%2F%2Fwww.facebook.com%2FDMKYWANBAGAM&width=${desiredWidth}&height=600&tabs=timeline&show_faces=false`;
  }
  getSpecificReport(id: string) {
    console.log(id, "116");
    this.service.getSpecificReport(id).subscribe((res: any) => {
      console.log(res.Data[0].Data,"responseeee===")
      this.reportData = res.Data[0].Data;
      this.reportData = res.Data[0].Data;
      this.customUrl = "https://api.youthwingdmk.in/livepost/share/"+id;
  //     var title = this.reportData['PostTitle'];
  //     var desc = this.reportData['PostName'];
  //     var img = this.reportData['PostImage'];
  // setTimeout(() => {
  //   this.updateOgTags1(this.reportData['PostTitle'],this.reportData['PostName'],this.reportData['PostImage']);
  // },2000);




     // image with and height from ckeditor not working, so i sanitize the data
      //https://stackoverflow.com/questions/54703250/angular-image-size-problem-in-ckeditor-content
      this.postContent = this._sanitizer.bypassSecurityTrustHtml(this.reportData['PostContent']);

      this.nextReportId = res.Data[0].NextId;

      this.images = res.Data[0].pdf;
      this.hashTags = res.Data[0].Data['hashtags'];
      console.log(this.hashTags,"hashtags---");

      console.log(this.images, "ogimage watsssss");
      let temp = res.Data[0].Data.VideURL;
      if (temp) {
        temp.forEach((ele: string) => {
          let url = "https://www.youtube.com/embed/" + ele.slice(32);
          // console.log(url);
          let modified_url = this._sanitizer.bypassSecurityTrustResourceUrl(url);
          this.videos.push(modified_url);
        });
      }

      this.fullScreenView();
      this.getNextReport();
    }, (err) => { }, () => {
      // console.log(this.meta.getTags('content'));

    });
  }

  get f() {
    return this.commentForm.controls;
  }

  getNextReport() {
    this.service.getArticle('articlerecentdate').subscribe((res: any) => {
      console.log(res.data, " articles");
      this.articleData = res.data;
    });
    this.service.getAllReports().subscribe((res) => {
      this.nextReportData = res['Data'].filter((ele) => ele.Id == this.nextReportId)[0];

    });
  }



  onBack() {
    // this._location.back();
    this.router.navigate(['/secretary-in-public-service']);


  };


  readMore(event) {
    this.getSpecificReport(event);
  }
  hashClick(hashtag){
console.log(hashtag,"onclick===");
this.router.navigate(['/hashtag-article'], { queryParams: { 'Id': hashtag } });

  }

  // full screen methods
  fullScreenView() {
    this.images.forEach(ele => {
      this.imagesFullScreen.push({ image: mediaUrl + ele.filefield });
    });
    // console.log('this is each', this.imagesFullScreen);

  }

  onPostComment() {

    this.f.PostForeignId.setValue(this.reportId);
    this.f.other.setValue(window.location.href);

    if (this.commentForm.valid) {
      // console.log('form value ', this.commentForm.value);
      this.service.postComment(this.commentForm.value).subscribe((res: Data) => {
        // console.log('res', res);
        this.toastr.success('உங்கள் விண்ணப்பம் சமர்ப்பிக்கப்பட்டது.');
        this.commentForm.reset();
      });
    } else {
      this.commentForm.markAllAsTouched();
    }
    // console.log('form values', this.commentForm.value);
  }
  getComments(id) {
    this.service.getComments(id).subscribe((res: Data) => {
      res.data.forEach((data: Data) => {
        // add isCollapsed property to collapse and expand the  desc content by default collapsed
        data['isCollapsed'] = true;
        this.comments.push(data);
      });
    });
    // console.log('comments', this.comments);
  }
  articleClick(id: string) {

    // this.service.getSpecificReport(id).subscribe((res: any)=>{
    //   console.log(res);})
    this.router.navigate(['/publication-details'], { queryParams: { 'Id': id } });



  }

  catClick(catId: string) {
    this.router.navigate(['/secretary-in-public-service'], { queryParams: { 'Id': catId } });

  }

  ngOnDestroy() {
    // this.meta.removeTag('property="og:type"');
 }
}



