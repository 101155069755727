<!-- <div class="login-page" *ngIf="loginPage">
    <div class="card login">
        <input class="form-control" [formControl]="f.Username" type="text" placeholder="User Name">
        <input class="form-control" [formControl]="f.Password" type="password" placeholder="Password">
        <button class="btn btn-primary" (click)="login()">
            login
        </button>
    </div>
</div>

<div *ngIf="launchPage">
    <div class="bg">
        <div class="launch-page">
            <button class="btn submit-btn" (click)="launch()">Launch</button>
        </div>
    </div>
</div> -->


<div>
    <div class="app-main">
        <app-nav-bar></app-nav-bar>
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-beat" [fullScreen]="true">
            <p style="color: white"> Loading... </p>
        </ngx-spinner>
        <router-outlet></router-outlet>
    </div>
    <app-footer class="page-footer"></app-footer>
</div>