import { AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { host, mediaUrl } from 'src/app/services/interface';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-slick-carousel',
  templateUrl: './slick-carousel.component.html',
  styleUrls: ['./slick-carousel.component.scss']
})

export class SlickCarouselComponent implements OnInit {
  // host = host;
  mediaUrl = mediaUrl;
  @Input() video = true;
  @Input() items = [];

  slideConfig = {
    "slidesToShow": 4,
    "dots": true,
    "slidesToScroll": 1,
    "infinite": false,
    'draggable': true,
    "arrows": false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  // full screen view
  images = [];
  imgItems = [];
  selectedImageIndex: any;
  showFlag: boolean;

  constructor(
    private service: HttpService,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.getVidAndImg();

  }

  getVidAndImg() {
    if (!this.video) {
      this.service.getImages().subscribe((res: any) => {
        this.items = res.Data;
        this.imgItems = res.Data;
        this.fullScreenView();
        // console.log('isdfdsfa', this.imgItems);

      });
    } else {
      this.service.getVideos().subscribe((res: any) => {
        this.items = res.Data.map(obj => {
          // sanitize the youTube url , so we don't need to change the actual youTube url 
          // https://stackoverflow.com/questions/42439060/how-to-embed-a-youtube-video-in-angular
          let youTube = this._sanitizer.bypassSecurityTrustResourceUrl(obj.video);
          return { ...obj, youTube };

        });
      });
    }
  }

  fullScreenView() {
    this.imgItems.forEach(ele => {
      // console.log('this is each', ele);

      this.images.push({ image: mediaUrl + ele.images });
    });
  }

  showPreview(imgIndex: number) {
    // console.log('img deee', this.images);

    this.selectedImageIndex = imgIndex;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.selectedImageIndex = -1;
  }


}
