<div class="card" #child2Ref>
    <img class="image"  #card [id]="report?.Id" (click)="readMoreFunc(report?.Id)" [src]="mediaUrl+report?.PostImage" alt="Avatar">
    <div class="container">
        <!-- <p class="date-time">
            பதிவு
            <span>
                {{report?.PostDateTime|date:'dd MMM yyyy, hh:mm:ss'}}
            </span>
            &nbsp;மணி
        </p> -->
        <p class="date-time">
            {{report?.PostDateTime|date:'dd MMM yyyy'}}
        </p>
        <h5 class="heading" (click)="readMoreFunc(report?.Id)">{{report?.PostName.slice(0,60)+'...'}}</h5>
        <!-- <p class="content" style="height: 6.5rem;overflow: hidden;">{{report?.PostTitle}}</p> -->
        <!-- <p class="read-more" (click)="readMoreFunc(report?.Id)"><a>{{'read-more'|translate}}</a></p> -->
    </div>
</div>
