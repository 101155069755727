<!-- <div class="photos">
    <header>
        <h2>{{'photo-gallery'|translate}}</h2>
    </header>
    <app-slick-carousel [video]="false"></app-slick-carousel>
    <div class="button d-flex">
        <div class="view-btn btn btn-sm btn-danger rounded-pill my-4" [routerLink]="'/photos'">
            {{'view-more'|translate}}
        </div>
    </div>
</div> -->

<div class="videos">
    <header>
        <h2>{{'video-gallery'|translate}}</h2>
    </header>
    <app-slick-carousel [video]="true"></app-slick-carousel>
    <div class="button d-flex">
        <div class="view-btn btn btn-sm btn-danger rounded-pill my-4" [routerLink]="'/videos'">
            {{'view-more'|translate}}
        </div>
    </div>
</div>