<div class="bg-image">
    <div class="row center m-0">
        <div class="col-xl-6 col-lg-6 description">
            <div class="description-heading">
                {{'contact'|translate}}
            </div>
            <div class="description-text">
                <p class="top" style="font-size: 1.3rem;">{{'address'|translate}}</p>
                <p class="middle">
                    {{'anbagam'|translate}},<br>
                    <!-- {{'head-office'|translate}},<br> -->
                    508, {{'anna-salai'|translate}},<br>
                    <!-- {{'teynampet'|translate}},<br> -->
                    {{'chennai'|translate}}- 600018.
                </p>
                <p class="bottom">044-24349970</p>
                {{'email'|translate}}: <a href="mailto: youthwing@dmk.in">youthwing@dmk.in</a>
            </div>
        </div>
        <!-- <div class="col-xl-4 col-lg-6 site-form mt-4" data-aos="fade-right" data-aos-duration="1000"
            style="padding:1.4rem"> -->
        <div class="col-xl-4 col-lg-6 site-form mt-4" style="padding:1.4rem">
            <p style="color: rgb(197, 6, 6);font-size: 1.1rem;">{{'contact-us'|translate}}</p>
            <div class="row">
                <div class="col-lg-12">
                    <app-input [control]="f.FirstName" placeHolder="{{'full-name'|translate}}"></app-input>
                </div>
                <!-- <div class="col-lg-6">
                    <app-input [control]="f.FirstLast" placeHolder="கடைசி பெயர்"></app-input>
                </div> -->
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <app-input [control]="f.Email" placeHolder="{{'email'|translate}}"></app-input>
                </div>
                <div class="col-lg-6">
                    <app-input [control]="f.MobileNo" placeHolder="{{'mobile-number'|translate}}"></app-input>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-input [control]="f.Subject" placeHolder="{{'subject'|translate}}"></app-input>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col">
                    <textarea class="form-control" [formControl]="f.Message"
                        placeholder="{{'here-your-information'|translate}}..." rows="8" required></textarea>
                    <div style="padding-left: .3rem;color: red;padding-top: .3rem;"
                        *ngIf="f.Message.errors && f.Message.errors.required && f.Message.touched">
                        {{'information'|translate}} {{'required'|translate}}
                    </div>
                </div>
            </div>
            <div class="col-12 submit">
                <button class="btn submit-btn" style="background-color:rgb(197, 6, 6)" (click)="submit()">
                    {{'submit'|translate}}
                </button>
            </div>
        </div>
    </div>
</div>