<div *ngIf="!isDistrict">
    <div class="row">
        <!-- <div *ngIf="category==='DistrictPost'" class="col search">
            <div class="search-input" style="margin: 0 2.5rem;">
                <app-select [options]="district" [control]="searchControl"></app-select>
            </div>
        </div> -->
        <div class="col district-head" *ngIf="category === 'DistrictPost'">
            <!-- <button *ngIf="!isBackBtnHidden" class="btn btn-danger rounded-pill btn-sm m-4"
                (click)="onBackToDistList()">{{'go-back'|translate}}</button> -->
            <h2 class="ml-4">{{DistrictName}}</h2>
        </div>


        <div class="col filter">
            <div class="filter-button">
                <button (click)="onFilterClick()" type="button" class="btn btn-light">{{'filter-by-date'|translate}} <i
                        class="fas fa-filter"></i></button>
            </div>
            <div *ngIf="toggleFilter" class="filter-card">
                <div class="date-text">
                    <div class="prv-month">
                        <a (click)="prvMonth()">
                            {{'prv-month'|translate}}
                        </a>
                    </div>
                    <div class="prv-6-month">
                        <a (click)="prv6Month()">
                            {{'past-six-month'|translate}}
                        </a>
                    </div>
                    <div class="prv-year">
                        <a (click)="prvYear()">
                            {{'prv-year'|translate}}
                        </a>
                    </div>
                </div>
                <div class="row date-inputs">
                    <div class="col-md-12 col-sm-6 date-col">
                        <p class="d-flex  align-items-center mr-2">From</p>
                        <input class="form-control from-date" type="date" max="9999-12-31" name="from-date"
                            id="from-date" [(ngModel)]="startDate">
                    </div>
                    <div class="col-md-12 col-sm-6 date-col">
                        <p class="d-flex  align-items-center mr-4">To</p>
                        <input class="form-control to-date" type="date" max="9999-12-31" name="to-date" id="to-date"
                            min={{startDate}} [(ngModel)]="endDate">
                    </div>


                </div>
                <div class="apply-button d-flex justify-content-around">
                    <button class="btn btn-danger m-2" (click)="dateSubmit()">Apply</button>
                    <button class="btn btn-primary m-2" (click)="clear()">Clear</button>
                </div>
            </div>
        </div>
    </div>

    <div class="reports">
        <div class="row d-flex justify-content-space-between align-items-center">
            <ng-container *ngIf="reports?.length!==0; else noReports">
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 mt-4" *ngFor="let report of reports">
                    <app-pub-card #card [report]="report" (readMore)="readMore($event)"></app-pub-card>
                </div>
            </ng-container>
            <ng-template #noReports>
                <div class="card p-4 mt-4 mx-auto" style="text-align: center;width: 98%;">
                    <div class="no-reports">
                        {{'reports-not-found'|translate}}
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<div *ngIf="isDistrict">
    <!-- districts -->
    <div class="row mx-0 team-members">
        <ol class="row mx-4">
            <div *ngFor="let member of districtMemberData" class="mt-4 col-sm-12 col-md-6 col-lg-4">
                <li class="text-left" [id]="member?.DistId" style="cursor: pointer;" (click)="onDistrictClick(member)">
                    {{member.KalagaDistrict}}</li>
            </div>
        </ol>
    </div>
</div>
