import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { host, mediaUrl } from 'src/app/services/interface';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-libraryhash',
  templateUrl: './libraryhash.component.html',
  styleUrls: ['./libraryhash.component.scss']
})
export class LibraryhashComponent implements OnInit {

  val: any;
  filterval = 'ALLCAT';
  hashvalue: any[] = [];
  comments: any = [];
  selectedTag: any = 2;
  // host = host;
  mediaUrl=mediaUrl

  constructor(
    private service: HttpService,
    private router: Router,
    private meta:Meta
  ) { }

  ngOnInit(): void {
    this.meta.updateTag({ name: 'description', content: 'நூல் அறிமுகம்' });
    this.meta.updateTag({ name: 'twitter:description', content:'நூல் அறிமுகம்'  });

    this.librarycommentsdata(this.filterval);

    this.service.librarydrop().subscribe((res: any) => {
      this.hashvalue = res.Data;
      this.hashvalue.push({ Id: null, hashtagsval: 'ALLCAT' });
      // set all category is by default selected value
      this.selectedTag = this.hashvalue.length - 1;
    });
  }

  librarycommentsdata(libraryval) {
    this.service.libraryallcomments(libraryval).subscribe((res: any) => {
      //  console.log(res.data);
      this.comments = res.data;
    });
  }

  hashCol(val) {
    // console.log(val);
    if (val == "ALLCAT") {
      this.filterval = 'ALLCAT';
      this.librarycommentsdata(this.filterval);
    } else {
      let withouthashval = val.slice(1,);
      this.filterval = withouthashval;
      this.librarycommentsdata(this.filterval);
    }
  }

  onTagClick(index) {
    this.selectedTag = index;
    // console.log(this.selectedTag);
  }

  commentDetails(id) {
    this.router.navigate(['library/librarycommentdetails'], { queryParams: { commentid: id, from: 'libraryhash' } });
    // console.log(id);
  }

  // commentdetails(id){
  //   console.log(id);
  //   this.router.navigate(['commentsdetails'], { queryParams: { commentid: id, from: 'libraryhash' } })
  //   .then(nav => {
  //     console.log(nav); // true if navigation is successful
  //   }, err => {
  //     console.log(err) // when there's an error
  //   });
  // }
}
