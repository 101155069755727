<div class="login-page" *ngIf="loginPage">
    <div class="card login">
        <input class="form-control" [formControl]="f.Username" type="text" placeholder="User Name">
        <input class="form-control" [formControl]="f.Password" type="password" placeholder="Password">
        <button class="btn btn-primary" (click)="login()">
            login
        </button>
    </div>
</div>

<div *ngIf="launchPage">
    <div class="bg">
        <div class="launch-page">
            <button class="btn submit-btn" (click)="launch()">Launch</button>
        </div>
    </div>
</div>


<!-- <h1>Hello</h1> -->