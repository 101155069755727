<div class="bg-image">
    <div class="row center m-0">

        <div class="container-fluid">
            <div class="bookrow">
                <div class="bookdetails">
                    <div class="col-xl-12 description">
                        <div class="description-heading">
                            {{bookdetails?.TitleLib}}
                        </div>
                    </div>
                    <div class="author">
                        <h4><span class="titlehead">{{'author'|translate}}</span> - {{bookdetails?.AuthorNameLib}}</h4>
                    </div>
                    <div class="row">
                        <div class="bookimage col-md-3" *ngIf="bookdetails.BookLibImg">
                            <img [src]="mediaUrl+bookdetails?.BookLibImg" alt="No Image">
                        </div>
                        <div class="desc col-md-9">
                            <h3 *ngIf="!fullDesc">{{bookdetails?.DescLip.slice(0,1500)+'...'}}</h3>
                            <h3 *ngIf="fullDesc">{{bookdetails?.DescLip}}</h3>
                            <a (click)="onReadMore()"
                                *ngIf="bookdetails?.DescLip.length>=1500&&!fullDesc">{{'read-more'|translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="comments.length">
                <div class="comhead">
                    <h1>{{'book-commentary'|translate}}</h1>
                </div>
                <div class="commentcol col-md-12">
                    <div class="row">
                        <div class="card col-md-3" *ngFor="let item of comments">
                            <img (click)="commentDetails(item.Id)" class="image" [src]="mediaUrl+item?.BookLibImg"
                                alt="Avatar">
                            <div class="container">
                                <p class="date-time">
                                    12 Oct 2021
                                </p>
                                <h5 class="heading" (click)="readMoreFunc(report?.Id)"> {{item.TitleLib}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>