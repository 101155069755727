<div class="background-img"></div>
<div class="report-details">
  <!-- <div class=insidediv><div class=col-md-4></div><div class=col-md-6></div><div class=col-md-4></div></div> -->

  <div class="row contents">
    <div class="col-lg-1 p-0"></div>

    <div class="col-lg-10 p-0">
      <div class="report-wrapper">
        <div class="d-flex justify-content-between align-items-center twobtn">
          <div class="back-button d-flex">
            <a
              class="view-btn btn btn-sm btn-danger rounded-pill1 my-4"
              (click)="onBack()"
            >
              {{ "go-back" | translate }}
            </a>



          </div>
          <div class="end-div">
            <!-- <button
              class="btn btn-danger"
              style="position: relative; left: -135px"
              (click)="author(reportData['author_id'])"
            >
              {{ "author1" | translate }} - {{ name }}
            </button> -->
          </div>
        </div>
        <!-- <div class="back-button d-flex">
                    <a class="view-btn btn btn-sm btn-danger rounded-pill my-4" (click)="onBack()">
                        {{'go-back'|translate}}
                    </a>
                </div>
                <div class="d-flex-end">
                    <a class="view-btn btn btn-sm btn-danger rounded-pill my-4" (click)="onBack()">
                        {{'go-back'|translate}}
                    </a>
                </div> -->

        <div class="heading">
          <!-- <h2>{{ reportData["PostTitle"] }}</h2> -->
          <!--எழுத்தாளர் --->
          <h6
            (click)="author(reportData['author_id'])"
            style="text-decoration: underline; cursor: pointer"
          >
            {{ name }}
          </h6>

          <div class="date-time">
            <p>
              பதிவு
              {{
                reportData["PostDateTime"]| date : "dd MMM yyyy"}},{{ reportData["PostDateTime"] | date : "hh:mm:ss" }} மணி
            </p>
          </div>
          <!-- <h2>{{reportData['PostName']}}</h2> -->
        </div>
        <!-- <div class="sub-heading">
                    <h5>{{reportData['PostTitle']}}</h5>
                </div> -->

        <!-- <div class="videos row" *ngIf="videos.length>0"> -->
        <!-- <div class="video-title">காணொளி</div> -->
        <div *ngFor="let video of videos">
          <div class="video col-sm-12 col-md-6 col-lg-6 mt-4">
            <iframe
              class="youtube-video"
              frameborder="0"
              allowfullscreen
              [src]="video"
            ></iframe>
          </div>
        </div>

        <ng-template *ngIf="images?.length > 0; else singleimage">
          <div class="images slick-wrapper slick-track">
            <ngx-slick-carousel
              class="carousel"
              #slickModal="slick-carousel"
              [config]="slideConfig"
            >
              <!-- show only 10 images and videos -->
              <div
                ngxSlickItem
                *ngFor="let image of images; let i = index"
                class="slide"
                style="width: 128px"
                (click)="showPreview(i)"
              >
                <img
                  class="image"
                  [src]="mediaUrl + image.filefield"
                  [alt]="image.filefield"
                />
              </div>
            </ngx-slick-carousel>
          </div>
        </ng-template>

        <ng-container #singleimage>
          <div class="thumbnail-img">
            <img [src]="mediaUrl + reportData['PostImage']" [alt]="" />
          </div>
        </ng-container>

        <div class="content mt-4">
          <div [innerHTML]="postContent"></div>
        </div>

        <div class="content mt-4">
          <p class="share-social-title1">
           Hashtags:
          </p>
          <ul class="d-flex flex-wrap tag">
            <div>
              <li
              class="badge rounded-pill mr-2 mt-4" *ngFor="let item of hashTags ">
                <span style="cursor: pointer;"  (click)="hashClick(item)">{{ item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()   }}</span>
              </li>
            </div>
          </ul>
          <!-- <div class="libdrp" *ngIf="currentArticleValue.length">
            <ul class="d-flex flex-wrap tag" *ngIf="lang == true; else allcat1">


              <div *ngFor="let item of currentArticleValue; let i = index" (click)="onTagClickCurrentTag(i)">
                <li
                  class="badge rounded-pill mr-2"
                  [ngClass]="{ selectedTagC: selectedTagC == i }"
                  (click)="hashCol(item.id, item.hashtagsval, item.cat_icon)"
                >


                  <span *ngIf="item.hashtagsval != 'ALLCAT'">{{
                    item.subcat_tamil
                  }}</span>





                </li>



              </div>
              <div>
                  <li
                class="badge rounded-pill mr-2"
                [ngClass]="{ selectedTagB: selectedTagB == 'previous' }"
                (click)="onTagPressed('previous')">


                <span> {{'previous-article'|translate}}      </span>


            </li>
          </div>
          </ul>
          </div> -->
        </div>
        <div class="share-social mt-4">
          <p class="share-social-title">
            {{ "share-on-social-media" | translate }}
          </p>

          <div class="share-buttons-container">
            <app-instagram-share-button [urlToShare]="customUrl"></app-instagram-share-button>

            <share-buttons
              [include]="['facebook', 'twitter', 'whatsapp', 'email', 'copy']"
              [url]="customUrl"
            >
            </share-buttons>
          </div>
          <!-- <share-buttons
            [include]="['facebook', 'twitter', 'whatsapp', 'email', 'copy']"
            [url]="customUrl"
          >
          </share-buttons>
          <app-instagram-share-button></app-instagram-share-button> -->



        </div>

        <div class="comment-section">
          <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem>
              <a ngbNavLink>{{ "comments" | translate }}</a>
              <ng-template ngbNavContent>
                <div
                  #scrollMe
                  [scrollTop]="scrollMe.scrollHeight"
                  class="comment-view"
                >
                  <div *ngFor="let comment of comments">
                    <div class="comment">
                      <span class="comment-date">{{
                        comment.PostDate | date : "dd MMM yyyy"
                      }}</span>
                      <p class="comment-name">{{ comment.Name }}</p>
                      <p class="comment-desc">{{ comment.Description }}</p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink>{{ "post-comments" | translate }}</a>
              <ng-template ngbNavContent>
                <div class="comment-post">
                  <form>
                    <app-input
                      [control]="f.Name"
                      placeHolder="{{ 'full-name' | translate }}"
                    >
                    </app-input>
                    <app-input
                      [control]="f.MobileNo"
                      placeHolder="{{ 'mobile-number' | translate }}"
                    >
                    </app-input>

                    <textarea
                      class="form-control"
                      [formControl]="f.Description"
                      placeholder="{{ 'here-your-information' | translate }}..."
                      rows="8"
                      required
                    ></textarea>
                    <div
                      style="
                        padding-left: 0.3rem;
                        color: red;
                        padding-top: 0.3rem;
                      "
                      *ngIf="
                        f.Description.errors &&
                        f.Description.errors.required &&
                        f.Description.touched
                      "
                    >
                      {{ "information" | translate }}
                      {{ "required" | translate }}
                    </div>

                    <button
                      class="btn btn-danger mt-4"
                      style="background-color: rgb(197, 6, 6)"
                      (click)="onPostComment()"
                    >
                      {{ "submit" | translate }}
                    </button>
                  </form>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>

    <!--
      <div class="col-md-4"> </div>

      <div class="col-md-2">
      <h1> WELCOME</h1>
      </div>
      <!-- </div> -->

    <!-- <div class="col-md-4"></div> -->
    <!-- <div class="col-md-4 card-article">
     <div class="heading other-reports"> <h5>பிற அறிக்கைகள்</h5> </div>
       <app-pub-card [report]="nextReportData" (readMore)="readMore($event)"></app-pub-card>


       <div class="card-container">
        <mat-card
          *ngFor="let item of articleData"
          (click)="articleClick(item.Id)"
        >
          <div class="card-content">
            <div style="width: 300px">
              <img class="artImage" [src]="mediaUrl + item?.PostImage" />
            </div>

            <mat-card-title>{{ item.PostName }}</mat-card-title>
             <mat-card-subtitle>{{item.PostDateTime}}</mat-card-subtitle>
            <mat-card-content>{{item.description}}</mat-card-content>
          </div>
        </mat-card>

        <mat-card *ngFor="let cat of catValue" (click)="catClick(cat.id)">
          <div class="card-content1">
            <img [src]="mediaUrl + cat?.cat_icon" />

            <mat-card-title>{{ cat.subcat_tamil }}</mat-card-title>
             <mat-card-subtitle>{{item.PostDateTime}}</mat-card-subtitle>
             <mat-card-content>{{item.description}}</mat-card-content>
          </div>
        </mat-card>
      </div>

       <mat-card *ngFor="let item of articleData">

                <mat-card-header>
                    <mat-card-title>{{item.PostTitle}}</mat-card-title>
                    <mat-card-subtitle>{{item.PostDateTime}}</mat-card-subtitle>
                </mat-card-header>
                <img mat-card-image [src]="item.Postimage">
                <mat-card-content>
                </mat-card-content>
            </mat-card>
    </div> -->
  </div>
</div>
<div class="col-md4"></div>

<!-- </div> -->
