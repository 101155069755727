<div class="bg"></div>

<h2 class="heading my-4">{{'video-gallery'|translate}}</h2>

<div class="row mr-3">
    <div *ngFor="let item of items" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <div class="videos">
            <iframe width="100%" frameborder="0" allowfullscreen [src]="item.youTube"></iframe>
            <p class="title">{{item.Title | titlecase}}</p>
        </div>
    </div>
</div>