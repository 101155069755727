import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/services/http.service';
import { mediaUrl } from 'src/app/services/interface';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  templateDetails: any;

  currentLang: string;
  slideIndex = 1;
  mediaUrl = mediaUrl;
  data = [
    {
      name: 'Template 2',
      id: '2',
      temp_id: '2',
      url: ''
    },
    {
      name: 'Template 3',
      id: '3',
      temp_id: '3',
      url: ''
    },
    {
      name: 'Template 1',
      id: '1',
      temp_id: '1',
      url: ''
    },
    {
      name: 'New Temp 2',
      id: '2',
      temp_id: '2',
      url: ''
    },
    {
      name: 'New Temp 3',
      id: '2',
      temp_id: '3',
      url: ''
    }
  ];

  constructor(
    private router: Router,
    private service: HttpService,
    private config: NgbCarouselConfig,
    private translator: TranslateService,
  ) {
    config.interval = 100000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = false;

  }

  ngOnInit(): void {
    this.getTemplatesData();
    // this.currentLang = localStorage.getItem("lang");
    // console.log(this.currentLang);

    this.translator.onDefaultLangChange.subscribe((res) => {
      console.log('translation Response', res.lang);
      this.currentLang = res.lang;
    });
  }

  getTemplatesData() {
    this.service.getTemplates().subscribe(res => {
      console.log(res['Data']);
      this.templateDetails = res['Data'];
      console.log(this.templateDetails);
      console.log(this.templateDetails.length);
    });
  }

  // application(id) {
  //   // this.router.navigate(['./application']);
  //   console.log(id);

  //   for (let i = 0; i < this.templateDetails.length; i++) {
  //     console.log(this.templateDetails[i]);
  //     if (this.templateDetails[i].Id == id) {
  //       // this.router.navigate(this.templateDetails[i].Template_url);
  //       console.log(this.templateDetails[i].Template_url);
  //       window.location.href = this.templateDetails[i].Template_url;
  //     }
  //   }

  // }

  application() {
    this.router.navigate(['./application']);
  }




}
