import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, tap, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ErrrorInterceptor implements HttpInterceptor {

  constructor(
    public router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.spinner.show();

    return next.handle(request)
      .pipe((tap((event) => { if (event instanceof HttpResponse) this.spinner.hide(); })),
        timeout(60000),
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          this.spinner.hide();
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          }
          // else {
          //   if (error.status == 0) {
          //     this.toastr.error('ஏதோ தவறாகிவிட்டது மீண்டும் முயற்சிக்கவும்');
          //   }
          //   else {
          //     try { this.toastr.info(error.error.status.message); }
          //     catch { this.toastr.info('ஏதோ தவறாகிவிட்டது மீண்டும் முயற்சிக்கவும்'); }
          //   }
          //   errorMsg = `Error Code: ${error}`;
          // }
          return throwError(error);
        })
      );
  }
}
