<!-- <nav class="navbar animate__animated animate__jackInTheBox animate__delay-2s"> -->
<nav class="navbar">
    <div class="navbar-left-menu">
        <a routerLink="./">
            <div class="logo">
                <img src="../../../assets/images/Youth wing logo2.png" alt="logo">
            </div>
        </a>
        <ul id="desktop">
            <li class="nav-item">
                <a routerLink="./" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="nav-link">
                    {{'home'|translate}}
                </a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" [ngClass]="{'active':pubRoute}" style="cursor: pointer;"
                    aria-labelledby="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    {{'publications'|translate}}
                </a>
                <div class="dropdown-menu">
                    <ng-container *ngFor="let item of publicationsItems">
                        <!-- hide sub menu  -->
                        <a (click)="onNewsSubMenu(item)" *ngIf="!item.hide" [routerLink]="item.link"
                            routerLinkActive="sub-active" [routerLinkActiveOptions]="{exact:false}"
                            class="dropdown-item">
                            {{ 'news.' + item.name | translate }}
                        </a>
                    </ng-container>
                </div>
            </li>

            <li class="nav-item">
                <a routerLink="./functionaries" routerLinkActive="active" class="nav-link">
                    {{'youth-wing-functionaries'|translate}}
                </a>
            </li>
            <!-- <li class="nav-item">
                <a routerLink="./secretary-in-service" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact:false}" class="nav-link">
                    {{'news.secretary'|translate}}
                </a>
            </li> -->

            <li class="nav-item">
                <a routerLink="./secretary-in-public-service" (click)="clicksec()" routerLinkActive="active"
                    class="nav-link">
                    {{'news.secretary'|translate}}
                </a>
            </li>
            <li class="nav-item">
                <a routerLink="./application" routerLinkActive="active" class="nav-link">
                    {{'apply'|translate}}
                </a>
            </li>

            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" style="cursor: pointer;" aria-labelledby="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{'press'|translate}}
                </a>
                <div class="dropdown-menu">
                    <ng-container *ngFor="let item of magazineItems">
                        <a [href]="item.link" target="_blank" class="dropdown-item">
                            {{ item.name | translate }}
                        </a>
                    </ng-container>
                </div>
            </li>
            <!-- <li class="nav-item">
                <a routerLink="./payment" routerLinkActive="active" class="nav-link">
                    {{'youth-wing-foundation'|translate}}
                </a>
            </li> -->
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" [ngClass]="{'active':foundationRoute}" style="cursor: pointer;"
                    aria-labelledby="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    {{'youth-wing-foundation'|translate}}
                </a>
                <div class="dropdown-menu">
                    <ng-container *ngFor="let item of foundationItems">
                        <a [routerLink]="item.link" routerLinkActive="sub-active"
                            [routerLinkActiveOptions]="{exact:false}" class="dropdown-item">
                            {{'foundation.'+item.name|translate}}
                        </a>
                    </ng-container>
                </div>
            </li>

            <!-- <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" routerLink="./library">
                    {{'library'|translate}}
                </a>
            </li> -->

            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" [ngClass]="{'active':libraryRoute}" style="cursor: pointer;"
                    aria-labelledby="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    {{'library'|translate}}
                </a>
                <div class="dropdown-menu">
                    <ng-container *ngFor="let item of libraryItems">
                        <a [routerLink]="item.link" routerLinkActive="sub-active"
                            [routerLinkActiveOptions]="{exact:false}" class="dropdown-item">
                            {{'library-dropdown.'+item.name|translate}}
                        </a>
                    </ng-container>
                </div>
            </li>



            <li class="nav-item">
                <a routerLink="./contact" routerLinkActive="active" class="nav-link">
                    {{'contact'|translate}}
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="./privacy-policy" routerLinkActive="active" class="nav-link">
                    {{'privacy-policy'|translate}}
                </a>
            </li>
        </ul>
    </div>

    <div class="navbar-right-menu">
        <div class="social-media">
            <app-social-media></app-social-media>
        </div>
        <div class="login">
            <button *ngIf="!loggedIn" (click)="onLogin(content)" class="btn btn-primary btn-sm login-btn">Login</button>
            <button *ngIf="loggedIn" (click)="onLogout()" class="btn btn-primary btn-sm logout-btn">Logout</button>
        </div>
        <div id="mobile" style="font-size:30px;cursor:pointer;color: white;display: none; position: absolute;"
            (click)="toggleNav()">&#9776;
        </div>
        <div class="language">
            <select class="choose-lang" (change)="changeLang($event?.target?.value)">
                <option value="ta">தமிழ்</option>
                <option value="en">English</option>
            </select>
        </div>
    </div>
</nav>
<!-- for small screens -->
<div id="mySidenav" style="display: none;" class="sidenav">
    <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
    <a routerLink="./" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="nav-link">
        {{'home'|translate}}
    </a>
    <a class="disable-select" style="cursor: pointer;" (click)="mobPubClick()">
        {{'publications'|translate}}
    </a>
    <div *ngIf="mobPubLink==true" style="margin-left: 2rem;">
        <ng-container *ngFor="let item of publicationsItems">
            <a (click)="onNewsSubMenu(item)" *ngIf="!item.hide" routerLinkActive="active" [routerLink]="item.link">
                {{ 'news.' + item.name | translate }}
            </a>
        </ng-container>
    </div>
    <a routerLink="./functionaries" routerLinkActive="active">
        {{'youth-wing-functionaries'|translate}}
    </a>

    <a routerLink="./secretary-in-public-service" (click)="clicksec()" routerLinkActive="active" class="nav-link">
        {{'news.secretary'|translate}}
    </a>
    <a routerLink="./application" routerLinkActive="active">
        {{'apply'|translate}}
    </a>


    <a class="disable-select" style="cursor: pointer;" (click)="mobNewsClick()">
        {{'press'|translate}}
    </a>
    <div *ngIf="mobNewsLink==true" style="margin-left: 2rem;">
        <ng-container *ngFor="let item of magazineItems">
            <a [href]="item.link" target="_blank">
                {{ item.name | translate }}
            </a>
        </ng-container>
    </div>

    <!-- <a href="https://www.murasoli.in/epaper/" class="nav-link" target="_blank">
        {{'murasoli'|translate}}
    </a>
    <a href="https://www.therisingsunmagazine.com/" class="nav-link" target="_blank">
        {{'the-rising-sun'|translate}}
    </a> -->

    <a routerLink="foundation/donate" routerLinkActive="active">
        {{'youth-wing-foundation'|translate}}
    </a>
    <!-- <a class="nav-link" routerLink="./library">
        {{'library'|translate}}
    </a> -->

    <a class="disable-select" style="cursor: pointer;" (click)="mobLibClick()">
        {{'library'|translate}}
    </a>
    <div *ngIf="mobLibLink==true" style="margin-left: 2rem;">
        <ng-container *ngFor="let item of libraryItems">
            <a routerLinkActive="active" [routerLink]="item.link">
                {{ 'library-dropdown.' + item.name | translate }}
            </a>
        </ng-container>
    </div>


    <a routerLink="./contact" routerLinkActive="active">
        {{'contact'|translate}}
    </a>

    <div class="login-mobile">
        <button *ngIf="!loggedIn" (click)="onLogin(content)" class="btn btn-primary btn-sm login-btn">login</button>
        <button *ngIf="loggedIn" (click)="onLogout()" class="btn btn-primary btn-sm logout-btn">logout</button>
    </div>

    <select class="choose-lang-side-nav" (change)="changeLang($event?.target?.value)">
        <option value="ta">தமிழ்</option>
        <option value="en">English</option>
    </select>
</div>

<!-- log-in model -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{'enter'|translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-input [control]="f.username" placeHolder="{{'username'|translate}}" (keyup.enter)="onEnter()"></app-input>
        <app-input [control]="f.password" placeHolder="{{'password'|translate}}" type='password'
            (keyup.enter)="onEnter()"></app-input>
        <div class="d-flex justify-content-center">
            <button type="button" class="btn btn-primary" (click)="loginSubmit()">{{'submit'|translate}}</button>
        </div>
    </div>
</ng-template>