<div class="banner">
    <app-banner></app-banner>
</div>
<div class="history-feed">
    <app-history-feed></app-history-feed>
</div>
<div class="container-fluid gallery">
    <app-gallery></app-gallery>
</div>
<div class="reports">
    <app-reports></app-reports>
</div>

<!-- <div class=" footer">
    <div class="text">
        <p>&copy; 2021 DMK Youth Wing. All Rights Reserved.</p>
    </div>
    <div class="social-media">
        <app-social-media></app-social-media>
    </div>
</div> -->