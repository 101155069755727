<!-- <div *ngIf="options.placeholder!=='district';else district"> -->

<!-- <ng-select [items]=" options.data" bindLabel="name" bindValue="id" class="mt-3 custom"
        [ngClass]="{'pb-2':!(control.errors&&control.touched)}"
        placeholder="{{options.placeholder|translate}} {{'constituency'|translate}}" [searchable]="options.searchable"
        [multiple]="options.multiple" (ngModelChange)="onChange($event)" [formControl]="control">
    </ng-select> -->
<ng-select [items]=" options.data" bindLabel="name" bindValue="id" class="mt-3 custom"
    [ngClass]="{'pb-2':!(control.errors&&control.touched)}" placeholder="{{options.placeholder|translate}}"
    [searchable]="options.searchable" [multiple]="options.multiple" (ngModelChange)="onChange($event)"
    [formControl]="control">
</ng-select>
<div class="error m-0" style="padding-top: 5px;" *ngIf="control.errors && control.touched">
    {{options.placeholder|translate}} {{'required'|translate}}.
</div>

<!-- </div> -->



<!-- <ng-template #district>
    <ng-select [items]=" options.data" bindLabel="name" bindValue="id" class="mt-3 custom"
        [ngClass]="{'pb-2':!(control.errors&&control.touched)}" placeholder="{{options.placeholder|translate}}"
        [searchable]="options.searchable" [clearable]="options.clearable" [multiple]="options.multiple"
        [formControl]="control">
    </ng-select>
    <div class="error m-0" style="padding-top: 5px;" *ngIf="control.errors && control.touched">
        {{options.placeholder|translate}} {{'required'|translate}}.
    </div>
</ng-template> -->