import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  constructor(private meta:Meta) { }

  ngOnInit(): void {
    this.meta.updateTag({ name: 'description', content: 'நிதி வழங்க கொரோனா தொற்றால், ஊரடங்கால் பாதிக்கப்பட்டோரை மீட்டெடுக்கும் பணிகளில் கழக அரசு முனைப்புடன் செயல்பட்டு வருகிறது. இப்பணியில் இளைஞரணியும் தன்னை முழுமையாக ஈடுபடுத்திக் கொள்கிறது.' });

  }

}
