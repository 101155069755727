import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from '../services/http.service';
import AOS from 'aos';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  title = 'DMK-Youth-Wing-Website';
  loginPage: boolean;
  launchPage = false;
  mainPage = true;
  loginForm: FormGroup;

  constructor(
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private service: HttpService,
    private toastr: ToastrService,
    private router: Router
  ) {
    // trans service
    translate.addLangs(['en', 'ta']);
    translate.setDefaultLang('ta');
    // login form
    this.loginForm = formBuilder.group({
      Username: [null],
      Password: [null]
    });
  }

  ngOnInit(): void {

    // AOS.init();
    AOS.init({
      disable: 'mobile'
    });

    this.mainPage = Boolean(Number(localStorage.getItem('mainPage')) | 1);
    // console.log('main page', this.mainPage);

    // prevent login page show on load for short time
    // this.firstTimeLogin = false;

    this.service.firstTimeLogin().subscribe((res: { data: any, Status: string; }) => {
      // console.log(res.data[0].active);


      if (res.data[0].active == 1) {
        this.loginPage = true;
        localStorage.setItem('mainPage', '0');
        this.mainPage = false;
      } else {
        this.loginPage = false;
        localStorage.setItem('mainPage', '1');
      }
    });
  }

  get f() {
    return this.loginForm.controls;
  }
  login() {
    // console.log('login form', this.loginForm.value);
    this.service.checkPassword(this.loginForm.value).subscribe((res: { Message: string, Status: string; }) => {
      // console.log('response', res);
      if (res.Status == 'Pass') {
        // alert('successfully logged in');
        this.toastr.success('successfully logged in');
        this.loginPage = false;
        this.launchPage = true;
      } else {
        // alert('username or password is invalid');
        this.toastr.warning('username or password is invalid');

      }

    });

  }

  launch() {
    this.service.launchWebsite().subscribe((res: { Message: string, Status: string; }) => {
      if (res.Status == 'Pass') {
        this.launchPage = false;
        localStorage.setItem('mainPage', '1');
        this.mainPage = true;
        this.router.navigate(['/applaunched']);
      }
    });

  }

  ngOnDestroy(): void {
    // this.service.firstTimeLogin();
    // this.firstTimeLogin = true;

  }

}
