import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';
import { host, mediaUrl } from 'src/app/services/interface';
declare var $;

@Component({
  selector: 'app-org-chart',
  templateUrl: './org-chart.component.html',
  styleUrls: ['./org-chart.component.scss']
})
export class OrgChartComponent implements OnInit {
  @Input() chartData: any;
  @Input() loggedIn: boolean;
  @Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();
  distMemberData: any = {};
  selectedId: any; //to highlight dist member
  activeDiv: boolean = false; //to activate bootstrap grid
  constructor(
    private router: Router,
    private commonService: CommonService,
    private service: HttpService,
    private spinner: NgxSpinnerService,

  ) { }

  ngOnInit(): void {
    // console.log('this chart data', this.chartData);
    // function for org tree
    $(function () {
      $('.genealogy-tree ul').hide();
      $('.genealogy-tree>ul').show();
      $('.genealogy-tree ul.active').show();
      $('.genealogy-tree li').on('click', function (e) {
        var children = $(this).find('> ul');
        if (children.is(":visible")) children.hide('fast').removeClass('active');
        else children.show('fast').addClass('active');
        e.stopPropagation();
      });
    });

    // console.log('chart dist', this.chartData);

  };

  onPublications() {
    // set heading name of the pub-page to district
    this.commonService.changeData('district');
    this.router.navigate(['publications/district'], { queryParams: { 'distId': this.chartData.DistId, 'distName': this.chartData.DistName } });
    // this.router.navigate(['publications/district']);
  }
  onSubdivision() {
    window.open(this.service.openTree(this.chartData.DistId), '_blank');
  }
  onMember(headId, index) {
    // this.active = true;
    this.spinner.hide();
    this.activeDiv = true;
    this.selectedId = index;
    // console.log('member click', Id);
    this.service.getDistMember(headId).subscribe((res: any) => {
      this.spinner.hide();
      // console.log('member data', res.Data);


      this.distMemberData['name'] = res.Data[0].Name;
      this.distMemberData['photo'] = mediaUrl + res.Data[0].Photo;
      this.distMemberData['address'] = res.Data[0].Address;
      this.distMemberData['post'] = res.Data[0].Post;
      this.distMemberData['kalagaDistrict'] = res.Data[0].KalagaDistrict.split('-')[0];

      // console.log('member data', this.distMemberData);

    });

  }
  onBack() {
    this.back.emit(false);
  }

}
