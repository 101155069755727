import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  newsSubMenuName: string;
  publicationsItems = [
    {
      name: 'report',
      link: '/publications/report'
    },
    // {
    //   name: 'secretary',
    //   link: '/publications/secretary-in-service'
    // },
    {
      name: 'shorts',
      link: '/publications/press-release',
      hide: true
    },
    {
      name: 'photo',
      link: '/publications/photo'
    },
    {
      name: 'video',
      // link: '/publications/video'
      link: '/videos'
    },
    // {
    //   name: 'programme',
    //   link: '/publications/program'
    // },
    // {
    //   name: 'announcement',
    //   link: '/publications/announcement'
    // },
    {
      name: 'articles',
      link: '/publications/articles'
    },
    {
      name: 'achievements',
      link: '/publications/achievements'
    },
    {
      name: 'district',
      link: '/publications/district'
    },
  ];
  magazineItems = [
    {
      name: 'murasoli',
      link: 'https://www.murasoli.in/epaper/'
    },
    {
      name: 'the-rising-sun',
      link: 'https://www.therisingsunmagazine.com/'
    },
  ];
  foundationItems = [
    {
      name: 'donation',
      link: 'foundation/donate'
    },
    // {
    //   name: 'beneficiaries',
    //   link: 'foundation/beneficiaries'
    // }
  ];
  libraryItems = [
    {
      name: 'books',
      link: 'library/books'
    },
    {
      name: 'commentary',
      link: 'library/bookcommentary'
    }
  ];

  pubRoute: boolean;
  foundationRoute: boolean;
  libraryRoute: boolean;
  mobPubLink = false;
  mobNewsLink = false;
  mobLibLink = false;
  navOpen: boolean;
  // model form
  loginForm: FormGroup;
  loggedIn: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private modalService: NgbModal,
    private service: HttpService,
    private toastr: ToastrService,
    private translate: TranslateService


  ) { }

  ngOnInit(): void {
    this.router.events
      .subscribe(event => {
        // get current sub-menu name on refresh
        if (event instanceof NavigationStart) {
          // console.log(event.url);
          this.closeNav();
          // enable active nav menu style
          if (event.url.includes('publications')) {
            this.pubRoute = true;
            this.foundationRoute = false;
            this.libraryRoute = false;


          }
          else if (event.url.includes('foundation')) {
            this.foundationRoute = true;
            this.pubRoute = false;
            this.libraryRoute = false;

          }
          else if (event.url.includes('library')) {
            this.libraryRoute = true;
            this.pubRoute = false;
            this.foundationRoute = false;
          }
          else {
            this.pubRoute = false;
            this.foundationRoute = false;
            this.libraryRoute = false;
          }
          // then pass the current sub-menu name to service
          setTimeout(() => {
            this.newsSubMenuName = this.publicationsItems.filter(item => {
              if (item.link === event.url) return item;
            })[0]?.name;
            this.commonService.changeData(this.newsSubMenuName);
          }, 0);
        }
      });
    //model form
    this.loginForm = new FormGroup({
      // email: new FormControl('', [Validators.required, Validators.email]),
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
  }

  clicksec() {
    console.log(this.translate.getDefaultLang(), "current lang");
    console.log("sec click fun");
    localStorage.removeItem("distID");
    localStorage.removeItem("itemCard");
    localStorage.removeItem("itemCard1");
    this.translate.setDefaultLang(this.translate.getDefaultLang());

  }
  openNav() {
    this.navOpen = true;
    document.getElementById("mySidenav").style.width = "250px";
  }

  closeNav() {
    this.navOpen = false;
    document.getElementById("mySidenav").style.width = "0";
    // close sub menus when close side nav
    if (this.mobPubLink) {
      this.mobPubClick();
    }
    if (this.mobNewsLink) {
      this.mobNewsClick();
    }
  }

  toggleNav() {
    if (this.navOpen) {
      this.closeNav();
    } else {
      this.openNav();
    }
  }
  // toggle sub menus on mobile mode
  mobPubClick() {
    this.mobPubLink = !this.mobPubLink;
  }
  mobNewsClick() {
    this.mobNewsLink = !this.mobNewsLink;
  }
  mobLibClick() {
    this.mobLibLink = !this.mobLibLink;
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  onNewsSubMenu(item) {
    // get current sub-menu tamil name on change
    this.newsSubMenuName = item.name;
    this.commonService.changeData(this.newsSubMenuName);
    console.log(this.newsSubMenuName, "205");
    // reload page on same sub (active) menu click
    this.redirectTo(item.link);
    console.log(item.link, "208");
  }
  changeLang(lang: string) {
    this.commonService.setLanguage(lang);
  }
  // login model form
  onLogin(content) {
    this.modalService.open(content, { centered: true });

  }

  onLogout() {
    this.commonService.memberLogin = 'notLogged';
    this.loggedIn = false;
    this.reloadCurrentRoute();
    // this.router.navigate(['youth-team']);
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  loginSubmit() {
    if (this.loginForm.valid) {
      this.service.memberLogin(this.loginForm.value).subscribe(res => {
        // console.log(res);
        if (res['status'] == 1) {
          this.modalService.dismissAll();
          this.commonService.memberLogin = 'logged';
          this.loggedIn = true;
          this.commonService.loginFormValue = this.loginForm.value;
          // if current route is same, reload the component
          if (this.router.url == '/youth-team') {
            this.reloadCurrentRoute();
          }
          // else {
          //   this.router.navigate(['youth-team']);
          // }
        } else {
          this.toastr.error('மின்னஞ்சல் அல்லது கடவுச்சொல் தவறு');
          this.loginForm.reset();
        }
      }, err => {
        // console.log(err);
      });
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  // enter on model form to submit
  onEnter() {
    this.loginSubmit();
  }
}
