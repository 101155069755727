import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
// import { ActivatedRoute, Data } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { alphabetRegex, mediaUrl, mobileNumberRegex } from 'src/app/services/interface';
import { TranslateService } from '@ngx-translate/core';
import { ApiResponse } from 'src/app/services/interface';
import { MetaService } from 'src/app/services/metatag';

import { response } from 'express';
import { Router, ActivatedRoute,Data, NavigationEnd,NavigationExtras } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NgNavigatorShareService } from 'ng-navigator-share';


@Component({
  selector: 'app-hashtags-article-secretary-in-service',
  templateUrl: './hashtags-article-secretary-in-service.component.html',
  styleUrls: ['./hashtags-article-secretary-in-service.component.scss']
})
export class HashtagsArticleSecretaryInServiceComponent implements OnInit {

  hashTagId: any;
  hashtagArticle: string[] = [];
  // reportData: [];


  constructor(
    public service: HttpService,
    private activeRoute: ActivatedRoute,
    private _location: Location,
    private _sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    // private metaService: Meta,
    private translate: TranslateService,
    private router: Router,
    private titleService: Title,

  ) { }

  ngOnInit(): void {

    this.activeRoute.queryParams.subscribe(params => {
      console.log(params, "paramssssss");
      this.hashTagId = params.Id;
      console.log(params.Id, "hashtagId");
    });

    this.service.getHashTagArticles(this.hashTagId).subscribe((res: any) => {
      console.log(res, "hasgtagArticle");
      // this.hashtagArticle=[];

      let arr = res.data.map(element => {
   return {
          Id: element.Id, PostTitle: element.PostTitle, PostName: element.PostName,
          PostContent: element.PostContent,
          categories: element.categories,
          PostImage: element.PostImage,
          VideURL: element.VideURL,
          SincDateTime: element.SinceDateTime,
          PostDateTime: element.PostDateTime,
          // hashtags: element.hashtags,

        };
      });

      this.hashtagArticle = [...arr];  //show all categories detail on page load
      // this.reports = [];  //show all categories detail on page load - testing empty
      console.log(this.hashtagArticle, "hashtagss");

      // this.ngZone.run(() => { });
    });
  }
  readMore(event) {

    localStorage.setItem("itemCard", event);
   console.log(event, "dddddd");


   ///////////////////////////
    let navigationExtras: NavigationExtras = {
      queryParams: { 'Id': event }
    };
    // this.router.navigate(['/publication-details'], navigationExtras);
    this.router.navigate(['pubnew'], { queryParams: { 'Id': event } });

    // this.router.navigate(['/pubnew'], navigationExtras);


  }

}
