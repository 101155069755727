import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { mediaUrl } from 'src/app/services/interface';
import { SelectOptions } from '../../select/select.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-youth-team',
  templateUrl: './youth-team.component.html',
  styleUrls: ['./youth-team.component.scss']
})
export class YouthTeamComponent implements OnInit {
  mediaUrl = mediaUrl;
  // host = host;
  stateMemberData: Array<object>;
  loggedInSateMemberData: Array<object>;
  isLoggedInStateMemberClicked: boolean = false;
  districtMemberData: Array<object>;
  LoggedInMemberData: Array<object>;
  distWithLoggedInMemberData: Array<object>;
  loggedIn: boolean = false;
  loginBtn: boolean = false;
  orgChart: boolean = false;
  chartData: any;
  driveBaseURL = "https://drive.google.com";
  imgBaseURL = "http://192.168.5.136:8004/";

  selection: SelectOptions = {
    placeholder: '',
    data: [],
  };
  control = new FormControl('state');
  loginForm: FormGroup;
  // @ViewChild('content') content: ElementRef;
  constructor(
    private service: HttpService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translator: TranslateService,
    private commonService: CommonService,
    private meta: Meta

  ) {
    // route.params.subscribe(val => {
    //   this.ngOnInit();
    // });
    // override the route reuse strategy
    // this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return false;
    // };
  }
  langChange(lang) {
    if (lang === "en") {
      this.selection.data = [
        {
          id: 'state',
          name: 'State'
        },
        {
          id: 'district',
          name: 'District'
        },
        // {
        //   id: 'volunteers',
        //   name: 'Volunteers'
        // }
      ];
    }
    else if (lang === "ta") {
      this.selection.data = [
        {
          id: 'state',
          name: 'மாநிலம்'
        },
        {
          id: 'district',
          name: 'மாவட்டம்'
        },
        // {
        //   id: 'volunteers',
        //   name: 'தன்னார்வலர்கள்'
        // }
      ];
    }
  }
  ngOnInit(): void {
    this.meta.updateTag({ name: 'description', content: 'DMK நிர்வாகிகள்' });

    // value set by back btn in org-tree to maintain state
    if (this.commonService.dropdownVal) {
      this.control.setValue(this.commonService.dropdownVal);
    }

    this.langChange(this.translator.defaultLang);

    //  if member login is true show loggedIn page
    if (this.commonService.memberLogin === 'logged') {
      // alert('logged in');
      this.loggedIn = true;
      // this.control.setValue('district');

      // set volunteers option after login
      // if (this.translator.defaultLang === 'ta') {
      //   this.selection.data.push({
      //     id: 'volunteers',
      //     name: 'தன்னார்வலர்கள்'
      //   });
      // } else {
      //   this.selection.data.push({
      //     id: 'volunteers',
      //     name: 'Volunteers'
      //   });
      // }
      this.loginSubmit(this.commonService.loginFormValue);
    }
    // else dont show loggedIn page
    else if (this.commonService.memberLogin === 'notLogged') {
      this.loggedIn = false;
      // this.control.setValue('district');
    }

    // on language change
    this.translator.onDefaultLangChange.subscribe((res) => {
      this.langChange(res.lang);
    });

    // this.loginForm = new FormGroup({
    //   // email: new FormControl('', [Validators.required, Validators.email]),
    //   username: new FormControl('', Validators.required),
    //   password: new FormControl('', Validators.required)
    // });
    this.service.getStateMembers().subscribe((res) => {
      // console.log(res);

      this.stateMemberData = res['Data'];
      // console.log('state members', this.stateMemberData);

    }, (err) => { });

    this.service.getDistrictMembers().subscribe((res: any) => {
      // this.districtMemberData = res['Data'];
      // console.log('dist', res.Data);

      this.districtMemberData = res['Data'].map((item) => {
        // console.log('items', item);
        return {
          // check the image url come from google drive or media in server
          // interpolate the image url with imgBaseURL if its come from media in server
          // Photo: item.Photo.includes(this.driveBaseURL) ? item.Photo : this.imgBaseURL + item.Photo,
          // Name: item.Name,
          // Post: item.Post,
          DistId: item.DistrictID,
          KalagaDistrict: item.TamilName
        };
      });
    }, (err) => { });


    this.control.valueChanges.subscribe(res => {
      // console.log(res);
    });

  }

  // get f() {
  //   return this.loginForm.controls;
  // }
  // login(content) {
  //   this.modalService.open(content, { centered: true });
  // }
  // logout() {
  //   this.loggedIn = false;
  //   this.loginForm.reset();
  // }
  onLoggedDistrictClick(id) {
    // window.location.href = `http://192.168.5.136:8004/livepost/Trees/${id}`;
    window.open(this.service.openTree(id), '_blank');
    // this.service.distOrgChart(id).subscribe((res: any) => {
    //   this.chartData = res.Data;
    //   this.chartData['DistId'] = id;
    //   this.orgChart = true;
    // });
  }

  onDistrictClick(id) {
    // this.service.distOrgChart(id).subscribe((res: any) => {
    //   this.chartData = res.Data;
    //   this.chartData['DistId'] = id;
    //   this.orgChart = true;
    // });

    // this.router.navigate(['org-tree'], { queryParams: { distId: id, from: 'youth-team' } });
  }

  onLoggedInStateMember(memberId) {
    this.service.loggedInStateMember(memberId).subscribe((res: any) => {
      //  res.Data[0].Name
      this.loggedInSateMemberData = res.Data[0];
      // console.log('state member', this.loggedInSateMemberData);
      // set image url
      this.loggedInSateMemberData['images'] = mediaUrl + res.Data[0].images;
      this.isLoggedInStateMemberClicked = true;



    });
  }
  loginSubmit(loginFormValue) {

    this.service.memberLogin(loginFormValue).subscribe(res => {
      // console.log(res);
      if (res['status'] == 1) {
        this.modalService.dismissAll();
        this.LoggedInMemberData = res['Data'].map((item) => {

          return {
            KalagaDistrict: item.DistrictName,
            DistId: item.DistId,
            Logged: true
          };
        });
        // replace dist member with loggedIn member data if id is same
        // https://stackoverflow.com/questions/37585309/replacing-objects-in-array
        this.distWithLoggedInMemberData = this.districtMemberData.map((obj: any) => this.LoggedInMemberData.find((o: any) => o.DistId === obj.DistId) || obj);
        // console.log('result', this.distWithLoggedInMemberData);

        this.loggedIn = true;
        // this.loginBtn=true;
      } else {
        this.toastr.error('மின்னஞ்சல் அல்லது கடவுச்சொல் தவறு');
        this.loginForm.reset();
      }
    }, err => {
      // console.log(err);
    });

  }
  // enter on model form to submit
  // onEnter() {
  //   this.loginSubmit();
  // }
  backToDist(e) {
    // console.log('event', e);
    this.orgChart = false;
  }
  onBackToStateMember() {
    this.isLoggedInStateMemberClicked = false;
    this.ngOnInit();
  }
}
