<div class="banner" style="background-color: transparent;">

    <div class="banner-heading">
        <h1 data-aos="fade-right" data-aos-duration="1000">{{'joinhead'|translate}}</h1>
    </div>

    <div class="row d-flex align-items-end">
        <div class="col col-xl-4 col-md-12 d-xl-block d-md-flex justify-content-center">
            <img data-aos="fade-right" data-aos-duration="1000" class="banner-img-top"
                src="../../../assets/images/web-banner-top-new.png" alt="banner-img">
        </div>
    </div>
    <div class="row banner-inner d-flex justify-content-center align-items-end">
        <div class="banner-content col-xl-6 col-md-12">
            <div class="banner-heading">
                <h1 data-aos="fade-right" data-aos-duration="1000">{{'join'|translate}}</h1>
            </div>
            <div class="banner-text">
                <p>{{'join-full'|translate}}</p>
            </div>
            <div class="banner-button">
                <button class="btn btn-danger btn-lg" (click)="application()">{{'to-join'|translate}}</button>
            </div>
        </div>
        <div class="col col-xl-6 col-md-12 d-xl-block d-md-flex justify-content-center">
            <img id="desktop" data-aos="fade-left" data-aos-duration="1000" class="banner-img"
                src="../../../assets/images/web-banner-main-new.png" alt="banner-img">

            <img id="mobile" class="banner-img" src="../../../assets/images/web-banner-main-new.png" alt="banner-img">
        </div>
    </div>
</div>