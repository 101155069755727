<div class="background-img">
  <!-- <div class="heading">{{'youth-wing'|translate}} - {{'news.secretary' | translate}} </div> -->

</div>
<div class="row">
  <div class="heading">{{'search-result'|translate}}  : {{hashTagId.charAt(0).toUpperCase() + hashTagId.slice(1).toLowerCase()}} </div>

  <div class="col-lg-12">
    <div>

      <div class="reports">
          <div class="row d-flex justify-content-space-between align-items-center" *ngIf="hashtagArticle.length; else nodata">
            <ng-container>
                  <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 mt-4" *ngFor="let item of hashtagArticle">


                  <app-pub-card [report]="item"  (readMore)="readMore($event)"></app-pub-card>






                  </div>
              </ng-container>
          </div>
          <ng-template #nodata>
              <div class="card p-4 mt-4 mx-auto" style="text-align: center;width: 98%;">
                  <div class="no-reports">
                      {{'reports-not-found'|translate}}
                  </div>
              </div>
          </ng-template>
      </div>
  </div>
  </div>

  </div>
