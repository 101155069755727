<div class="background-img"></div>
<div class="report-details">
    <div class="row contents">
        <div class="col-md-2"></div>

        <div class="col-md-8">
            <div class="report-wrapper">

                <div class="back-button d-flex">
                    <a class="view-btn btn btn-sm btn-danger rounded-pill my-4" (click)="onBack()">
                        {{'go-back'|translate}}
                    </a>
                </div>


                <!-- <div *ngFor="let item of commentDetails"> -->
                <div class="heading">
                    <h2>{{bookTitle}}</h2>
                </div>
                <div class="com-img">
                    <img class="image" [src]="mediaUrl+bookImg" alt="Avatar">
                </div>
                <div class="com-author">
                    <span class="auth-head">{{'author'|translate}}</span> - {{authorName}}
                </div>
                <div class="content">
                    <div [innerHtml]='bookDesc'>
                    </div>
                </div>
                <!-- </div> -->


            </div>
        </div>
        <div class="col-md-2"></div>
    </div>
</div>