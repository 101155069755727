<!-- <div class="reports-page"> -->
<!-- <div class="row d-flex justify-content-center align-items-center"> -->

<div class="photos">
    <h1 class="photos-heading">{{'photo-gallery'|translate}}</h1>
    <ng-container *ngIf="photos?.length!=0; else noContent">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let photo of photos" class="slide">
                <app-pub-card [report]="photo" (readMore)="readMore($event)"></app-pub-card>
            </div>
        </ngx-slick-carousel>
        <div class="button d-flex">
            <div class="view-btn btn btn-sm btn-danger rounded-pill my-4" [routerLink]="'/publications/photo'">
                {{'view-more'|translate}}
            </div>
        </div>
    </ng-container>

    <ng-template #noContent>
        <div class="card p-4 mt-4 mx-auto" style="text-align: center;width: 98%;">
            <div class="no-reports">
                {{'reports-not-found'|translate}}
            </div>
        </div>
    </ng-template>
</div>

<div class="reports">
    <h1 class="heading">{{'news.report'|translate}}</h1>
    <ng-container *ngIf="reports?.length!=0; else noContent">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let report of reports" class="slide">
                <app-pub-card [report]="report" (readMore)="readMore($event)"></app-pub-card>
            </div>
        </ngx-slick-carousel>
        <div class="button d-flex">
            <div class="view-btn btn btn-sm btn-danger rounded-pill my-4" [routerLink]="'/publications/report'">
                {{'view-more'|translate}}
            </div>
        </div>
    </ng-container>

    <ng-template #noContent>
        <div class="card p-4 mt-4 mx-auto" style="text-align: center;width: 98%;">
            <div class="no-reports">
                {{'reports-not-found'|translate}}
            </div>
        </div>
    </ng-template>
</div>

<!-- </div> -->
<div class="secretary-in-service">
    <h1 class="heading">{{'news.secretary'|translate}}</h1>
    <ng-container *ngIf="secretaryInService?.length!=0; else noContent">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let secretary of secretaryInService" class="slide">
                <app-pub-card [report]="secretary" (readMore)="readMore($event)"></app-pub-card>
            </div>
        </ngx-slick-carousel>
        <div class="button d-flex">
            <div class="view-btn btn btn-sm btn-danger rounded-pill my-4"
                [routerLink]="'/publications/secretary-in-service'"> {{'view-more'|translate}}

            </div>
        </div>
    </ng-container>

    <ng-template #noContent>
        <div class="card p-4 mt-4 mx-auto" style="text-align: center;width: 98%;">
            <div class="no-reports">
                {{'reports-not-found'|translate}}
            </div>
        </div>
    </ng-template>

</div>

<div class="press-release">
    <h1 class="heading">{{'news.shorts'|translate}}</h1>
    <ng-container *ngIf="pressRelease?.length!=0; else noContent">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let press of pressRelease" class="slide">
                <app-pub-card [report]="press" (readMore)="readMore($event)"></app-pub-card>
            </div>
        </ngx-slick-carousel>
        <div class="button d-flex">
            <div class="view-btn btn btn-sm btn-danger rounded-pill my-4" [routerLink]="'/publications/press-release'">
                {{'view-more'|translate}}

            </div>
        </div>
    </ng-container>

    <ng-template #noContent>
        <div class="card p-4 mt-4 mx-auto" style="text-align: center;width: 98%;">
            <div class="no-reports">
                {{'reports-not-found'|translate}}
            </div>
        </div>
    </ng-template>
</div>

<!-- </div> -->