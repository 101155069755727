import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';
import { host, mediaUrl } from 'src/app/services/interface';


declare var $;
@Component({
  selector: 'app-org-tree',
  templateUrl: './org-tree.component.html',
  styleUrls: ['./org-tree.component.scss']
})
export class OrgTreeComponent implements OnInit {

  distId: any;
  chartData: any = {};
  distMemberData: any = {};
  activeDiv: boolean = false;
  selectedId: any;
  loggedIn: boolean = false;
  isFromYouthTeam: boolean = false;
  districtMemberData: any;
  isImgLoading: boolean = true;
  isPopupOpen: boolean = true;

  constructor(
    private service: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    // function for org tree
    $(function () {
      $('.genealogy-tree ul').hide();
      $('.genealogy-tree>ul').show();
      $('.genealogy-tree ul.active').show();
      $('.genealogy-tree li').on('click', function (e) {

        // dont close tree on member click(to show popup)
        if ($(e.target).attr('class') !== 'member') {
          var children = $(this).find('> ul');
          if (children.is(":visible")) children.hide('fast').removeClass('active');
          else children.show('fast').addClass('active');
          e.stopPropagation();
        }
      });
    });

    this.route.queryParams.subscribe(res => {
      this.distId = res.distId;
      // check the user came from youth team page
      this.isFromYouthTeam = res.from == 'youth-team' ? true : false;
    });


    this.service.distOrgChart(this.distId).subscribe((res: any) => {
      // console.log('dist data', res.Data);
      this.chartData = res.Data;
      this.chartData['DistId'] = this.distId;
    });


  }

  onMember(headId, index) {
    // this.activeDiv = true;
    this.isPopupOpen = true;

    this.selectedId = index;

    // console.log('member click', Id);
    this.service.getDistMember(headId).subscribe((res: any) => {
      // console.log(res);

      this.distMemberData['Id'] = headId;
      this.distMemberData['name'] = res.Data[0].Name;
      this.distMemberData['photo'] = mediaUrl + res.Data[0].Photo;
      this.distMemberData['address'] = res.Data[0].Address;
      this.distMemberData['post'] = res.Data[0].Post;
      this.distMemberData['kalagaDistrict'] = res.Data[0].KalagaDistrict.split('-')[0];
      // load event binding in image element is not get triggered when same image getting from api (no image)
      // so if the noImage.jpeg get from api, set hasPhoto property to false
      // based on hasPhoto property show image from api or local
      this.distMemberData['hasPhoto'] = res.Data[0].Photo == 'districtmembersphoto/image_WhatsApp Image 2021-11-12 at 03.04.44.jpeg' ? false : true;//no image.jpeg from api

      // console.log('member data ', this.distMemberData);

      // hide prev image and enable loading img on next member click
      this.isImgLoading = true;
    });
    // console.log(this.distMemberData);

  }

  // onPublications() {
  //   // console.log('youth team', this.isFromYouthTeam);
  //   // if user from youth team page show list of districts
  //   if (this.isFromYouthTeam) {
  //     // this.isDistShown = true;
  //     this.service.getDistrictMembers().subscribe((res: any) => {
  //       this.districtMemberData = res['Data'].map((item) => {
  //         return {
  //           DistId: item.DistrictID,
  //           KalagaDistrict: item.TamilName
  //         };
  //       });
  //     }, (err) => { });


  //   } else {
  //     this.commonService.changeData('district');
  //     this.router.navigate(['publications/district'], { queryParams: { 'distId': this.distId, 'distName': this.chartData.DistName } });
  //   }
  // }
  // onDistrictClick(distId, distName) {
  //   this.commonService.changeData('district');
  //   this.router.navigate(['publications/district'], { queryParams: { 'distId': distId, 'distName': distName } });
  // }

  onPublications() {
    this.commonService.changeData('district');
    this.router.navigate(['publications/district'], { queryParams: { 'distId': this.distId, 'distName': this.chartData.DistName } });
  }
  onImgLoad(event) {
    // console.log(event);
    this.isImgLoading = false;

  }
  onBack() {
    if (this.isFromYouthTeam) {
      this.commonService.setDropdownVal('district');
      this.router.navigate(['youth-team']);
    } else {
      this.router.navigate(['publications/district']);
    }
  }
  onShowMore(memberId) {
    // console.log('member id', memberId);
    this.router.navigate(['youth-team/dist-member-profile'], { queryParams: { Id: memberId } });
  }

  onClosePopup() {
    this.isPopupOpen = false;
  }

  // hide popup when press esc key
  // @HostListener('window:keyup', ['$event'])
  // keyEvent(event: KeyboardEvent) {
  //   if (event.key == 'Escape') {
  //     this.isPopupOpen = false;
  //   }
  // }

}
