import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { host } from './interface';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  url = host + 'livepost/';
    url1 = host + 'bararts/'; //live environment


  // url = 'http://205.147.97.85:8005/' + 'livepost/'; //outdated testing environment
  // url1 = 'http://205.147.97.85:8005/' + 'bararts/';


  //  url = 'http://122.186.126.218:5105/' + 'livepost/';  //current testing environment
  //  url1 = 'http://122.186.126.218:5105/' + 'bararts/';

  // url1 = 'http://122.186.126.218:5105/' + 'bararts/';


  // url1 = host;
  // url1 = "http://192.168.5.136:8004/";
  // url1 = "http://192.168.5.136:8004/"   //for murasoli pasarai
  // url1 = "http://205.147.97.85:8005/"

  // url1 = "https://api.youthwingdmk.in/"



  url2 = "http://192.168.5.136:8004/livepost/"

  // url2 = "http://192.168.5.136:8004/livepost/";

  constructor(
    private http: HttpClient
  ) { }


  getVideos() {
    return this.http.get(this.url + 'GetVideoData/');
  }
  getImages() {
    return this.http.get(this.url + 'GetImageData/');
  }
  getHistory(lang) {
    return this.http.get(this.url + 'GetDataUsingSpecificCategory/History/' + lang);
  }
  getStateMembers() {
    return this.http.get(this.url + 'membersdata');
  }
  getDistrictMembers() {
    return this.http.get(this.url + 'DmkDistrictAll/');
  }
  memberLogin(formData) {
    return this.http.post(this.url + 'websitecheckpass/', formData);
  }
  navToDistrict(id) {
    return this.http.get(this.url + `districtnav/${id}/`);
  }
  contact(formData) {
    return this.http.post(this.url + 'Feedback/', formData);
  }
  application(formData) {
    return this.http.post(this.url + 'PeopleMemberAdd/', formData);
  }
  getDistricts() {
    console.log(this.http.get(this.url + 'AllDistrict/'));
    return this.http.get(this.url + 'AllDistrict/');
  }
  getMlaAssembly(district) {
    return this.http.get(this.url + `AllZone/${district}/`);
  }
  getMpAssembly() {
    return this.http.get(this.url + 'MpConstituency/');
  }
  openTree(id) {
    return (this.url + 'Trees/' + id);
  }
  distOrgChart(id) {
    return this.http.get(this.url + 'TreeData/' + id);
  }
  getDistMember(id) {
    return this.http.get(this.url + 'GetMemberviewsdata/' + id);
  }
  loggedInStateMember(id) {
    return this.http.get(this.url + 'manilamdetails/' + id);

  }
  getAuthor(id: string) {
    console.log("getAuthor url")
    console.log(this.url1  + 'authorgetdetails/' + id);
    return this.http.get(this.url1 + 'authorgetdetails/' + id);
  }

  // Publications
  getSpecificReport(id: string) {


    return this.http.get(this.url + 'GetSpecificPostDetails/' + id );


  }

  // getSpecificRepo(id: string) {
  //   return this.http.get(this.url + 'GetSpecificPostDetails/' + id);
  // }
  getAllReports() {
    return this.http.get(this.url + 'GetDataUsingSpecificCategory/News Letter/Tamil');
  }
  getReportsByDate(startDate: string, endDate: string, category: string, id) {
    return this.http.get(this.url + `GetDataUsingDate/${startDate}/${endDate}/` + category + '/' + id);
  }
  filterPrvMonth(category: string, id) {
    return this.http.get(this.url + 'GetMonthwise/thismonth/' + category + '/' + id);
  }
  filterPrvSixMonth(category: string, id) {
    return this.http.get(this.url + 'GetMonthwise/sixmonth/' + category + '/' + id);
  }
  filterPrvYear(category: string, id) {
    return this.http.get(this.url + 'GetMonthwise/year/' + category + '/' + id);
  }
  getPublications(category: string, id) {
    console.log(category, id, "84ser");
    return this.http.get(this.url + 'newsLetter/' + category + '/' + id);
  }
  getArticle(articlerecentdate: string) {
    return this.http.get(this.url1 + articlerecentdate);
  }

  filterDistPrvMonth(category: string) {
    return this.http.get(this.url + 'GetMonthwise/thismonth/' + category);
  }
  postComment(formData) {
    return this.http.post(this.url + 'SinglePostCommentry/', formData);
  }
  getComments(id) {
    return this.http.get(this.url + 'GetPostCommentryIndividual/' + id);
  }

  //library
  getLibrary() {
    return this.http.get(this.url + 'GetUpdateLibraryPostWebsite/');
  }

  librarydrop() {
    return this.http.get(this.url + 'savehashtag/');
  }

  libraryfilterdata(filterval) {
    return this.http.get(this.url + 'FilterHashtag/' + filterval);
  }

  librarybookdetails(id) {
    return this.http.get(this.url + 'GetBookDetailsUsingBookId/' + id);
  }

  libraryallcomments(filterval) {
    return this.http.get(this.url + 'FilterHashtagCommentry/' + filterval);
  }

  libraryCommentDetails(id) {
    return this.http.get(this.url + 'GetSingleCommentryDetails/' + id);
  }

  getCategory() {


        return this.http.get(this.url1 + 'murasolicatagory/');

  }
  getCatDetails() { //bararts removed

    // return this.http.get('http://122.186.126.218:8888/' + 'bararts/pasuraicurrentconttent/secretaryInPublicService'); //all category details for pageonload defauld content

    return this.http.get(this.url1 + 'pasuraicurrentconttent/secretaryInPublicService'); //all category details for pageonload defauld content


  }
  getSpecificCategory(id, date) {

//
    return this.http.get(this.url1 + 'pasuraifiltersearch/secretaryInPublicService/' + id + '/' + date);
  }


  getHashTagArticles(hashtag) { //bararts removed

    return this.http.get(this.url1 + 'hashtags/' + hashtag + '/'); //all category details for pageonload defauld content

    // return this.http.get(this.url1 + 'pasuraicurrentconttent/secretaryInPublicService/'); //all category details for pageonload defauld content
  }



  // Dynamic Template Banner

  getTemplates() {
    return this.http.get(this.url + 'templatesget');
  }
  // first time login
  firstTimeLogin() {
    return this.http.get(this.url + 'WebsiteStartApi/');
  }
  checkPassword(formData) {
    return this.http.post(this.url + 'WebsiteStartApiPasswordCheck/', formData);
  }
  launchWebsite() {
    return this.http.get(this.url + 'LanuchButton/');
  }





}
