import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import AOS from 'aos';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    AOS.init();
    localStorage.removeItem("distID");
    localStorage.removeItem("itemCard");
    localStorage.removeItem("itemCard1");

 }

}
