import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from './services/http.service';
import AOS from 'aos';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { mediaUrl } from './services/interface';

const InlineShareButtonsConfig = {
  alignment: 'center', // alignment of buttons (left, center, right)
  color: 'social', // set the color of buttons (social, white)
  enabled: true, // show/hide buttons (true, false)
  font_size: 16, // font size for the buttons
  labels: 'cta', // button labels (cta, counts, null)
  language: 'en', // which language to use (see LANGUAGES)
  networks: [
    // which networks to include (see SHARING NETWORKS)
    'whatsapp',
    'linkedin',
    'messenger',
    'facebook',
    'twitter',
  ],
  padding: 12, // padding within buttons (INTEGER)
  radius: 4, // the corner radius on each button (INTEGER)
  show_total: true,
  size: 40, // the size of each button (INTEGER)

  // OPTIONAL PARAMETERS

  url: 'https://www.sharethis.com', // (defaults to current url)
  image: 'https://bit.ly/2CMhCMC', // (defaults to og:image or twitter:image)
  description: 'custom text', // (defaults to og:description or twitter:description)
  title: 'custom title', // (defaults to og:title or twitter:title)
  message: 'custom email text', // (only for email sharing)
  subject: 'custom email subject', // (only for email sharing)
  username: 'custom twitter handle', // (only for twitter sharing)
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  // blankPage = true;
  title = 'DMK-Youth-Wing-Website';
  inlineShareButtonsConfig = InlineShareButtonsConfig;

  loginPage: boolean;
  launchPage = false;
  mainPage = true;
  loginForm: FormGroup;
  reportId: any;
  reportData = [];
  postContent: any;
  nextReportId: any;
  nextReportData: any;
  videos = [];
  images = [];
  comments = [];
  imagesFullScreen = [];

  constructor(
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private service: HttpService,
    private toastr: ToastrService,
    private activeRoute: ActivatedRoute,
    private meta: Meta,
    private _sanitizer: DomSanitizer,

  ) {
    console.log('Browser:', window.navigator.userAgent);
    console.log('Platform:', window.navigator.platform);
    // trans service
    translate.addLangs(['en', 'ta']);
    translate.setDefaultLang('ta');
    // login form
    this.loginForm = formBuilder.group({
      Username: [null],
      Password: [null]
    });
  }
  // ngOnDestroy(): void {
  //   throw new Error('Method not implemented.');
  // }

  ngOnInit(): void {

    // AOS.init();
    AOS.init({
      disable: 'mobile'
    });


    this.activeRoute.queryParams.subscribe((params: any) => {
      this.reportId = params.Id;
      // console.log(params, "53app1");
      // console.log(this.reportId, "54app");
      // getSpecificReport(id) {
      //   //   console.log(id, "116");
      //   //   this.service.getSpecificReport(id).subscribe((res: any) => {
      //   //     this.reportData = res.Data[0].Data;

      //   //     console.log(this.reportData['PostTitle'], "82 title");
      //   //     this.meta.updateTag(
      //   //       { property: 'og:title', content: this.reportData['PostTitle'] },
      //   //     );
      //   //     this.meta.updateTag(
      //   //       { property: 'og:description', content: this.reportData['PostTitle'] },
      //   //     );
      //   //   });
      //   // }

    }
    );
    this.getSpecificReport(this.reportId);

    this.mainPage = Boolean(Number(localStorage.getItem('mainPage')) | 1);
    // console.log('main page', this.mainPage);

    // prevent login page show on load for short time
    // this.firstTimeLogin = false;

    this.service.firstTimeLogin().subscribe((res: { data: any, Status: string; }) => {
      // console.log(res.data[0].active);

      if (res.data[0].active == 1) {
        this.loginPage = true;
        localStorage.setItem('mainPage', '0');
        this.mainPage = false;
      } else {
        this.loginPage = false;
        localStorage.setItem('mainPage', '1');
      }
    });
  }

  getSpecificReport(id) {
    // console.log(id, "95");
    this.service.getSpecificReport(id).subscribe((res: any) => {
      this.reportData = res.Data[0].Data;

      console.log(this.reportData['PostTitle'], "82 title");
      this.meta.updateTag(
        { property: 'og:title', content: this.reportData['PostTitle'] },
      );
      this.meta.updateTag(
        { property: 'og:description', content: this.reportData['PostTitle'] },
      );

      // image with and height from ckeditor not working, so i sanitize the data
      //https://stackoverflow.com/questions/54703250/angular-image-size-problem-in-ckeditor-content
      this.postContent = this._sanitizer.bypassSecurityTrustHtml(this.reportData['PostContent']);

      this.nextReportId = res.Data[0].NextId;
      this.images = res.Data[0].pdf;
      let temp = res.Data[0].Data.VideURL;
      if (temp) {
        temp.forEach((ele: string) => {
          let url = "https://www.youtube.com/embed/" + ele.slice(32);
          // console.log(url);
          let modified_url = this._sanitizer.bypassSecurityTrustResourceUrl(url);
          this.videos.push(modified_url);
        });
      }

      this.fullScreenView();
      this.getNextReport();
    });
  }

  fullScreenView() {
    this.images.forEach(ele => {
      this.imagesFullScreen.push({ image: mediaUrl + ele.filefield });
    });
    // console.log('this is each', this.imagesFullScreen);

  }

  getNextReport() {
    this.service.getAllReports().subscribe((res) => {
      this.nextReportData = res['Data'].filter((ele) => ele.Id == this.nextReportId)[0];
    });
  }
  get f() {
    return this.loginForm.controls;
  }
  login() {
    // console.log('login form', this.loginForm.value);
    this.service.checkPassword(this.loginForm.value).subscribe((res: { Message: string, Status: string; }) => {
      // console.log('response', res);
      if (res.Status == 'Pass') {
        // alert('successfully logged in');
        this.toastr.success('successfully logged in');
        this.loginPage = false;
        this.launchPage = true;
      } else {
        // alert('username or password is invalid');
        this.toastr.warning('username or password is invalid');

      }

    });

  }

  launch() {
    this.service.launchWebsite().subscribe((res: { Message: string, Status: string; }) => {
      if (res.Status == 'Pass') {
        this.launchPage = false;
        localStorage.setItem('mainPage', '1');
        this.mainPage = true;
      }
    });

  }

  ngOnDestroy(): void {
    // this.service.firstTimeLogin();
    // this.firstTimeLogin = true;

  }
}
