import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { SelectOptions } from '../select/select.component';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {
  library: [];
  displayedColumns = ['id', 'title', 'author', 'bookId'];
  dataSource: MatTableDataSource<any>;
  filterval = 'ALLCAT';
  hashvalue: any = [];
  selectedTag: any = null;
  librarydrop: SelectOptions = {
    placeholder: 'library',
    data: [],
    multiple: false
  };

  libraryform: FormGroup;

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) matSort: MatSort;

  constructor(
    private service: HttpService,
    private formBuilder: FormBuilder,
    private router: Router,
    private meta: Meta
  ) {
    this.libraryform = this.formBuilder.group({
      'librarydrop': [null]
    });
  }

  ngOnInit(): void {
    this.meta.updateTag({ name: 'description', content: 'கலைஞர் நூலகம் திராவிட இயக்கம், மேடைப் பேச்சுகளாலும் எழுத்துக்களாலும் வளர்ந்த இயக்கம். இயக்கத்தின் தலைவர்கள் பலரும் நாளிதழ்கள் வார இதழ்கள், மாத இதழ்களை நடத்தியது மட்டுமின்றி கதைகள், கவிதைகள், கட்டுரைகள், புதினங்கள், நாடகங்கள் உள்ளிட்ட பல நூல்களையும் எழுதியுள்ளனர்.' });
    this.meta.updateTag({ name: 'twitter:description', content: 'கலைஞர் நூலகம் திராவிட இயக்கம், மேடைப் பேச்சுகளாலும் எழுத்துக்களாலும் வளர்ந்த இயக்கம். இயக்கத்தின் தலைவர்கள் பலரும் நாளிதழ்கள் வார இதழ்கள், மாத இதழ்களை நடத்தியது மட்டுமின்றி கதைகள், கவிதைகள், கட்டுரைகள், புதினங்கள், நாடகங்கள் உள்ளிட்ட பல நூல்களையும் எழுதியுள்ளனர்.'});

    //this.getLibrary();

    // this.service.librarydrop().subscribe((res: any) => {
    //   let arr = res.Data.map(element => element['id'] = element['name'] = element.hashtagsval);
    //   this.librarydrop.data = [...arr];
    //   // console.log(this.librarydrop.data);
    // });

    this.service.librarydrop().subscribe((res: any) => {
      console.log(res.Data, "53 hashvalue");
      this.hashvalue = res.Data;
      if (this.hashvalue.length) this.hashvalue.push({ Id: null, hashtagsval: 'ALLCAT' });
      // set all category is by default selected value
      this.selectedTag = this.hashvalue.length - 1;
    });

    this.libraryfilterdata(this.filterval);
  }

  // getLibrary() {
  //   this.service.getLibrary().subscribe((res: any) => {
  //     this.dataSource = new MatTableDataSource(res.data);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.matSort;
  //     // console.log(this.dataSource);
  //   }, (err) => {
  //     //  console.log(err); 
  //   });
  // }

  libraryfilterdata(libraryval) {
    this.service.libraryfilterdata(libraryval).subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res.data);
      console.log(res, "77 response for libraryval");
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.matSort;
      // console.log(res.data);
    });
  }

  filterData($event: any) {
    this.dataSource.filter = $event.target.value;
  }

  get f() {
    return this.libraryform.controls;
  }

  bookid(id) {
    // console.log(id);
    this.router.navigate(['library/librarybook'], { queryParams: { bookid: id, from: 'library' } });
    // .then(nav => {
    //   console.log(nav); // true if navigation is successful
    // }, err => {
    //   console.log(err) // when there's an error
    // });
  }

  hashCol(val) {
    console.log(val, "val");
    if (val == "ALLCAT") {
      this.filterval = 'ALLCAT';
      this.libraryfilterdata(this.filterval);
    } else {
      let withouthashval = val.slice(1,);
      console.log(withouthashval, "withouthash");
      this.filterval = withouthashval;
      this.libraryfilterdata(this.filterval);
    }

  }
  onTagClick(index) {
    this.selectedTag = index;
  }

}
