import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Meta } from '@angular/platform-browser';
import { PubCardComponent } from 'src/app/components/publications/pub/pub-card/pub-card.component';
import { PubListComponent } from 'src/app/components/publications/pub/pub-list/pub-list.component';


@Component({
  selector: 'app-pub-page',
  templateUrl: './pub-page.component.html',
  styleUrls: ['./pub-page.component.scss']
})
export class PubPageComponent implements OnInit {

  newsSubMenuName;
  constructor(
    private commonService: CommonService,
    private meta: Meta

  ) { }


  ngOnInit(): void {
    this.commonService.data$.subscribe(res => {
      if (res) {
        console.log(res, "20000");
        this.newsSubMenuName = res;
      }
      else {
        // data from org chart
        this.newsSubMenuName = this.commonService.district;
      }
    });


    this.meta.updateTag({ name: 'description', content: 'இளைஞர் அணி வெளியீடுகள் - அறிக்கை' });
    this.meta.updateTag({ name: 'twitter:description', content:  'இளைஞர் அணி வெளியீடுகள் - அறிக்கை'});


  }

}
