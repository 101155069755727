<ul>
    <li class="nav-item">
        <!-- <a href="https://twitter.com/dmk_youthwing" class="nav-link" target="_blank"><i class="fab fa-twitter"></i></a> -->
        <a href="https://twitter.com/dmk_youthwing" class="nav-link" target="_blank"><img
                src="../../../../assets/images/icons8-twitter-50.png" alt="twitterlink"></a>
    </li>
    <li class="nav-item">
        <a href="https://www.youtube.com/channel/UCxxL-HOrCAHxyvkPajRIDpg" class="nav-link" target="_blank"><img
                src="../../../../assets/images/YouTube-Icon-White-Logo.wine.svg" alt="youtube"></a>
    </li>
    <li class="nav-item">
        <a href="https://www.instagram.com/dmkyouthwing/" target="_blank" class="nav-link"><img
                src="../../../../assets/images/icons8-instagram-24.png" alt="instagram"></a>
    </li>
    <li class="nav-item">
        <a href="https://www.facebook.com/DMKYWANBAGAM/" class="nav-link" target="_blank"><img
                src="../../../../assets/images/icons8-facebook-f-32.png" alt="facebook"></a>
    </li>

  
</ul>
