import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/http.service';
import { alphabetRegex, emailRegex, mobileNumberRegex } from 'src/app/services/interface';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  contactForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private service: HttpService,
    private toastr: ToastrService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.meta.updateTag({ name: 'description', content: 'எங்களை தொடர்பு கொள்ளுங்கள்' });

    this.contactForm = this.formBuilder.group({
      'FirstName': ['', [Validators.required, Validators.pattern(alphabetRegex)]],
      // 'FirstLast': ['', [Validators.required, Validators.pattern(alphabetRegex)]],
      'Email': ['', [Validators.required, Validators.pattern(emailRegex)]],
      'MobileNo': ['', [Validators.required, Validators.pattern(mobileNumberRegex)]],
      'Subject': ['', Validators.required],
      'Message': ['', Validators.required],
    });
  }

  get f() {
    return this.contactForm.controls;
  }

  submit() {
    if (this.contactForm.valid) {
      this.service.contact(this.contactForm.value).subscribe(res => {
        this.toastr.success('உங்கள் தொடர்பு படிவம் சமர்ப்பிக்கப்பட்டது.');
        this.contactForm.reset();
      });
    } else {
      this.contactForm.markAllAsTouched();
    }
  }

}
