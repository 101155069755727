<div class="bg-image">
    <div class="row center m-0">
        <div class="description">
            <div class="description-heading">
                {{'donate'|translate}}
            </div>
            <!-- <div data-aos="fade-up" data-aos-duration="1000" class="description-text content"> -->
            <div class="description-text content">
                <p>{{'donation-content'|translate}}</p>
            </div>
            <!-- <div data-aos="fade-up" data-aos-duration="1000" class="description-text"> -->
            <div class="description-text">
                <p class="top" style="font-size: 1.3rem;">DMK Youth Wing Charitable Trust</p>
                <p class="middle">
                    <span class="label">{{'bank-name'|translate}}</span> - {{'indian-bank'|translate}}<br>
                    <span class="label">{{'branch'|translate}}</span> - {{'teynampet'|translate}},
                    {{'chennai'|translate}}-18.<br>
                    <span class="label">{{'savings-account'|translate}}</span> - <span
                        class="numberStyle">783271504</span><br>
                    <span class="label"> {{'ifsc-code'|translate}}</span> - <span class="numberStyle">IDIB000T011</span>
                </p>
            </div>
        </div>
    </div>
</div>