<div class="background-img">
    <div class="heading">{{'youth-wing'|translate}} - {{'news.secretary' | translate}} </div>

</div>



<!--jhjkhjhjk  for current article-->

<!-- <div class="row" *ngIf="isCurrent"> -->
  <div class="row" >

  <div class="libdrp" *ngIf="currentArticleValue.length">
    <ul class="d-flex flex-wrap tag" *ngIf="lang == true; else allcat1">
      <!--this is  for categories in tamil -->


      <div *ngFor="let item of filteredCategories; let i = index" (click)="onTagClickCurrentTag(i)">
        <li
          class="badge rounded-pill mr-2"
          [ngClass]="{ selectedTagC: selectedTagC == i,
          'bounce-blink rounded-pill1': item.subcat_tamil == 'சிறப்பு கட்டுரைகள்'}"
          (click)="hashCol(item.id, item.subcat_tamil, item.hashtagsval, item.cat_icon)"
        >
          <!-- <img [src]="mediaUrl+item.cat_icon" class="imgsec mr-1"> -->
          <!-- <img src="https://www.youthwingdmk.in/assets/images/Youth%20wing%20logo2.png" class="imgsec1 mr-1"> -->


          <span *ngIf="item.hashtagsval != 'ALLCAT'">{{
            item.subcat_tamil
          }}</span>

<!-- <i *ngIf="item.subcat_tamil == 'சிறப்பு கட்டுரைகள்'" class="fas  latest-label bounce-blink"></i> -->



          <!-- <span *ngIf=" item.hashtagsval!='ALLCAT' ; else allCat">{{item.subcat_tamil}}</span> -->
          <!-- <ng-template #allCat>


                        <span>{{"alltag"|translate}} </span>


                    </ng-template> -->
          <!-- <span *ngIf="lang;else allCat">{{item.subcat_tamil}}</span>
                    <ng-template #allCat><span>{{item.subcat_english}}</span></ng-template> -->
        </li>



      </div>
      <div>
          <li
        class="badge rounded-pill mr-2"
        [ngClass]="{ selectedTagB: selectedTagB == 'previous' }"
        (click)="onTagPressed('previous')">


        <span> {{'previous-article'|translate}}      </span>


    </li>
  </div>
<!--soecial article-->
  <!-- <div>
    <li
  class="badge rounded-pill mr-2 "
  [ngClass]="{ selectedTagB: selectedTagB == 'previous' }"
  (click)="onTagPressed('previous')">


  <span > {{'previous-article'|translate}}      </span>
    <i class="fas fa-star top-right-star latest-label"></i>


</li>




</div> -->




    </ul>
    <!-- </div> -->
  </div>


  <!--this is  for categories in english -->


  <ng-template #allcat1>
    <!-- <span *ngIf=" !lang">{{item.subcat_english}} </span> -->
    <!-- <div class="libdrp"> -->



    <ul class="d-flex flex-wrap tag">
      <div *ngFor="let item of filteredCategories; let i = index" (click)="onTagClickCurrentTag(i)">
        <li
          class="badge rounded-pill mr-2"
          [ngClass]="{ selectedTagC: selectedTagC == i ,
          'bounce-blink rounded-pill1': item.subcat_english == 'Special Articles'
          }"
          (click)="hashCol(item.id,item.subcat_english, item.hashtagsval, item.cat_icon)"
        >
          <!-- <img src="https://www.youthwingdmk.in/assets/images/Youth%20wing%20logo2.png" class="imgsec1 mr-1"> -->


          <span *ngIf="item.hashtagsval != 'ALLCAT'; else allCat">{{
            item.subcat_english
          }}</span>
<i *ngIf="item.subcat_english == 'Special Articles'" class="fas fa-star top-right-star latest-label bounce-blink"></i>


          <!-- <ng-template #allCat> <span>{{"alltag"|translate}} </span>


                        </ng-template> -->
        </li>
      </div>

      <div>
        <li
      class="badge rounded-pill mr-2"
      [ngClass]="{ selectedTagB: selectedTagB == 'previous' }"
      (click)="onTagPressed('previous')">


      <span> {{'previous-article'|translate}}      </span>


  </li>
</div>
    </ul>
    <!-- </div> -->
  </ng-template>



 </div>


<!--jhjkhjhjk-->

<!--jhjkhjhjk  for previous article-->

<div class="row" *ngIf="isPrevious">
  <div class="libdrp" *ngIf="previousArticleValue.length">
    <ul class="d-flex flex-wrap tag" *ngIf="lang == true; else allcat1">
      <!--this is  for categories in tamil -->


      <div *ngFor="let item of previousArticleValue; let i1 = index" (click)="onTagClick1(i1)">
        <li
          class="badge rounded-pill mr-2"
          [ngClass]="{ selectedTag: selectedTag == i1 }"
          (click)="hashCol(item.id, item.subcat_tamil, item.hashtagsval, item.cat_icon)"
        >
          <!-- <img [src]="mediaUrl+item.cat_icon" class="imgsec mr-1"> -->
          <!-- <img src="https://www.youthwingdmk.in/assets/images/Youth%20wing%20logo2.png" class="imgsec1 mr-1"> -->


          <span *ngIf="item.hashtagsval != 'ALLCAT'">{{
            item.subcat_tamil
          }}</span>


          <!-- <span *ngIf=" item.hashtagsval!='ALLCAT' ; else allCat">{{item.subcat_tamil}}</span> -->
          <!-- <ng-template #allCat>


                        <span>{{"alltag"|translate}} </span>


                    </ng-template> -->
          <!-- <span *ngIf="lang;else allCat">{{item.subcat_tamil}}</span>
                    <ng-template #allCat><span>{{item.subcat_english}}</span></ng-template> -->
        </li>
      </div>
    </ul>
    <!-- </div> -->
  </div>


  <!--this is  for categories in english -->


  <ng-template #allcat1>
    <!-- <span *ngIf=" !lang">{{item.subcat_english}} </span> -->
    <!-- <div class="libdrp"> -->
    <ul class="d-flex flex-wrap tag">
      <div *ngFor="let item of previousArticleValue; let i = index" (click)="onTagClick1(i)">
        <li
          class="badge rounded-pill mr-2"
          [ngClass]="{ selectedTag: selectedTag == i }"
          (click)="hashCol(item.id,item.subcat_english, item.hashtagsval, item.cat_icon)"
        >
          <!-- <img src="https://www.youthwingdmk.in/assets/images/Youth%20wing%20logo2.png" class="imgsec1 mr-1"> -->


          <span *ngIf="item.hashtagsval != 'ALLCAT'; else allCat">{{
            item.subcat_english
          }}</span>


          <!-- <ng-template #allCat> <span>{{"alltag"|translate}} </span>


                        </ng-template> -->
        </li>
      </div>
    </ul>
    <!-- </div> -->
  </ng-template>



 </div>
<!--jhjkhjhjk  for previous article-->




 <div>
  <div class="row"></div>
 </div>


 <div class="row">

  <div class="col-lg-9">
    <div *ngIf="AllCat;else specificcat">

      <div class="reports">
          <div class="row d-flex justify-content-space-between align-items-center" *ngIf="reports.length; else nodata">
            <ng-container>
                  <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 mt-4" *ngFor="let item of reports">


                  <app-pub-card [report]="item"  (readMore)="readMore($event)"></app-pub-card>




                      <!-- <div class="card"> -->
                          <!-- <img class="image" max="2023-12-31"(click)="readMore(report?.Id)" [src]="mediaUrl+report?.PostImage" alt="Avatar"> -->

                          <!-- <img class="image" [src]="mediaUrl+item.PostImage" alt="Avatar1" (click)="readMore(item?.Id)"> -->
                          <!-- <img class="image" [src]="mediaUrl+item.PostImage" alt="Avatar" (click)="open(item?.Id)"> -->

                          <!-- <div class="container"> -->
                              <!-- <p class="date-time">
              பதிவு
              <span>
                  {{report?.PostDateTime|date:'dd MMM yyyy, hh:mm:ss'}}
              </span>
              &nbsp;மணி
           </p> -->
                              <!-- <p class="date-time"> -->
                                 <!-- {{item.PostDateTime|date:'dd MMM yyyy'}} -->
                              <!-- </p> -->
                              <!-- <h5 class="heading" (click)="readMore(item?.Id)">{{item?.PostName.slice(0,60)+'...'}}</h5> -->
                              <!-- <h5 class="heading" (click)="open()">{{item?.PostName.slice(0,60)+'...'}}</h5> -->

                              <!-- <p class="content" style="height: 6.5rem;overflow: hidden;">{{report?.PostTitle}}</p> -->
                              <!-- <p class="read-more" (click)="readMoreFunc(report?.Id)"><a>{{'read-more'|translate}}</a></p> -->
                          <!-- </div> -->
                      <!-- </div> -->

                  </div>
              </ng-container>
          </div>
          <ng-template #nodata>
              <div class="card p-4 mt-4 mx-auto" style="text-align: center;width: 98%;">
                  <div class="no-reports">
                      {{'reports-not-found'|translate}}
                  </div>
              </div>
          </ng-template>
      </div>
  </div>
  </div>
  <div class="col-lg-3 fb-feed">
    <h6> DMK Murasoli Facebook Feed</h6>


   <div class="facebook">

  <iframe class="scrollable" frameborder="0"
      src=
      "https://www.facebook.com/plugins/likebox.php?href=https://www.facebook.com/profile.php?id=100092435641685&width=260&height=500&tabs=timeline&show_faces=false"
      ></iframe>
      <!-- https:÷//www.facebook.com/p/Murasoli-Paasarai-100092435641685/?locale=da_DK -->

      </div>

      <div class="twitter scrollable">
        <a class="twitter-timeline" href="https://twitter.com/MPaasarai?ref_src=twsrc%5Etfw">Tweets by arivalayam</a>
    </div>


 </div>






<ng-template #specificcat>



    <img *ngIf="categories.length && !isSpecial" [src]="mediaUrl+ icon" class="imgsec d-flex mr-1">
    <!-- <img *ngIf="categories.length" [src]="mediaUrl+ icon" class="imgsec d-flex mr-1"> -->

    <div class="reports">


        <div class="row d-flex justify-content-space-between align-items-center" *ngIf="categories.length; else nodata">
            <ng-container>
                <!-- <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 mt-4 cardspace" *ngFor="let item of categories"> -->
                  <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 mt-4" *ngFor="let item of categories">
                    <app-pub-card [report]="item" (readMore)="readMore($event)"></app-pub-card>
                  <!-- </div> -->
                    <!-- <div class="card">

                        <img class="image" [src]="mediaUrl+item.PostImage" alt="Avatar2" (click)="readMore(item?.Id)">

                        <div class="container">

                            <p class="date-time">
                                {{item.PostDateTime|date:'dd MMM yyyy'}}
                            </p>
                            <h5 class="heading" (click)="readMore(item?.Id)">{{item?.PostName.slice(0,60)+'...'}}</h5>
                           </div>
                    </div> -->

                </div>
            </ng-container>
        </div>
        <ng-template #nodata>
            <div class="card p-4 mt-4 mx-auto" style="text-align: center;width: 98%;">
                <div class="no-reports">
                    {{'reports-not-found'|translate}}
                </div>
            </div>
        </ng-template>

    </div>

</ng-template>
<!-- </div> -->






<!--

<div class="row">
  <div class="libdrp">
    <ul class="d-flex flex-wrap tag" >



      <div>
        <li
          class="badge rounded-pill mr-2"
          [ngClass]="{ selectedTagB: selectedTagB == 'current' }"
          (click)="onTagPressed('current')"
        >


          <span>
            {{'current-article'|translate}}
          </span>


      </li>

      <li
      class="badge rounded-pill mr-2"
      [ngClass]="{ selectedTagB: selectedTagB == 'previous' }"
      (click)="onTagPressed('previous')">


      <span>

{{'previous-article'|translate}}      </span>


  </li>
      </div>
    </ul>
  </div>









 </div>

-->
