import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/http.service';
import { SelectOptions } from '../select/select.component';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss']
})
export class ApplicationFormComponent implements OnInit {
  MlaAssembly: SelectOptions = {
    placeholder: 'MLA constituency',
    data: []
  };

  MlaAssembly1: SelectOptions = {
    placeholder: 'MLA constituency',
    data: []
  };
  MpAssembly: SelectOptions = {
    placeholder: 'MP constituency',
    data: []
  };


  MpAssembly1: SelectOptions = {
    placeholder: 'MP constituency',
    data: []
  };
  district: SelectOptions = {
    placeholder: 'district',
    data: []
  };
  // state:SelectOptions = {
  //   placeholder: 'மாநிலம்',
  //   data: this.items3
  // }
  applicationForm: FormGroup;
  file: File = null;
  uploadFileSize: number;
  distList: any = [];
  dist: any;
  district1: SelectOptions = {
    placeholder: 'district',
    data: [],

  };
  currentLang: string;
  selectedItem = '0';
  lang: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private service: HttpService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private ngZone: NgZone,
    private meta: Meta
  ) {
    this.applicationForm = this.formBuilder.group({
      'FirstName': ['', [Validators.required, Validators.pattern('^[^0-9]+$')]],
      'FatherName': ['', [Validators.required, Validators.pattern('^[^0-9]+$')]],
      // 'Email': ['', [Validators.required, Validators.email]],
      'DOB': ['', Validators.required],
      'MobileNo': ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      'District': [null, Validators.required],
      'MlaAssembly': [null, Validators.required],
      'MpAssembly': [null, Validators.required],
      'Ward': [''],
      'Address': [''],
      // 'state': [null, Validators.required],
      'FacebookLink': [''],
      'TwitterLink': [''],
      'InstaLink': [''],

    });

    this.translate.onDefaultLangChange.subscribe((res) => {
      // console.log('translation Response', res);
      this.currentLang = res.lang;
      localStorage.setItem("ta", this.currentLang);
      if (this.currentLang == 'ta') {
        this.lang = true;
      } else {
        this.lang = false;
      }
    });
    if (localStorage.getItem("ta") == 'ta') {
      this.lang = true;
    } else {
      this.lang = false;
    }
  }

  ngOnInit(): void {
    // this.lang = true;
    // if (this.currentLang !== "ta") {
    //   this.lang = false;
    // }

    // else {
    //   this.lang = true;
    // }
    // console.log(this.lang, "app lang 82");
    this.meta.updateTag({ name: 'description', content: 'தி.மு.க இளைஞர் அணியில் இணைவோம்!' });

    this.service.getDistricts().subscribe((res: any) => {

      // DistrictName
      // let arr = res.data.map(element => element['id'] = element['name'] = element.DistrictName);
      let arr = res.data.map(element => {
        return { id: element.DistrictName, name: element.DistrictName };
      });

      let arr1 = res.data.map(element => {
        return { id: element.DistrictName, name: element.DistrictName_tamil };
      });


      this.district.data = [...arr];
      this.district1.data = [...arr1];
      // this.district1.data = [...arr1];
      // console.log(this.district.data);
      // console.log(this.district1.data);
      this.ngZone.run(() => { });
    });
    this.f.District.valueChanges.subscribe(res => {
      this.f.MlaAssembly.markAsUntouched();
      this.f.MlaAssembly.setValue(null);

      if (res) {
        console.log(res, "for assem");
        this.service.getMlaAssembly(res).subscribe((res: any) => {
          console.log(res, "assembly res");
          // let arr = res.data.map(element => element['id'] = element['name'] = element.ZoneName);
          let arr = res.data.map(element => {
            return { id: element.ZoneName, name: element.ZoneName };
          });

          let arr1 = res.data.map(element => {
            return { id: element.ZoneName, name: element.ZoneName_tamil };
          });
          this.MlaAssembly.data = [...arr];
          this.MlaAssembly1.data = [...arr1];
          console.log(this.MlaAssembly, "assemly");
          console.log(this.MlaAssembly1, "assemly11");
        });
      } else {
        this.MlaAssembly.data = [];
        this.f.MlaAssembly.markAsUntouched();
      }
    });

    this.service.getMpAssembly().subscribe((res: any) => {

      // console.log(res, "mpassembly");
      // let arr = res.data.map(element => element['id'] = element['name'] = element.MpConstituencyName);
      // this.MpAssembly.data = [...arr];

      let arr = res.data.map(element => {
        return { id: element.MpConstituencyName, name: element.MpConstituencyName };
      });

      let arr1 = res.data.map(element => {
        return { id: element.MpConstituencyName, name: element.MpConstituencyNameTamil };
      });

      this.MpAssembly.data = [...arr];
      this.MpAssembly1.data = [...arr1];




    });

    this.translate.onDefaultLangChange.subscribe((res) => {
      // console.log('translation Response', res.lang);
      this.currentLang = res.lang;
      if (this.currentLang == 'ta') {
        this.lang = true;
      }
      else {
        this.lang = false;
      }
    });

  }

  get f() {
    return this.applicationForm.controls;
  }

  submit() {
    // if (this.applicationForm.valid && this.file != null) {
    if (this.applicationForm.valid) {
      const formData: FormData = this.toFormData(this.applicationForm.value);
      formData.append('Proof', this.file);
      this.service.application(formData).subscribe(res => {
        this.toastr.success('உங்கள் விண்ணப்பம் சமர்ப்பிக்கப்பட்டது.');
        this.applicationForm.reset();
        this.file = null;
      });
    } else {
      this.applicationForm.markAllAsTouched();
      // if (this.file == null && this.applicationForm.valid) this.toastr.warning('புகைப்படம் தேவை, பதிவேற்றவும்.');
      ;
    }
  }

  handleFileInput(e) {
    this.file = e.target.files[0];
    this.uploadFileSize = this.file.size;
    // restrict file size below 20MB
    if (this.uploadFileSize > 160000000) {
      // alert('file size should not be more then 20MB');
      this.toastr.warning('file size should not be more then 20MB');

      this.file = null;
    }
    // console.log('file size', this.file.size);
  }

  toFormData<T>(formValue: T) {
    const formData = new FormData();
    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      if (value) {
        formData.append(key, value);
      }
    }
    return formData;
  }




}
function changeLang(lang: any, string: any) {
  throw new Error('Function not implemented.');
}

